import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaPercent } from "react-icons/fa6";
import { MdCurrencyRupee } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  bagSizecreate_api,
  get_bagsize_api,
  restauranttypelist_api,
  resturant_dropdown_list,
  update_bagsize_api,
} from "../../API/baseUrl";
import {
  bagSizeadd,
  bagsizeDetails,
  bagsizeUpdate,
  removeDataFromReducer,
  restauranttypeListRequest,
  resturantListaction,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { bagsizeaddshema } from "../../utils/schema";
import { useParams } from "react-router-dom";
import StatusModal from "../vendor/modal";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Label } from "@mui/icons-material";

const SurpriseBagAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [statusvalue, setStatusvalue] = useState("");
  const [accountstatus, setAccountstatus] = useState("");
  const [valueshowdropdown, setValueshowdropdown] = useState("");
  const { TypeListdata } = useSelector(
    (state) => state?.restauranttypeListreduser || {}
  );
  const [typelist, setTypelist] = useState([]);
  

  const [resturantnamelistdrop, setResturantnamelistdrop] = useState([]);

  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const { bagsizedetailsdata } = useSelector(
    (state) => state?.bagsizedetailsReduser || {}
  );
  console.log("bagsizedetailsdata", bagsizedetailsdata)

  const { surpricebagAdddata } = useSelector(
    (state) => state?.bagsizeAddReduser || {}
  );

  const { bagsizeupdatedata } = useSelector(
    (state) => state?.bagsizeupdateReduser || {}
  );
  const { resturantlistdata } = useSelector((state) => state.resturantlistreduser);



  console.log("resturantlistdata", resturantlistdata)
  const initialValues = {
    surpriseBagSize: "",
    restaurantTypeIdFk: "",
    restaurantName: "",
    commission: "",
    surpriseBagRetailPrice: "",
    surpriseBagSellingPrice: "",
    discount: "",
    restaurantIdFK: "",
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: bagsizeaddshema,

    onSubmit: () => ApiHandlesubmit(),
  });

  useEffect(() => {
    if (surpricebagAdddata?.status) {
      setLoading(false);
      ToastMessage("success", surpricebagAdddata?.message);
      navigate("/dashboard/bagsize");
      dispatch(removeDataFromReducer("BAGSIZE_ADD_RESET"));
    } else {
      ToastMessage("error", surpricebagAdddata?.message);
      setLoading(false);
    }
  }, [surpricebagAdddata]);

  useEffect(() => {
    if (bagsizeupdatedata?.status) {
      setLoading(false);
      ToastMessage("success", bagsizeupdatedata?.message);
      navigate("/dashboard/bagsize");
      dispatch(removeDataFromReducer("BAGSIZE_UPDATE_RESET"));
    } else {
      ToastMessage("error", bagsizeupdatedata?.message);
      setLoading(false);
    }
  }, [bagsizeupdatedata]);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (TypeListdata?.status) {
      const newType = TypeListdata?.data?.map((item) => ({
        label: item.type,
        value: item.restaurantTypeIdFk,
      }));
      setTypelist(newType);
    } else {
      ToastMessage("error", TypeListdata?.message);
    }
  }, [TypeListdata]);

  useEffect(() => {
    const parameter = {
      url: restauranttypelist_api,
    };
    dispatch(restauranttypeListRequest(parameter));
  }, []);

  const ApiHandlesubmit = async () => {
    setLoading(true);
    const parameter = {
      surpriseBagSize: values.surpriseBagSize,
      restaurantIdFk: values.restaurantIdFK,
      commission: values.commission,
      restaurantTypeIdFk: values.restaurantTypeIdFk,
      discount: values.discount,
      restaurantName: values.restaurantName,
      surpriseBagRetailPrice: values.surpriseBagRetailPrice,
      surpriseBagSellingPrice: values.surpriseBagSellingPrice,
      url: id ? `${update_bagsize_api}${id}` : bagSizecreate_api,
    };

    if (id) {
      dispatch(bagsizeUpdate(parameter));
    } else {
      dispatch(bagSizeadd(parameter));
    }
  };

  useEffect(() => {
    if (bagsizedetailsdata?.status) {
      values.surpriseBagSize = bagsizedetailsdata?.data?.surpriseBagSize;
      setFieldValue(
        "surpriseBagSize",
        bagsizedetailsdata?.data?.surpriseBagSize
      );
      console.log("values.surpriseBagSize", values.surpriseBagSize)
      values.restaurantTypeIdFk = bagsizedetailsdata?.data?.restaurantTypeIdFk;
      setFieldValue(
        "restaurantTypeIdFk",
        bagsizedetailsdata?.data?.restaurantTypeIdFk
      );
      values.commission = bagsizedetailsdata?.data?.commission;
      setFieldValue("commission", bagsizedetailsdata?.data?.commission);

      values.discount = bagsizedetailsdata?.data?.discount;
      setFieldValue("discount", bagsizedetailsdata?.data?.discount);

      
    setFieldValue("restaurantIdFK", bagsizedetailsdata?.data?.restaurantIdFK)
    setFieldValue("restaurantName", bagsizedetailsdata?.data?.restaurantName)


      values.surpriseBagRetailPrice =
        bagsizedetailsdata?.data?.surpriseBagRetailPrice;
      setFieldValue(
        "surpriseBagRetailPrice",
        bagsizedetailsdata?.data?.surpriseBagRetailPrice
      );
      values.surpriseBagSellingPrice =
        bagsizedetailsdata?.data?.surpriseBagSellingPrice;
      setFieldValue(
        "surpriseBagSellingPrice",
        bagsizedetailsdata?.data?.surpriseBagSellingPrice
      );


      values.restaurantName = bagsizedetailsdata?.data?.restaurantName;
      setFieldValue("restaurantName", bagsizedetailsdata?.data?.restaurantName);



    }
    // ToastMessage("error", getstatedata?.message);
  }, [bagsizedetailsdata]);

  useEffect(() => {
    const parameter = {
      url: `${get_bagsize_api}${id}`,
    };
    dispatch(bagsizeDetails(parameter));
  }, [id]);

  useEffect(() => {
    const percentageValue = (Number(values.discount) / 100) * values.surpriseBagRetailPrice;
    values.surpriseBagSellingPrice = values.surpriseBagRetailPrice - parseFloat(percentageValue.toFixed(2));
    console.log("percentageValue", percentageValue)
  }, [values.surpriseBagRetailPrice, values.discount]);

  const handleselectchange = (e) => {
    values.restaurantTypeIdFk = e.target.value;
    setFieldValue("restaurantTypeIdFk", e.target.value);
    // values.restaurantTypeIdFk = parseInt(values.restaurantTypeIdFk, 10);
  };
  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };



  const handleautocomplete = (event, newValue) => {
    setFieldValue("restaurantIdFK", newValue.restaurantIdFK)
    setFieldValue("restaurantName", newValue.restaurantName)
    setValueshowdropdown(newValue)
  };
  

  useEffect(() => {
    const parameter = {
      url: resturant_dropdown_list,
      search: "",
    };
    dispatch(resturantListaction(parameter));
  }, []);


  useEffect(() => {
    if (resturantlistdata?.status) {
      const updatedData = resturantlistdata?.data?.map(item => ({
        ...item,
        valueshow: `${item.restaurantName} - ${item.restaurantId}`
      }));
      setResturantnamelistdrop(updatedData)
    }
  }, [resturantlistdata]);


  useEffect(() => {
    const selectedRestaurant = resturantnamelistdrop?.find(item => item.restaurantIdFK === Number(values.restaurantIdFK));

    if (selectedRestaurant) {
      const selectedRestaurantTypeId = selectedRestaurant.restaurantTypeIdFk;
      const selectedRestaurantType = selectedRestaurant.restaurantType;
      setSelectedRestaurantType(selectedRestaurantType);
      setFieldValue('restaurantTypeIdFk', selectedRestaurantTypeId);
    }
  }, [values.restaurantIdFK]);



  // const restaurantOptions = resturantlistdata?.status && Array.isArray(resturantlistdata.data) ? resturantlistdata.data : [];



  const [selectedRestaurantType, setSelectedRestaurantType] = useState(null);

  // Handle the change in restaurant selection
  const handleRestaurantChange = (e) => {
    const selectedRestaurantId = e.target.value;
    const selectedRestaurant = resturantnamelistdrop?.find(item => item.restaurantIdFK === Number(selectedRestaurantId));

    if (selectedRestaurant) {
      const selectedRestaurantTypeId = selectedRestaurant.restaurantTypeIdFk;
      // Extract the restaurantTypeIdFk value
      const selectedRestaurantType = selectedRestaurant.restaurantType;
      // Get the restaurantType

      // Set the field values
      setSelectedRestaurantType(selectedRestaurantType);
      setFieldValue('restaurantIdFK', selectedRestaurantId);
      setFieldValue('restaurantTypeIdFk', selectedRestaurantTypeId);
      // Set the correct value for restaurantTypeIdFk

      console.log("restaurantTypeIdFk", selectedRestaurantTypeId); // Log the selected restaurantTypeIdFk
    }
  };


  const checkArrId = (arr, id) => {
    const data = arr?.filter((item) => item.restaurantIdFK == id);
    console.log("dataaaaaa", data)
    return data;
  };

  return (
    <CForm onSubmit={handleSubmit2}>
      <h3
        className="AddEdit_Heading"

      >
        {" "}
        <ArrowBackIcon onClick={handleBackNavigation}
          style={{ cursor: "pointer" }} />
        {id ? "Edit Magic Bag Size" : "Add Magic Bag Size"}
      </h3>

      <CContainer className="add_form_container">
        {visible ? (
          <StatusModal
            {...{ visible, setVisible, setStatusvalue }}
            restaurantId={id}
            statusId={accountstatus}
          />
        ) : (
          ""
        )}
        <CRow xs={{ gutter: 4 }}>

          <CCol sm={12} md={6}>
            {/* <CFormSelect
              name="restaurantName"
              // disabled={id} // Assuming 'id' disables the select dropdown
              label="Restaurant list"
              placeholder="Select a Type"
              invalid={touched.restaurantName && !!errors.restaurantName} // Form validation
              value={values.restaurantName} // Formik value for the restaurant list
              onChange={(e) => {
                handleRestaurantChange(e); // Custom change handler for restaurant type
              }}
              id="restaurantName"
              onBlur={handleBlur} // Handle blur event for form validation
            >
              <option value="">Select a Type</option>
              {restaurantOptions?.map((item, index) => (
                <option key={index} value={item.restaurantIdFK}>
                  {item.restaurantName} - <span style={{ color: '#888', fontSize: '0.9em' }}><br></br>
                  {item.restaurantId}
                  </span>
                </option>
              ))}
            </CFormSelect>
            {touched.restaurantName && errors.restaurantName && (
              <div className="invalid-feedback">
                {errors.restaurantName}
              </div>
            )} */}

            <label className="mb-2"> Restaurant list</label>


            <Autocomplete
             disabled={id}
              size="small"
              disablePortal
              key={checkArrId(resturantnamelistdrop, values.restaurantIdFK)[0]?.valueshow}
              options={resturantnamelistdrop}
              getOptionLabel={(option) => option.valueshow || ""}
              isOptionEqualToValue={(option, value) => value.restaurantIdFK === option.restaurantIdFK}
              defaultValue={checkArrId(resturantnamelistdrop, values.restaurantIdFK)[0] || ""}
              onChange={(event, newValue) => {
                handleautocomplete(event, newValue); // Call your handler function
              }}
              renderInput={(params) => <TextField {...params} placeholder="Restaurant" />}
              sx={{
                width: "100%",
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  borderColor: '#ebebeb',
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                  borderColor: '#ebebeb',
                },
              }}
            />


               {/* <Autocomplete
                disablePortal
                disableClearable
                key={checkArrId(categoryName, values?.category_Id)[0]?.category_name}
                options={categoryName}
                getOptionLabel={(option) => option.category_name}
                isOptionEqualToValue={(option, value) => value.id === option.id}
                defaultValue={checkArrId(categoryName, values.category_Id)[0]}
              
                onChange={(event, newValue) => { handleautocomplete(event, newValue) }}
                renderInput={(params) => <TextField {...params} placeholder="Restaurant" />}

              /> */}


            {/* {selectedRestaurantType && <h1>{selectedRestaurantType}</h1>} */}

          </CCol>




          <CCol sm={12} md={6}>
            <CFormSelect
              name="restaurantTypeIdFk"
              disabled={true}  // Disable the dropdown
              label="Restaurant Type"
              placeholder="Select a Type"
              invalid={touched.restaurantTypeIdFk && !!errors.restaurantTypeIdFk}
              value={selectedRestaurantType || ''}  // Prefill with selected restaurant type, or empty if none
              id="restaurantTypeIdFk"
              onBlur={handleBlur} // Handle blur event for form validation
            >
              <option value="">{selectedRestaurantType || 'Select a Type'}</option>
              {/* The dropdown options are hidden, so no <option> elements here */}
            </CFormSelect>

          </CCol>

          <CCol sm={12} md={6}>
            <CFormInput
              name="surpriseBagSize"
              disabled={id}
              type={errors.surpriseBagSize}
              invalid={touched.surpriseBagSize && !!errors.surpriseBagSize}
              value={values.surpriseBagSize}
              id="surpriseBagSize"
              label="Bag Size *"
              placeholder="Bag Size"
              onChange={handleChange}
              // text={errors.surpriseBagSize}
              onBlur={handleBlur}
            />
            {touched.surpriseBagSize && errors.surpriseBagSize && (
              <div className="invalid-feedback">{errors.surpriseBagSize}</div>
            )}
          </CCol>
          {/* <CCol sm={12} md={6}>
            <CFormSelect
              name="restaurantTypeIdFk"
              disabled={id}
              type={errors.restaurantTypeIdFk}
              label="Restaurant Type"
              placeholder="Country Name"
              text={errors.restaurantTypeIdFk}
              invalid={
                touched.restaurantTypeIdFk && !!errors.restaurantTypeIdFk
              }
              value={values.restaurantTypeIdFk}
              onChange={handleselectchange}
              id="restaurantTypeIdFk"
              onBlur={handleBlur}
            >
              <option value="" disabled selected>
                Select a Type
              </option>
              {typelist?.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}

              {touched.restaurantTypeIdFk && errors.restaurantTypeIdFk && (
                <div className="invalid-feedback">
                  {errors.restaurantTypeIdFk}
                </div>
              )}
            </CFormSelect>
          </CCol> */}

          <CCol sm={12} md={6}>
            <Form.Group controlId="validationCustomCommission">
              <Form.Label>Commission in % *</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="number"
                  name="commission"
                  label="commission"
                  text={errors.commission}
                  invalid={touched.commission && !!errors.commission}
                  value={values.commission}
                  id="commission"
                  onBlur={handleBlur}
                  // onChange={(e) => {
                  //   const { value } = e.target;
                  //   if (value === "" || /^\d*[1-9]\d*$/.test(value)) {
                  //     handleChange(e);
                  //   }
                  // }}
                  onChange={handleChange}
                  isInvalid={touched.commission && !!errors.commission}
                  placeholder="Commission in %"
                  aria-describedby="inputGroupPrepend"
                />

                <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.commission}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </CCol>

          <CCol sm={12} md={6}>
            <Form.Group controlId="validationCustomRetailPrice">
              <Form.Label>Retail Price in INR *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">₹</InputGroup.Text>
                <Form.Control
                  name="surpriseBagRetailPrice"
                  label="surpriseBagRetailPrice"
                  text={errors.surpriseBagRetailPrice}
                  id="surpriseBagRetailPrice"
                  type="number"
                  onBlur={handleBlur}
                  value={values.surpriseBagRetailPrice}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === "" || /^\d*[1-9]\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  isInvalid={
                    touched.surpriseBagRetailPrice &&
                    !!errors.surpriseBagRetailPrice
                  }
                  placeholder="Retail Price in INR"
                  aria-describedby="inputGroupPrepend"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.surpriseBagRetailPrice}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </CCol>
          <CCol sm={12} md={6}>
            <Form.Group controlId="validationCustomCommission">
              <Form.Label>Discount in % *</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="number"
                  name="discount"
                  label="discount"
                  text={errors.discount}
                  invalid={touched.discount && !!errors.discount}
                  value={values.discount}
                  id="discount"
                  onBlur={handleBlur}
                  // onChange={(e) => {
                  //   const { value } = e.target;
                  //   if (value === "" || /^\d*[1-9]\d*$/.test(value)) {
                  //     handleChange(e);
                  //   }
                  // }}
                  onChange={handleChange}
                  isInvalid={touched.discount && !!errors.discount}
                  placeholder="Discount in %"
                  aria-describedby="inputGroupPrepend"
                />

                <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.discount}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </CCol>

          <CCol sm={12} md={6}>
            <Form.Group md="4" controlId="validationCustomUsername">
              <Form.Label>Selling Price in INR *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">₹</InputGroup.Text>
                <Form.Control
                  name="surpriseBagSellingPrice"
                  type="number"
                  disabled
                  value={values.surpriseBagSellingPrice}
                  id="surpriseBagSellingPrice"
                  label="Selling Price in INR *"
                  placeholder="Selling Price in INR"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === "" || /^\d+$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  invalid={
                    touched.surpriseBagSellingPrice &&
                    !!errors.surpriseBagSellingPrice
                  }
                />
                {touched.surpriseBagSellingPrice &&
                  errors.surpriseBagSellingPrice && (
                    <div className="invalid-feedback">
                      {errors.surpriseBagSellingPrice}
                    </div>
                  )}
              </InputGroup>
            </Form.Group>
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
          <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              width: "120px",
              color: "var(--colorWhite)",
            }}
            type="submit"
            className="me-md-2"
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              <>{id ? "Update" : "Submit"}</>
            )}
          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default SurpriseBagAdd;
