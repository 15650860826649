
// import { CircularProgress, Container, Grid } from '@mui/material';
// import './index.css'
// import { useFormik } from 'formik';
// import { useState, useEffect } from 'react';
// import { CButton, CCol, CRow } from '@coreui/react';
// import CkEditor from '../../ckEditor';
// import { create_setting_api, get_setting_api } from '../../API/baseUrl';
// import { useDispatch, useSelector } from 'react-redux';
// import { getsettingAction, removeDataFromReducer, settingAction } from '../../redux/actions';
// import ToastMessage from '../../utils/ToastMessage';

// const Intial = {
//     data: '',
//     type: ''
// }

// export default function UserPrivacy() {
//     const [ckValue, setCkValue] = useState("")
//     const { settingdata } = useSelector((state) => state?.settingReduser || {});
//     const { getsettingdata } = useSelector((state) => state?.getsettingReduser || {});
//     const dispatch = useDispatch();
    

//     const [editTerms, setEditTerms] = useState(true)
//     const [loading, setLoading] = useState(false)

//     const { setValues, handleBlur, handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
//         initialValues: Intial,
//         validationSchema: "",
//         enableReinitialize: true,
//         // onSubmit: () => handlePrivacyData(),
//     });


//     const handlePrivacyData = async () => {
//         setLoading(true)
//         const parameter = {
//             data: ckValue,
//             type: "userPrivacyPolicy",
//             url:create_setting_api,
//         };
//         dispatch(settingAction(parameter));
//     }



//     const ckChange = (text, type, key, index) => {
//         values.privacyContent = type.getData()
//         setCkValue(type.getData())
//         setFieldValue("privacyContent", values.privacyContent)
//     }


    
//   useEffect(() => {
//     if (settingdata?.status) {
//       ToastMessage("success", settingdata?.message);
//       dispatch(removeDataFromReducer("CREATE_SETTING_RESET"));
//       setLoading(false); 
//     } else {
//       ToastMessage("error", settingdata?.message);
//       setLoading(false); 
//     }
//   }, [settingdata]);


//   useEffect(() => {
//     if (getsettingdata?.status) {
//         setCkValue(getsettingdata.data?.data)
//       ToastMessage("success", getsettingdata?.message);
//       setLoading(false); 
//     } else {
//     //   ToastMessage("error", getsettingdata?.message);
//       setLoading(false); 
//     }
//   }, [getsettingdata]);

    


//   useEffect(() => {
//     const parameter = {
//         type: "vendorPrivacyPolicy",
//         url:get_setting_api,
//     };
//     dispatch(getsettingAction(parameter));
//   }, []);


//     const handleEditTerms = () => {
//         setEditTerms(false)
//         // setFieldValue("privacyContent", values.privacyContent)
//     }

//     // const handleUpdateTerms = () => {
//     //     setEditTerms(true)
//     //     setFieldValue("privacyContent", values.privacyContent)
//     //     handleSubmit()
//     // }


//     const privacyGet = async () => {
//         // const id = 1
//         // try {
//         //     const result = await getApiCallToken(`${getTermsandCondition}${id}`)
//         //     setCkValue(result.data.data.privacy_policy)
//         // } catch (error) {
//         //     console.log("errors", error);
//         // }
//     }


//     useEffect(() => {
//         privacyGet()
//     }, [])



//     const toolbarConfig = {

//         toolbar: [
//             'heading',
//             'bold',
//             'italic',
//             'link',
//             'bulletedList',
//             'numberedList',
//             'blockQuote',
//             'undo',
//             'redo',
//         ],
//     };




//     return (
//         <div className=''>
//             <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
//                 <Grid item xs={12} md={12} className='CKEditHeightClass privacyImageCKEditor'>
//                     <CkEditor
//                         config={toolbarConfig}
//                         disable={editTerms}
//                         onChange={(text, type) => ckChange(text, type, "content")}
//                         data={ckValue}
//                         name="data"
//                         label="privacyCkedit"
//                         autoComplete="off"
//                         sx={{ width: '100%' }}
//                         onBlur={handleBlur}
//                         value={ckValue}
//                     />
//                 </Grid>

//                 <div className="d-grid gap-2 d-md-flex justify-content-md-end privacy_submit_button">
//                     <CButton style={{
//                         backgroundColor: "var(--colorused)",
//                         border: "none",
//                         width: "120px",
//                         color: "var(--colorWhite)",
//                     }} type="button"
//                         onClick={handlePrivacyData}
//                         className="me-md-2">
//                         {loading ? (
//                             <CircularProgress size={24} style={{ color: "white" }} />
//                         ) :
//                             <>Submit</>
//                         }
//                     </CButton>
//                 </div>
//             </Grid>
//         </div>
//     );
// }







import { CircularProgress, Container, Grid } from '@mui/material';
import './index.css'
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { CButton, CCol, CRow } from '@coreui/react';
import CkEditor from '../../ckEditor';
import { create_setting_api, get_setting_api, setting_edit_api } from '../../API/baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { editstatebyidRequest, getsettingAction, removeDataFromReducer, settingAction } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';

const Intial = {
    data: '',
    type: ''
}

export default function UserPrivacy({showupdate}) {
    const [ckValue, setCkValue] = useState("")
    const { settingdata } = useSelector((state) => state?.settingReduser || {});
    const { getsettingdata } = useSelector((state) => state?.getsettingReduser || {});
    const { editStatedata } = useSelector((state) => state?.EditStateReduser || {});
    const dispatch = useDispatch();
    

    const [editTerms, setEditTerms] = useState(true)
    const [loading, setLoading] = useState(false)

    const { setValues, handleBlur, handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
        initialValues: Intial,
        validationSchema: "",
        enableReinitialize: true,
        // onSubmit: () => handlePrivacyData(),
    });


    // const handlePrivacyData = async () => {
    //     setLoading(true)
    //     const parameter = {
    //         data: ckValue,
    //         type: "vendorPrivacyPolicy",
    //         url:create_setting_api,
    //     };
    //     dispatch(settingAction(parameter));
    // }

    const handlePrivacyData = async () => {
        setLoading(true)
        const parameter = {
            data: ckValue,
            description: "",
            url: `${setting_edit_api}${getsettingdata.data?.id}` ,
        };
        dispatch(editstatebyidRequest(parameter));
    }

    // const editparameter = {
    //     data: values.data,
    //     description: values.description,
    //     url:  `${setting_edit_api}${getsettingdata.data?.id}` ,
    //   };
    //   if(id){
    //     dispatch(editstatebyidRequest(editparameter));
    //   }

    const ckChange = (text, type, key, index) => {
        values.privacyContent = type.getData()
        setCkValue(type.getData())
        setFieldValue("privacyContent", values.privacyContent)
    }

    
  useEffect(() => {
    if (editStatedata?.status) {
      ToastMessage("success", editStatedata?.message);
      dispatch(removeDataFromReducer("STATE_EDIT_RESET"));
      setLoading(false); 
    } else {
      ToastMessage("error", editStatedata?.message);
      setLoading(false); 
    }
  }, [editStatedata]);


  useEffect(() => {
    if (getsettingdata?.status) {
        setCkValue(getsettingdata.data?.data)
      ToastMessage("success", getsettingdata?.message);
      setLoading(false); 
    } else {
    //   ToastMessage("error", getsettingdata?.message);
      setLoading(false); 
    }
  }, [getsettingdata]);

    


  useEffect(() => {
    const parameter = {
        type: "userPrivacyPolicy",
        url:get_setting_api,
    };
    dispatch(getsettingAction(parameter));
  }, []);


    const handleEditTerms = () => {
        setEditTerms(false)
        // setFieldValue("privacyContent", values.privacyContent)
    }

    // const handleUpdateTerms = () => {
    //     setEditTerms(true)
    //     setFieldValue("privacyContent", values.privacyContent)
    //     handleSubmit()
    // }


    const privacyGet = async () => {
        // const id = 1
        // try {
        //     const result = await getApiCallToken(`${getTermsandCondition}${id}`)
        //     setCkValue(result.data.data.privacy_policy)
        // } catch (error) {
        //     console.log("errors", error);
        // }
    }


    useEffect(() => {
        privacyGet()
    }, [])



    const toolbarConfig = {

        toolbar: [
            'heading',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
        ],
    };




    return (
        <div className=''>
            <Grid item xs={12} md={12} sx={{ padding: "5px" }}>
                <Grid item xs={12} md={12} className='CKEditHeightClass privacyImageCKEditor'>
                    <CkEditor
                        config={toolbarConfig}
                        disable={editTerms}
                        onChange={(text, type) => ckChange(text, type, "content")}
                        data={ckValue}
                        name="data"
                        label="privacyCkedit"
                        autoComplete="off"
                        sx={{ width: '100%' }}
                        onBlur={handleBlur}
                        value={ckValue}
                    />
                </Grid>
             { showupdate == true ? 

                <div className="d-grid gap-2 d-md-flex justify-content-md-end privacy_submit_button">
                    <CButton style={{
                        backgroundColor: "var(--colorused)",
                        border: "none",
                        width: "120px",
                        color: "var(--colorWhite)",
                    }} type="button"
                        onClick={handlePrivacyData}
                        disabled={getsettingdata?.data?.id ? false : true}
                        className="me-md-2">
                        {loading ? (
                            <CircularProgress size={24} style={{ color: "white" }} />
                        ) :
                            <>Update</>
                        }
                    </CButton>
                </div> :""}
            </Grid>
        </div>
    );
}
