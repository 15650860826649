import { CButton, CCol, CFormInput, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cityStatus_api, creatRole_api, editRole_api, getcitylist_api, Roleby_id_api, Rolelist_api } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';

import {
  addcityRequest,
  cityList,
  cityStatusRequest,
  getcitybyidRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import { CityTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { useFormik } from "formik";
import { adminroleValidation } from "../../utils/schema";



const Adminrole = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [idforedit, setIdforedit] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(localStorage.getItem('currentPage'));
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { cityListInfo } = useSelector((state) => state?.cityList || {});
  const { citycreatedata } = useSelector((state) => state?.cityaddreduser) || {};


  const Intial = {
    user_role: "",
  };
  

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: adminroleValidation,
    onSubmit: (values) => ApiHandlesubmit(),
  });





  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      user_role: values.user_role,
      url: idforedit ? `${editRole_api}${idforedit}` : creatRole_api,
    };
      dispatch(addcityRequest(parameter));
  };


  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline:"none",
    p: 2,
  };

  const handleClose = () => {
    setIdforedit("")
    setOpen(false);
  }
    


  useEffect(() => {

    document.title = "Role";
  }, []);

  const handleAdd = (e) => {
    values.user_role = ""
    setOpen(true)
  };



  useEffect(() => {
    if (cityListInfo?.status) {
      setData(cityListInfo?.data);
      setTotalRows(cityListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", cityListInfo?.message);
    }
  }, [cityListInfo]);


  useEffect(() => {
    if (citycreatedata?.status) {
      setLoading(false)
      ToastMessage("success", citycreatedata?.message);  
       getlistdata()
      setOpen(false)
      dispatch(removeDataFromReducer("ADD_CITY_RESET"));
    } else {
      setLoading(false)
      ToastMessage("error", citycreatedata?.message);
    }
  }, [citycreatedata]);



  const getlistdata = () =>{
    setPending(true);
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: Rolelist_api,
    };
    dispatch(cityList(parameter));
  }


  useEffect(() => {
    getlistdata()
  }, [offset, limit, searchText, sortByColumnName, sortBy]);



  const columns = [
    {
      name: "Id",
      selector: (row) => row?.user_id,
      sortable: true,
      column_name: "user_id",
      reorder: true,
    },
    {
      name: "Role",
      selector: (row) => row.user_role,
      sortable: true,
      column_name: "user_role",
      reorder: true,
    },


    ...(parsedDatapermission?.adminRoleedit == 1
      ? [
        {
          name: "Edit",
          selector: (row) => (
            <button className="edit_btn" onClick={() => handleEdit(row.user_id , row.user_role)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Edit
            </button>
          ),
          // sortable: true,
          column_name: "",
          reorder: true,
        },
        ]
      : []),




   
  ];

  const handleEdit = (id, role) => {
    setFieldValue("user_role",role)
    values.user_role = role
    setIdforedit(id)
    setOpen(true)
    // dispatch(removeDataFromReducer("CITY_DETAILS_RESET"));
    // navigate(`/dashboard/city/add/${id}`);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };

  const handlePerRowsChange = async (newLimit, offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };



  const handleclearSearch = () => {
    setSearchText("");
  };
  return (
    <div>


      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Role</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>

          {parsedDatapermission?.adminRoleedit == 1 ? 
             <CButton
             style={{
               backgroundColor: "var(--colorused)",
               border: "none",
               color: "var(--colorWhite)",
               fontSize: "13px",
               overflow: "hidden",
               whiteSpace: "nowrap",
               textOverflow: "ellipsis",
             }}
             className="me-md-2 btn_add_country"
             onClick={handleAdd}
           // disabled={isLoading}
           >
             Add Role
           </CButton> 
        :"" }
       
        </div>
      </div>
      {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-5">
        <CButton color="primary" className="me-md-2" onClick={handleAdd}>Add City</CButton>
      </div> */}
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} 
        component="form"
        onSubmit={handleSubmit2}
        >
          <Typography sx={{mb:"2px"}} id="modal-modal-title" variant="h6" component="h2">
             {idforedit ? "Edit Role" : "Add Role"}
          </Typography>
          <CFormInput
          name="user_role"
          type="text"
          value={values.user_role}
          id="exampleFormControlInput1"
          label="User Role*"
          placeholder="User Role"
          onChange={handleChange}
          onBlur={handleBlur}
          // text={errors.cityName}
          invalid={touched.user_role && !!errors.user_role}

          aria-describedby="exampleFormControlInputHelpInline"
          className="field-width_modal"

        />
        {touched.user_role && errors.user_role && (
          <div className="invalid-feedback">{errors.user_role}</div>
        )}


<div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">
    
      <CButton style={{
        backgroundColor: "red",
        border: "none",
        // width: "120px",
        color: "var(--colorWhite)",
      }} type="button"
      onClick={handleClose}
      className="me-md-2">
          <>Cancel</>
      </CButton> 
      
      <CButton style={{
        backgroundColor: "var(--colorused)",
        border: "none",
        // width: "120px",
        color: "var(--colorWhite)",
      }} type="submit" className="me-md-2">
        {loading ? (
          <CircularProgress size={24} style={{ color: "white" }} />
        ) :

          <>Submit</>
        }

      </CButton>
    </div>


        </Box>
      </Modal>




    </div>
  );
};

export default Adminrole;