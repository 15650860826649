import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import { permission_get_api, signIn_api } from "../../../API/baseUrl";
import { loginschema } from "../../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, permissiongetaction } from "../../../redux/actions";
import ToastMessage from "../../../utils/ToastMessage";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress from Material-UI
import Logo from "../../../components/logo";
import { getTokenfun, onMessageListener } from "../../../firebase";

const Intial = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state?.loginUser || {});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const[email , setEmail] = useState("");



  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  getTokenfun(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));









  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: loginschema,
    onSubmit: (values) => handleLogin(values),
  });

  const handleLogin = async (values) => {
    setLoading(true); 
    
    setEmail(values.email)
    localStorage.setItem('email', values.email)
    const parameter = {
      email: values.email,
      password: values.password,
      url: signIn_api,
    };
    dispatch(loginUser(parameter));
  };

  useEffect(() => {


    if (loginInfo?.status) {
      localStorage.setItem("auth", true);
      // localStorage.setItem("accessToken", loginInfo?.accessToken);
      // localStorage.setItem("refreshToken", loginInfo?.refreshToken);
      localStorage.setItem("userIdFk", loginInfo?.adminId);
      ToastMessage("success", loginInfo?.message);

    const parameter = {
      url: `${permission_get_api}${loginInfo?.roleId}`,
    }
    if(loginInfo?.roleId){
      dispatch(permissiongetaction(parameter));
    }
  
      // navigate("/dashboard/app");
      navigate("/verify/Otp");
    } else {
      ToastMessage("error", loginInfo?.message);
    }
    setLoading(false); // Stop loading indicator
  }, [loginInfo, navigate]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bglogin">
      <CContainer>
        <CRow className="justify-content-center login_containrr">
          <CCol md={6} sx={12} sm={10}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <Logo />

                    <div style={{ margin: "20px 0px" }}>
                      {/* <h2>Login</h2> */}
                      <small
                        className="text-body-secondary mb-4"
                        style={{ fontSize: "15px" }}
                      >
                        Login to your account
                      </small>
                    </div>

                    <FormControl
                      sx={{ width: "100%", mb: 2 }}
                      variant="outlined"
                      className="textfield_background"
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Email"
                        sx={{backgroundColor:"rgb(232, 240, 254)"}}
                      />
                      {errors.email && touched.email && (
                        <p className="form-error">{errors.email}</p>
                      )}
                    </FormControl>

                    <FormControl
                      sx={{ width: "100%", mb: 2 }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="current-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {!showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        sx={{backgroundColor:"rgb(232, 240, 254)"}}
                      />
                      {errors.password && touched.password && (
                        <div className="form-error">{errors.password}</div>
                      )}
                    </FormControl>

                    <CButton
                      color="primary"
                      className="px-4 btn_add_country"
                      type="submit"
                      style={{ background: "var(--colorused)", border: "none" }}
                    >
                      {loading ? (
                        <CircularProgress
                          size={24}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "Login"
                      )}
                    </CButton>

                   
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
