import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import "./index.css"
import { useDispatch, useSelector } from "react-redux";
import { permission_get_api, permission_update_api, userrole_dropdown_api } from "../../API/baseUrl";
import { permissiongetaction, permissiongetactionforvalue, permissionUpdateaction, removeDataFromReducer, roledropdownaction } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { CButton } from "@coreui/react";
import { useFormik } from "formik";
import Permissionui from "./permissionui";

const Permission = () => {
  const [role, setRole] = useState("");
  const [roledropdownval, setRoledropdownval] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { roledropdownData } = useSelector((state) => state?.roledropdownReduser) || {};
  const { permissionUpdateData } = useSelector((state) => state?.permissionUpdateReduser) || {};
  const { permissionGetvalueData } = useSelector((state) => state?.permissionGetvalueReduser) || {};




  const Intial = {
    roleidFk: 0,
    liveVendorsview: false,
    liveVendorsedit: false,
    vendorsAccountsview: false,
    vendorsAccountsedit: false,
    vendorsDocumentview: false,
    vendorsDocumentedit: false,

    adminUserview: false,
    adminUseredit: false,
    adminRoleview: false,
    adminRoleedit: false,
    adminPermissionView: false,
    adminPermissionedit: false,

    countryView: false,
    countryEdit: false,
    stateView: false,
    stateEdit: false,
    cityView: false,
    cityEdit: false,

    dashboardView: false,
    dashboardEdit: false,
    usersView: false,
    usersEdit: false,
    orderView: false,
    orderEdit: false,
    billingsView: false,
    billingsEdit: false,
    restaurantView: false,
    restaurantEdit: false,
    magicbagSizeview: false,
    magicbagSizeedit: false,
    cuisineView: false,
    cuisneEdit: false,
    bankView: false,
    bankEdit: false,
    faqView: false,
    faqEdit: false,
    supportView: false,
    supportEdit: false,
    privacyPolicyView: false,
    privacyPolicyEdit: false,
    termsandconditionView: false,
    termsandconditionEdit: false,
    settingsView: false,
    settingsEdit: false
  }




  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: "",
    onSubmit: (values) => "",
  });






  const datapermission = localStorage.getItem("permissiondata")
  const parsedDatapermission = JSON.parse(datapermission);





  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };


  const handlepermissionUpdate = (event) => {
    setLoading(true)
   
    const parameter = {
      roleidFk: role,
      liveVendorsview: values.liveVendorsview == true || values.liveVendorsedit == true ? 1 : 0,
      liveVendorsedit: values.liveVendorsedit == true ? 1 : 0,
      vendorsAccountsview: values.vendorsAccountsview == true || values.vendorsAccountsedit == true ? 1 : 0,
      vendorsAccountsedit: values.vendorsAccountsedit == true ? 1 : 0,
      vendorsDocumentview: values.vendorsDocumentview == true || values.vendorsDocumentedit == true ? 1 : 0,
      vendorsDocumentedit: values.vendorsDocumentedit == true ? 1 : 0,
      adminUserview: values.adminUserview == true || values.adminUseredit ? 1 : 0,
      adminUseredit: values.adminUseredit == true ? 1 : 0,


      adminRoleview: values.adminRoleview == true || values.adminRoleedit == true ? 1 : 0,
      adminRoleedit: values.adminRoleedit == true ? 1 : 0,

      adminPermissionView: values.adminPermissionView == true || values.adminPermissionedit == true ? 1 : 0,
      adminPermissionedit: values.adminPermissionedit == true ? 1 : 0,

      countryView: values.countryView == true || values.countryEdit == true ? 1 : 0,
      countryEdit: values.countryEdit == true ? 1 : 0,

      stateView: values.stateView == true || values.stateEdit == true ? 1 : 0,
      stateEdit: values.stateEdit == true ? 1 : 0,
      cityView: values.cityView == true || values.cityEdit == true ? 1 : 0,
      cityEdit: values.cityEdit == true ? 1 : 0,
      dashboardView: values.dashboardView == true || values.dashboardEdit == true ? 1 : 0,
      dashboardEdit: values.dashboardEdit == true ? 1 : 0,
      usersView: values.usersView == true || values.usersEdit == true ? 1 : 0,
      usersEdit: values.usersEdit == true ? 1 : 0,
      orderView: values.orderView == true || values.orderEdit == true ? 1 : 0,
      orderEdit: values.orderEdit == true ? 1 : 0,
      billingsView: values.billingsView == true || values.billingsEdit == true ? 1 : 0,
      billingsEdit: values.billingsEdit == true ? 1 : 0,
      restaurantView: values.restaurantView == true || values.restaurantEdit == true ? 1 : 0,
      restaurantEdit: values.restaurantEdit == true ? 1 : 0,
      magicbagSizeview: values.magicbagSizeview == true || values.magicbagSizeedit == true ? 1 : 0,
      magicbagSizeedit: values.magicbagSizeedit == true ? 1 : 0,
      cuisineView: values.cuisineView == true || values.cuisneEdit == true ? 1 : 0,
      cuisneEdit: values.cuisneEdit == true ? 1 : 0,
      bankView: values.bankView == true || values.bankEdit == true ? 1 : 0,
      bankEdit: values.bankEdit == true ? 1 : 0,
      faqView: values.faqView == true || values.faqEdit == true ? 1 : 0,
      faqEdit: values.faqEdit == true ? 1 : 0,
      supportView: values.supportView == true || values.supportEdit == true ? 1 : 0,
      supportEdit: values.supportEdit == true ? 1 : 0,
      privacyPolicyView: values.privacyPolicyView == true || values.privacyPolicyEdit == true ? 1 : 0,
      privacyPolicyEdit: values.privacyPolicyEdit == true ? 1 : 0,
      termsandconditionView: values.termsandconditionView == true || values.termsandconditionEdit == true ? 1 : 0,
      termsandconditionEdit: values.termsandconditionEdit == true ? 1 : 0,
      settingsView: values.settingsView == true || values.settingsEdit == true ? 1 : 0,
      settingsEdit: values.settingsEdit == true ? 1 : 0,
      url: permission_update_api,
    };

    if( values.liveVendorsview == true || values.liveVendorsedit == true || values.vendorsAccountsview == true ||  values.vendorsAccountsedit == true || values.vendorsDocumentview == true || values.vendorsDocumentedit == true || values.adminUserview == true || values.adminUseredit == true || 
      values.adminRoleview == true ||  values.adminRoleedit == true || values.adminPermissionView == true ||  values.adminPermissionedit == true ||  values.countryView == true || 
      values.countryEdit == true || 
      values.stateView == true || 
      values.stateEdit == true || 
      values.cityView == true || 
      values.cityEdit == true ||  values.dashboardView == true || values.dashboardEdit == true || 
      values.usersView == true || 
      values.usersEdit == true || 
      values.orderView == true || 
      values.orderEdit == true || 
      values.billingsView == true || 
      values.billingsEdit == true || 
      values.restaurantView == true || 
      values.restaurantEdit == true || 
      values.magicbagSizeview == true || 
      values.magicbagSizeedit == true || 
      values.cuisineView == true || 
      values.cuisneEdit == true || 
      values.bankView == true || 
      values.bankEdit == true || 
      values.faqView == true || 
      values.faqEdit == true || 
      values.supportView == true || 
      values.supportEdit == true || 
      values.privacyPolicyView == true || 
      values.privacyPolicyEdit == true || 
      values.termsandconditionView == true || 
      values.termsandconditionEdit == true || 
      values.settingsView == true || 
      values.settingsEdit == true){
        dispatch(permissionUpdateaction(parameter));
      }
      else{
        ToastMessage("error", "Please check atleast one permission");
        setLoading(false)
      }


    // dispatch(permissionUpdateaction(parameter));

    // console.log("resultvalue",keysToCheck)
  };


  useEffect(() => {
    document.title = "Permission";
  }, []);

  useEffect(() => {
    const parameter = {
      url: userrole_dropdown_api,
    };
    dispatch(roledropdownaction(parameter));
  }, []);




  useEffect(() => {

    const parameter = {
      url: `${permission_get_api}${role}`,
    };
    if (role) {
      dispatch(permissiongetactionforvalue(parameter));
    }
  }, [role]);



  useEffect(() => {
    if (permissionUpdateData?.status) {
      ToastMessage("success", permissionUpdateData?.message);
      dispatch(removeDataFromReducer("PERMISSION_UPDATE_RESET"));
      setLoading(false)
    } else {
      ToastMessage("error", permissionUpdateData?.message);
      setLoading(false)
    }
  }, [permissionUpdateData]);


  useEffect(() => {
    if (roledropdownData?.status) {
      setRoledropdownval(roledropdownData?.data);
    } else {
      ToastMessage("error", roledropdownData?.message);
    }
  }, [roledropdownData]);



  useEffect(() => {
    if (permissionGetvalueData?.status) {

      setFieldValue("liveVendorsview", permissionGetvalueData?.data.liveVendorsview)
      setFieldValue("liveVendorsedit", permissionGetvalueData?.data.liveVendorsedit)
      setFieldValue("vendorsAccountsview", permissionGetvalueData?.data.vendorsAccountsview)
      setFieldValue("vendorsAccountsedit", permissionGetvalueData?.data.vendorsAccountsedit)
      setFieldValue("vendorsDocumentview", permissionGetvalueData?.data.vendorsDocumentview)
      setFieldValue("vendorsDocumentedit", permissionGetvalueData?.data.vendorsDocumentedit)
      setFieldValue("adminUserview", permissionGetvalueData?.data.adminUserview)
      setFieldValue("adminUseredit", permissionGetvalueData?.data.adminUseredit)
      setFieldValue("adminRoleview", permissionGetvalueData?.data.adminRoleview)
      setFieldValue("adminRoleedit", permissionGetvalueData?.data.adminRoleedit)
      setFieldValue("adminPermissionView", permissionGetvalueData?.data.adminPermissionView)
      setFieldValue("adminPermissionedit", permissionGetvalueData?.data.adminPermissionedit)
      setFieldValue("countryView", permissionGetvalueData?.data.countryView)
      setFieldValue("countryEdit", permissionGetvalueData?.data.countryEdit)
      setFieldValue("stateView", permissionGetvalueData?.data.stateView)
      setFieldValue("stateEdit", permissionGetvalueData?.data.stateEdit)
      setFieldValue("cityView", permissionGetvalueData?.data.cityView)
      setFieldValue("cityEdit", permissionGetvalueData?.data.cityEdit)
      setFieldValue("dashboardView", permissionGetvalueData?.data.dashboardView)
      setFieldValue("dashboardEdit", permissionGetvalueData?.data.dashboardEdit)
      setFieldValue("usersView", permissionGetvalueData?.data.usersView)
      setFieldValue("usersEdit", permissionGetvalueData?.data.usersEdit)
      setFieldValue("orderView", permissionGetvalueData?.data.orderView)
      setFieldValue("orderEdit", permissionGetvalueData?.data.orderEdit)
      setFieldValue("billingsView", permissionGetvalueData?.data.billingsView)
      setFieldValue("billingsEdit", permissionGetvalueData?.data.billingsEdit)
      setFieldValue("restaurantView", permissionGetvalueData?.data.restaurantView)
      setFieldValue("restaurantEdit", permissionGetvalueData?.data.restaurantEdit)
      setFieldValue("magicbagSizeview", permissionGetvalueData?.data.magicbagSizeview)
      setFieldValue("magicbagSizeedit", permissionGetvalueData?.data.magicbagSizeedit)
      setFieldValue("cuisineView", permissionGetvalueData?.data.cuisineView)
      setFieldValue("cuisneEdit", permissionGetvalueData?.data.cuisneEdit)
      setFieldValue("bankView", permissionGetvalueData?.data.bankView)
      setFieldValue("bankEdit", permissionGetvalueData?.data.bankEdit)
      setFieldValue("faqView", permissionGetvalueData?.data.faqView)
      setFieldValue("faqEdit", permissionGetvalueData?.data.faqEdit)
      setFieldValue("supportView", permissionGetvalueData?.data.supportView)
      setFieldValue("supportEdit", permissionGetvalueData?.data.supportEdit)
      setFieldValue("privacyPolicyView", permissionGetvalueData?.data.privacyPolicyView)
      setFieldValue("privacyPolicyEdit", permissionGetvalueData?.data.privacyPolicyEdit)
      setFieldValue("termsandconditionView", permissionGetvalueData?.data.termsandconditionView)
      setFieldValue("termsandconditionEdit", permissionGetvalueData?.data.termsandconditionEdit)
      setFieldValue("settingsView", permissionGetvalueData?.data.settingsView)
      setFieldValue("settingsEdit", permissionGetvalueData?.data.settingsEdit)

    } else {
      ToastMessage("error", permissionGetvalueData?.message);
    }
  }, [permissionGetvalueData]);


  const handlePermissionChangenew = (value, key, type) => {
    setFieldValue(key, value == false ? true : false)
  }

  return (
    <form style={{ padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        Permission
      </Typography>

      <FormControl
        fullWidth
        style={{ marginBottom: "20px", width: "280px", background: "#e8f0fe" }}
      >
        <InputLabel>Role</InputLabel>
        <Select
          label="Role"
          value={role}
          onChange={handleRoleChange}
        >
          {roledropdownval?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.user_role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>




      {role ?

        <Permissionui {...{ values, handlePermissionChangenew }} />
        // <Grid container spacing={3}>




        //   <Grid item xs={12}>
        //     <Typography variant="h6" gutterBottom>
        //       Vendors
        //     </Typography>
        //     <Grid
        //       container
        //       spacing={2}
        //       style={{
        //         display: "flex",
        //         flexWrap: "wrap",
        //       }}
        //     >
        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Live Vendors</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.liveVendorsview}
        //                 onChange={() => handlePermissionChangenew(values.liveVendorsview, "liveVendorsview", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.liveVendorsedit}
        //                 onChange={(e) => handlePermissionChangenew(values.liveVendorsedit, "liveVendorsedit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>


        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Vendor Accounts</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.vendorsAccountsview}
        //                 onChange={(e) => handlePermissionChangenew(values.vendorsAccountsview, "vendorsAccountsview", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.vendorsAccountsedit}
        //                 onChange={(e) => handlePermissionChangenew(values.vendorsAccountsedit, "vendorsAccountsedit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>


        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Vendor Documents</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.vendorsDocumentview}
        //                 onChange={(e) => handlePermissionChangenew(values.vendorsDocumentview, "vendorsDocumentview", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.vendorsDocumentedit}
        //                 onChange={(e) => handlePermissionChangenew(values.vendorsDocumentedit, "vendorsDocumentedit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>
        //     </Grid>
        //   </Grid>

        //   <Grid item xs={12}>
        //     <Typography variant="h6" gutterBottom>
        //       Admin User Management
        //     </Typography>
        //     <Grid
        //       container
        //       spacing={2}
        //       style={{
        //         display: "flex",
        //         flexWrap: "wrap",
        //       }}
        //     >
        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Admin Users</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminUserview}
        //                 onChange={() => handlePermissionChangenew(values.adminUserview, "adminUserview", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminUseredit}
        //                 onChange={(e) => handlePermissionChangenew(values.adminUseredit, "adminUseredit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>


        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Role</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminRoleview}
        //                 onChange={(e) => handlePermissionChangenew(values.adminRoleview, "adminRoleview", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminRoleedit}
        //                 onChange={(e) => handlePermissionChangenew(values.adminRoleedit, "adminRoleedit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>

        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Permissions</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminPermissionView}
        //                 onChange={(e) => handlePermissionChangenew(values.adminPermissionView, "adminPermissionView", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.adminPermissionedit}
        //                 onChange={(e) => handlePermissionChangenew(values.adminPermissionedit, "adminPermissionedit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>

        //     </Grid>
        //   </Grid>

        //   <Grid item xs={12}>
        //     <Typography variant="h6" gutterBottom>
        //       Location
        //     </Typography>
        //     <Grid
        //       container
        //       spacing={2}
        //       style={{
        //         display: "flex",
        //         flexWrap: "wrap",
        //       }}
        //     >
        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">Country</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.countryView}
        //                 onChange={() => handlePermissionChangenew(values.countryView, "countryView", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.countryEdit}
        //                 onChange={(e) => handlePermissionChangenew(values.countryEdit, "countryEdit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>


        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">State</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.stateView}
        //                 onChange={(e) => handlePermissionChangenew(values.stateView, "stateView", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.stateEdit}
        //                 onChange={(e) => handlePermissionChangenew(values.stateEdit, "stateEdit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>

        //       <Grid
        //         item
        //         xs={12}
        //         sm={6}
        //         md={4}
        //         style={{
        //           display: "flex",
        //           flexDirection: "column",
        //           gap: "5px",
        //         }}
        //       >
        //         <Typography variant="subtitle1">City</Typography>
        //         <div className="view-edit-permission">
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.cityView}
        //                 onChange={(e) => handlePermissionChangenew(values.cityView, "cityView", "view")}
        //               />
        //             }
        //             label="View"
        //           />
        //           <FormControlLabel
        //             control={
        //               <Checkbox
        //                 checked={values.cityEdit}
        //                 onChange={(e) => handlePermissionChangenew(values.cityEdit, "cityEdit", "edit")}
        //               />
        //             }
        //             label="Add/Edit"
        //           />
        //         </div>
        //       </Grid>

        //     </Grid>
        //   </Grid>



        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Dashboard</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.dashboardView}
        //             onChange={(e) => handlePermissionChangenew(values.dashboardView, "dashboardView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.dashboardEdit}
        //             onChange={(e) => handlePermissionChangenew(values.dashboardEdit, "dashboardEdit", "edit")}
        //           />
        //         }
        //         label="Add/Edit"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Users</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.usersView}
        //             onChange={(e) => handlePermissionChangenew(values.usersView, "usersView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.usersEdit}
        //             onChange={(e) => handlePermissionChangenew(values.usersEdit, "usersEdit", "edit")}
        //           />
        //         }
        //         label="Add/Edit"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Orders</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.orderView}
        //             onChange={(e) => handlePermissionChangenew(values.orderView, "orderView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.orderEdit}
        //             onChange={(e) => handlePermissionChangenew(values.orderEdit, "orderEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Billings</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.billingsView}
        //             onChange={(e) => handlePermissionChangenew(values.billingsView, "billingsView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.billingsEdit}
        //             onChange={(e) => handlePermissionChangenew(values.billingsEdit, "billingsEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Restaurant Type</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.restaurantView}
        //             onChange={(e) => handlePermissionChangenew(values.restaurantView, "restaurantView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.restaurantEdit}
        //             onChange={(e) => handlePermissionChangenew(values.restaurantEdit, "restaurantEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Magic Bag Size</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.magicbagSizeview}
        //             onChange={(e) => handlePermissionChangenew(values.magicbagSizeview, "magicbagSizeview", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.magicbagSizeedit}
        //             onChange={(e) => handlePermissionChangenew(values.magicbagSizeedit, "magicbagSizeedit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Cuisine</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.cuisineView}
        //             onChange={(e) => handlePermissionChangenew(values.cuisineView, "cuisineView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.cuisneEdit}
        //             onChange={(e) => handlePermissionChangenew(values.cuisneEdit, "cuisneEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Bank</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.bankView}
        //             onChange={(e) => handlePermissionChangenew(values.bankView, "bankView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.bankEdit}
        //             onChange={(e) => handlePermissionChangenew(values.bankEdit, "bankEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">FAQ</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.faqView}
        //             onChange={(e) => handlePermissionChangenew(values.faqView, "faqView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.faqEdit}
        //             onChange={(e) => handlePermissionChangenew(values.faqEdit, "faqEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Support</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.supportView}
        //             onChange={(e) => handlePermissionChangenew(values.supportView, "supportView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.supportEdit}
        //             onChange={(e) => handlePermissionChangenew(values.supportEdit, "supportEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>


        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Privacy Policy</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.privacyPolicyView}
        //             onChange={(e) => handlePermissionChangenew(values.privacyPolicyView, "privacyPolicyView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.privacyPolicyEdit}
        //             onChange={(e) => handlePermissionChangenew(values.privacyPolicyEdit, "privacyPolicyEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Terms and Conditions</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.termsandconditionView}
        //             onChange={(e) => handlePermissionChangenew(values.termsandconditionView, "termsandconditionView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.termsandconditionEdit}
        //             onChange={(e) => handlePermissionChangenew(values.termsandconditionEdit, "termsandconditionEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>

        //   <Grid
        //     item
        //     xs={12}
        //     sm={6}
        //     md={4}
        //     style={{
        //       display: "flex",
        //       flexDirection: "column",
        //       gap: "5px",
        //     }}
        //   >
        //     <Typography variant="h6">Settings</Typography>
        //     <div className="view-edit-permission">
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.settingsView}
        //             onChange={(e) => handlePermissionChangenew(values.settingsView, "settingsView", "view")}
        //           />
        //         }
        //         label="View"
        //       />
        //       <FormControlLabel
        //         control={
        //           <Checkbox
        //             checked={values.settingsEdit}
        //             onChange={(e) => handlePermissionChangenew(values.settingsEdit, "settingsEdit", "edit")}
        //           />
        //         }
        //         label="Downloads"
        //       />
        //     </div>
        //   </Grid>







        // </Grid>


        : ""}



      {parsedDatapermission?.adminPermissionedit == 1 ?
        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">
          <CButton

            disabled={!role}
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
            }} type="button" onClick={handlepermissionUpdate} className="me-md-2">
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) :

              <>Submit</>
            }

          </CButton>
        </div>
        : ""}




    </form >
  );


};

export default Permission;
