import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  useColorModes,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilContrast,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilMoon,
  cilSun,
} from "@coreui/icons";

import { AppBreadcrumb } from "./index";
import ToastMessage from "../utils/ToastMessage";
import { notificationcountAction, notificationreadAction } from "../redux/actions";
import { get_Notification_api, get_Notification_unread_COUNT } from "../API/baseUrl";
import { Badge, Drawer } from "@mui/material";
import Notification from "./Notification";

const AppHeader = ({ sidebarShow, setSidebarShow }) => {
  const headerRef = useRef();
  const { colorMode, setColorMode } = useColorModes("#ffffff");

  const dispatch = useDispatch();
  // const sidebarShow = useSelector((state) => state.changeState)

  const { notificationData } = useSelector(
    (state) => state?.notificationReduser || {}
  );

  const { notificationcountData } = useSelector(
    (state) => state?.notificationcountReduser || {}
  );
  console.log("notificationcountData",notificationcountData)
  const [notification, setNotification] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [countval, setCountval] = useState("");
  const [pending, setPending] = useState(true);
  const [prevNotificationCount, setPrevNotificationCount] = useState(0);
  // const audioRef = useRef(new Audio("src/images/notification.mp3")); // path to your sound file
  const audioRef = useRef(new Audio("../../images/notification.mp3"));
  const userIdFk = localStorage.getItem("userIdFk");
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };




  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0
        );
    });
  }, []);

  const handleToggel = () => {
    setSidebarShow(!sidebarShow);
  };

  // useEffect(() => {
  //   setPending(true);
  //   const parameter = {
  //     type: 0,
  //     offset: 0,
  //     userType: "admin",
  //     limit: 0,
  //     search: " ",
  //     columnName: "",
  //     sortBy: "ACS",
  //     url: `${get_Notification_api}${userIdFk}`,
  //   };
  //   dispatch(notificationreadAction(parameter));
  // }, []);



  useEffect(() => {
    const fetchUnreadCount = () => {
      const parameter = {
        url: `${get_Notification_unread_COUNT}${userIdFk}`,
      };
      dispatch(notificationcountAction(parameter));
    };
    fetchUnreadCount();
    const intervalId = setInterval(fetchUnreadCount, 2000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (notificationcountData) {
      if (notificationcountData.status) {
        setCountval(notificationcountData?.Count);
      } else {
        ToastMessage("error", notificationcountData.message);
      }
    }
  }, [notificationcountData]);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        const newNotificationCount = notificationData.data?.length || 0;
        if (newNotificationCount > prevNotificationCount) {
          // Play sound only when notification count increases
          audioRef.current
            .play()
            .catch((error) => console.log("Audio play error:", error));
        }
        setPrevNotificationCount(newNotificationCount); // Update previous notification count
        setNotification(notificationData.data || []);
      } else {
        ToastMessage("error", notificationData.message);
      }
      setPending(false);
    }
  }, [notificationData]); // Trigger effect when orderListData changes



  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler
          // onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          onClick={handleToggel}
          style={{ marginInlineStart: "-14px" }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="d-none d-md-flex">
          <CNavItem></CNavItem>
        </CHeaderNav>


        <CHeaderNav className="ms-auto">
          <CNavItem >
            <CNavLink
              onClick={toggleDrawer(true)}>
              {!countval || countval == 0 ? (
                <CIcon icon={cilBell} size="lg" style={{cursor:"pointer"}} />
              ) : (
                <Badge badgeContent={countval} color="success" style={{cursor:"pointer"}}>
                  <CIcon icon={cilBell} size="lg" style={{cursor:"pointer"}} />
                </Badge>
              )}
            </CNavLink>
          </CNavItem>

        </CHeaderNav>


        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Notification onClose={toggleDrawer(false)} />
        </Drawer>

      </CContainer>
      <CContainer className="px-4" fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
