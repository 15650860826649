/**
 * Login Action
 */

import * as type from "../type";

/**
 *
 * @param {*} data
 * @returns
 */
export const loginUser = (data) => {
  return {
    type: type.LOGIN_USER_REQUEST,
    payload: { data },
  };
};

export const sendemail = (data) => {
  return {
    type: type.SEND_EMAIL_REQUEST,
    payload: { data },
  };
};

export const countryList = (data) => {
  return {
    type: type.COUNTRY_LIST_REQUEST,
    payload: { data },
  };
};

export const stateList = (data) => {
  return {
    type: type.STATE_LIST_REQUEST,
    payload: { data },
  };
};

export const cityList = (data) => {
  return {
    type: type.CITY_LIST_REQUEST,
    payload: { data },
  };
};
export const vendorList = (data) => {

  return {
    type: type.VENDOR_LIST_REQUEST,
    payload: { data },
  };
};

export const countryStatusRequest = (data) => {
  return {
    type: type.COUNTRY_STATUS_REQUEST,
    payload: { data },
  };
};

export const stateStatusRequest = (data) => {
  return {
    type: type.STATE_STATUS_REQUEST,
    payload: { data },
  };
};

export const cityStatusRequest = (data) => {
  return {
    type: type.CITY_STATUS_REQUEST,
    payload: { data },
  };
};

export const specialityStatusRequest = (data) => {
  return {
    type: type.SPECIALITY_STATUS_REQUEST,
    payload: { data },
  };
};

export const addcountryRequest = (data) => {
  return {
    type: type.ADD_COUNTRY_REQUEST,
    payload: { data },
  };
};

export const addstateRequest = (data) => {
  return {
    type: type.ADD_STATE_REQUEST,
    payload: { data },
  };
};

export const addcityRequest = (data) => {
  return {
    type: type.ADD_CITY_REQUEST,
    payload: { data },
  };
};

export const addspecialityRequest = (data) => {
  return {
    type: type.ADD_SPECIALITY_REQUEST,
    payload: { data },
  };
};

export const activecountrylistRequest = (data) => {
  return {
    type: type.ACTIVE_COUNTRYLIST_REQUEST,
    payload: { data },
  };
};

export const activestatelistRequest = (data) => {
  return {
    type: type.ACTIVE_STATELIST_REQUEST,
    payload: { data },
  };
};

export const specialitylistRequest = (data) => {
  return {
    type: type.SPECIALITY_LIST_REQUEST,
    payload: { data },
  };
};

export const restaurantViewRequest = (data) => {
  return {
    type: type.RESTAURANT_VIEW_REQUEST,
    payload: { data },
  };
};


export const restauranttypeListRequest = (data) => {
  return {
    type: type.RESTAURANT_TYPELIST_REQUEST,
    payload: { data },
  };
};


export const getcountrybyidRequest = (data) => {
  return {
    type: type.COUNTRY_DETAILS_REQUEST,
    payload: { data },
  };
};

export const getstatebyidRequest = (data) => {
  return {
    type: type.STATE_DETAILS_REQUEST,
    payload: { data },
  };
};

export const getcitybyidRequest = (data) => {
  return {
    type: type.CITY_DETAILS_REQUEST,
    payload: { data },
  };
};


export const editbyidRequest = (data) => {
  return {
    type: type.CITY_EDIT_REQUEST,
    payload: { data },
  };
};

export const editstatebyidRequest = (data) => {
  return {
    type: type.STATE_EDIT_REQUEST,
    payload: { data },
  };
};

export const editcountrybyidRequest = (data) => {

  return {
    type: type.COUNTRY_EDIT_REQUEST,
    payload: { data },
  };
};

export const accountStatusRequest = (data) => {
  return {
    type: type.ACCOUNT_STATUS_REQUEST,
    payload: { data },
  };
};

export const documentListRequest = (data) => {
  return {
    type: type.DOCUMENT_LIST_REQUEST,
    payload: { data },
  };
};


export const documentViewRequest = (data) => {
  return {
    type: type.DOCUMENT_VIEWLIST_REQUEST,
    payload: { data },
  };
};



export const bagSizelist = (data) => {
  return {
    type: type.BAGSIZE_LIST_REQUEST,
    payload: { data },
  };
};


export const bagSizeadd = (data) => {
  return {
    type: type.BAGSIZE_ADD_REQUEST,
    payload: { data },
  };
};



export const typelist = (data) => {
  return {
    type: type.TYPE_LIST_REQUEST,
    payload: { data },
  };
};


export const typeactivelist = (data) => {
  return {
    type: type.ACTIVETYPE_LIST_REQUEST,
    payload: { data },
  };
};


export const typeadd = (data) => {
  return {
    type: type.TYPE_ADD_REQUEST,
    payload: { data },
  };
};

export const typestatus = (data) => {
  return {
    type: type.TYPE_STATUS_REQUEST,
    payload: { data },
  };
};


export const bagsizeStatus = (data) => {
  return {
    type: type.BAGSIZE_STATUS_REQUEST,
    payload: { data },
  };
};


export const bagsizeDetails = (data) => {
  return {
    type: type.BAGSIZE_DETAILS_REQUEST,
    payload: { data },
  };
};


export const bagsizeUpdate = (data) => {
  return {
    type: type.BAGSIZE_UPDATE_REQUEST,
    payload: { data },
  };
};


export const editspecialitybyidRequest = (data) => {
  return {
    type: type.SPECIALITY_UPDATE_REQUEST,
    payload: { data },
  };
};

export const specialityDetailsRequest = (data) => {
  
  return {
    type: type.SPECIALITY_DETAILS_REQUEST,
    payload: { data },
  };
};


export const editTypeRequest = (data) => {
  return {
    type: type.TYPE_UPDATE_REQUEST,
    payload: { data },
  };
};

export const faqadd = (data) => {
  return {
    type: type.ADD_FAQ_REQUEST,
    payload: { data },
  };
};

export const typeDetailsRequest = (data) => {
  
  return {
    type: type.TYPE_DETAILS_REQUEST,
    payload: { data },
  };
};

export const OrderListRequest = (data) => {
  return {
    type: type.ORDER_LIST_REQUEST,
    payload: { data },
  };
};

export const faqTypeRequest = (data) => {
  return {
    type: type.FAQ_TYPE_REQUEST,
    payload: { data },
  };
};

export const faqListRequest = (data) => {
  return {
    type: type.FAQ_LIST_REQUEST,
    payload: { data },
  };
};
export const createOrderListRequest=(data)=>{
  return{
    type:type.CREATE_ORDER_LIST_REQUEST,
    payload:{ data },
  }
}



export const supportTicketListRequest = (data) => {
  return {
    type: type.SUPPORTTICKET_LIST_REQUEST,
    payload: { data },
  };
};

export const getTicketStutus = (data) => {
  return {
    type: type.GETSUPPORT_STATUS_REQUEST,
    payload: { data },
  };
};


export const settingAction = (data) => {
  return {
    type: type.CREATE_SETTING_REQUEST,
    payload: { data },
  };
};

export const getsettingAction = (data) => {
  return {
    type: type.GET_SETTING_REQUEST,
    payload: { data },
  };
};

export const supportStatusedit = (data) => {
  
  return {
    type: type.SUPPORT_STATUSEDIT_REQUEST,
    payload: { data },
  };
};

export const supportChatSendAction = (data) => {
  
  return {
    type: type.CHAT_SEND_REQUEST,
    payload: { data },
  };
};

export const supportChatgETAction = (data) => {
  
  return {
    type: type.CHAT_GET_REQUEST,
    payload: { data },
  };
};



export const  ExcleDownloadOrder  = (data) => {

  return {
    type: type.Excle_Download_REQUEST ,
    payload: {data},
  };
};

export const  DashboardAction  = (data) => {
  return {
    type: type.DASHBOARD_DATA_REQUEST ,
    payload: {data},
  };
};

export const UserList = (data) => {

  return {
    type: type.User_LIST_REQUEST,
    payload: { data },
  };
};

export const userViewRequest = (data) => {
  return {
    type: type.User_VIEW_REQUEST,
    payload: { data },
  };
};
export const commissionRequest = (data) => {
  return {
    type: type.DASHBOARD_COMMISSION_DATA_REQUEST,
    payload: { data },
  };
};




export const notificationreadAction = data => {
  return {
    type: type.NOTIFICATION_READ_REQUEST,
    payload: {data},
  };
};

export const notificationcountAction = data => {
  return {
    type: type.NOTIFICATION_COUNT_REQUEST,
    payload: {data},
  };
};




export const notificationaction = data => {
  return {
    type: type.NOTIFICATION_DATA_REQUEST,
    payload: {data},
  };
};



export const roledropdownaction = data => {
  return {
    type: type.ROLE_DROPDOWN_REQUEST,
    payload: {data},
  };
};


export const permissionUpdateaction = data => {
  return {
    type: type.PERMISSION_UPDATE_REQUEST,
    payload: {data},
  };
};

export const permissiongetaction = data => {
  return {
    type: type.PERMISSION_GET_REQUEST,
    payload: {data},
  };
};


export const permissiongetactionforvalue = data => {
  return {
    type: type.PERMISSION_GETVALUE_REQUEST,
    payload: {data},
  };
};


export const updateadminUser = data => {
  return {
    type: type.UPDATE_ADMIN_REQUEST,
    payload: {data},
  };
};

export const EmailOtp = data => {
  return {
    type: type.Eamil_Otp_REQUEST,
    payload: {data},
  };
};



export const uploaddocuments = data => {

  return {
    type: type.UPLOAD_DOCUMENT_REQUEST,
    payload: {data},
  };
};

export const changepasswordaction = data => {

  return {
    type: type.CHANGE_PASSWORD_REQUEST,
    payload: {data},
  };
};

export const getBanklist = data => {
  return {
    type: type.BANK_LIST_REQUEST,
    payload: {data},
  };
};

export const documentViewRequestdoc = data => {
  console.log("dataddddddd",data)
  return {
    type: type.DOCUMENTS_DETAILS_REQUEST,
    payload: {data},
  };
};




export const emptytokenaction = data => {
  console.log("dataddddddd",data)
  return {
    type: type.EMPTY_DEVICETOKEN_REQUEST,
    payload: {data},
  };
};
export const resturantListaction = data => {
  console.log("dataddddddd",data)
  return {
    type: type.RESTURANT_LIST_REQUEST,
    payload: {data},
  };
};




export const removeDataFromReducer = (data) => ({
  type: data,
});
