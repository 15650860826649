import { CircularProgress, Grid, Skeleton, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import ToastMessage from "../utils/ToastMessage";
import LoaderAnimation from "../logo-2/loader/loaderanimation";
import { notificationaction, notificationreadAction } from "../redux/actions";
import { get_Notification_api, get_Notification_api_admin, get_Notification_read, get_Notification_unread, read_message_api } from "../API/baseUrl";

const Notification = ({ onClose }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [unreadNotification, setUnreadNotification] = useState([]);
  const [readNotification, setReadNotification] = useState([]);
  const { notificationData } = useSelector(
    (state) => state?.notificationReduser || {}
  );
  const { notificationreadData } = useSelector(
    (state) => state?.notificationreadReduser || {}
  );

  console.log("notificationreadData", notificationreadData)
  const dispatch = useDispatch();
  const [notification_id, setNotification_id] = useState("");
  const userIdFk = localStorage.getItem("userIdFk");
  const [notificationType, setNotificationType] = useState(0);

  const buttonStyle = (type) => ({
    backgroundColor: notificationType === type ? `var(--colorused)` : "white",
    color: notificationType == type ? "white" : `var(--colorused)`,
    backgroundColor: notificationType === type ? `var(--colorused)` : "white",
    border: "none",
    padding: "10px 20px",
    margin: "0 5px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%",
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // const markAsunread = () => {
  //   const parameter = {
  //     url: `${get_Notification_unread}${userIdFk}`,
  //   };
  //   dispatch(notificationreadAction(parameter));
  // };



  const markAsread = (id) => {
    const parameter = {
      url: `${get_Notification_read}${id}`,
    };
    dispatch(notificationreadAction(parameter));
  };

  const handleCardClick = async (id, isRead, link) => {
    setNotification_id(id);
    setSelectedNotification(notification);
    if (Array.isArray(notification)) {
      const updatedNotifications = notification.map((item) =>
        item.notification_id === id ? { ...item, isRead: 1 } : item
      );
      setNotification(updatedNotifications);
      setReadNotification(
        updatedNotifications.filter((item) => item.isRead === 1)
      );
      setUnreadNotification(
        updatedNotifications.filter((item) => item.isRead === 0)
      );
      markAsread(id);
    } else {
      console.error("Notification data is not an array:", notification);
    }

    if (link) {
      window.location.href = link;
    }
  };


  // useEffect(()=>{
  //   markAsRead()
  // })



  // useEffect(() => {
  const listapi = () => {
    setLoading(true);
    const parameter = {
      type: notificationType,
      offset: 0,
      userType: "admin",
      limit: 0,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: `${get_Notification_api_admin}${userIdFk}`,
    };
    dispatch(notificationaction(parameter));
  }
  // }, [searchText, sortByColumnName, sortBy, notificationType, userIdFk]);
  useEffect(() => {
    listapi();
  }, [searchText, sortByColumnName, sortBy, notificationType, userIdFk]);  // Runs when any of these dependencies change

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        const allNotifications = notificationData.data || [];
        setNotification(allNotifications);
        setUnreadNotification(
          allNotifications.filter((item) => item.isRead === 0)
        );
        setReadNotification(
          allNotifications.filter((item) => item.isRead === 1)
        );
        setTotalRows(allNotifications.length);
      } else {
        // ToastMessage("error", notificationData.message);
      }
      setLoading(false);
    }
  }, [notificationData]);

  useEffect(() => {
    // alert("ok")
    if (notificationreadData) {
      if (notificationreadData.status) {
        setSearchText("");
        listapi();
        // ToastMessage("error", notificationreadData.message);
      } else {
        // ToastMessage("error", notificationreadData.message);
      }
      setLoading(false);
    }
  }, [notificationreadData]);

  // Get paginated notifications

  const paginatedNotifications = (
    notificationType === 0 ? unreadNotification : readNotification
  ).slice((page - 1) * perPage, page * perPage);

  function formatTimestamp(timestamp) {
    return moment(timestamp, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
      "MMMM Do YYYY, h:mm:ss a"
    );
  }

  return (
    // <div
    //   style={{
    //     backgroundColor: "gary",
    //     height: "100vh",
    //     overflow: "hidden",
    //   }}
    // >
    //   <div style={{ width: "500px", padding: "20px", color: "black" }}>
    //     <h3>Notifications</h3>
    //     <button
    //       onClick={onClose}
    //       style={{
    //         position: "absolute",
    //         top: "10px",
    //         right: "10px",
    //         minWidth: "auto",
    //         padding: "10px",
    //         border: "none",
    //         background: "none",
    //       }}
    //     >
    //       <CloseIcon />
    //     </button>
    //     {/* <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
    //       <Grid item xs={12} sm={6} md={6} lg={12} style={{ display: "flex" }}>
    //         <button
    //           style={buttonStyle(0)}
    //           onClick={() => setNotificationType(0)}

    //         >
    //           Unread
    //         </button>
    //         <button
    //           style={buttonStyle(1)}
    //           onClick={() => setNotificationType(1)}
    //         >
    //           Read
    //         </button>
    //       </Grid>
    //     </Grid> */}
    //     <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
    //       <Grid
    //         item
    //         // xs={4}
    //         // sm={6}
    //         md={6}
    //         lg={6}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <button
    //           style={buttonStyle(0)}
    //           onClick={() => setNotificationType(0)}
    //         >
    //           Unread
    //         </button>
    //       </Grid>
    //       <Grid
    //         item
    //         // xs={4}
    //         // sm={6}
    //         md={6}
    //         lg={6}
    //         style={{ display: "flex", justifyContent: "space-around" }}
    //       >
    //         <button
    //           style={buttonStyle(1)}
    //           onClick={() => setNotificationType(1)}
    //         >
    //           Read
    //         </button>
    //       </Grid>
    //     </Grid>

    //     <div>
    //       {loading ? (


    //         <LoaderAnimation/>
    //       ) : (
    //         <Stack
    //           spacing={2}
    //           style={{
    //             height: "80vh",
    //             overflowY:
    //               paginatedNotifications.length > 0 ? "scroll" : "hidden",
    //           }}
    //         >
    //           {paginatedNotifications.length > 0 ? (
    //             paginatedNotifications.map((notification) => (
    //               <details
    //                 key={notification.notification_id}
    //                 style={{ padding: "0px" }}
    //               >
    //                 <summary
    //                   style={{ listStyleType: "none", cursor: "pointer" }}
    //                 >
    //                  <Card
    //                     style={{
    //                       width: "29rem",
    //                       backgroundColor:
    //                         notificationType === 0
    //                           ? "rgb(229, 235, 229)"
    //                           : "white",
    //                     }}
    //                     onClick={() =>
    //                       handleCardClick(
    //                         notification.notification_id,
    //                         notification.isRead,
    //                         notification.redirectLink
    //                       )
    //                     }
    //                   >
    //                     <Card.Body>
    //                       <Grid
    //                         container
    //                         rowGap={2}
    //                         style={{
    //                           display: "flex",
    //                           justifyContent: "space-between",
    //                         }}
    //                       >
    //                         <Grid item xs={12} md={4} lg={7}>
    //                           <h5
    //                             style={{
    //                               fontSize: "18px",
    //                               fontWeight: "bold",
    //                               margin: "0px",
    //                             }}
    //                           >
    //                             {notification.notification_name}
    //                           </h5>
    //                         </Grid>
    //                         <Grid
    //                           item
    //                           xs={12}
    //                           md={12}
    //                           lg={12}
    //                           style={{ fontSize: "13px" }}
    //                         >
    //                           <small>
    //                             {formatTimestamp(notification.created_at)}
    //                           </small>
    //                         </Grid>
    //                       </Grid>
    //                       <Card.Text>
    //                         <p style={{ fontSize: "15px", margin: "0px" }}>
    //                           {notification.notification_message}
    //                         </p>
    //                       </Card.Text>
    //                     </Card.Body>
    //                   </Card> 
    //                 </summary>
    //               </details>
    //             ))
    //           ) : (
    //             <p style={{ textAlign: "center" }} className="mt-4">
    //               No {notificationType === 0 ? "Unread" : "Read"} Notification
    //             </p>
    //           )}
    //           {paginatedNotifications.length > 0 ? (
    //             <Pagination
    //               count={Math.ceil(totalRows / perPage)}
    //               page={page}
    //               onChange={handlePageChange}
    //               // color="primary"
    //               sx={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 marginTop: "20px",
    //               }}
    //             />
    //           ) : (
    //             ""
    //           )}
    //         </Stack>
    //       )}
    //     </div>
    //   </div>
    // </div>


    <div
      style={{
        backgroundColor: "gary",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{ width: "500px", padding: "20px", color: "black" , maxWidth:"90vw"}}>
        <h3>Notifications</h3>
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            minWidth: "auto",
            padding: "10px",
            border: "none",
            background: "none",
          }}
        >
          <CloseIcon />
        </button>
        {/* <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={12} style={{ display: "flex" }}>
            <button
              style={buttonStyle(0)}
              onClick={() => setNotificationType(0)}

            >
              Unread
            </button>
            <button
              style={buttonStyle(1)}
              onClick={() => setNotificationType(1)}
            >
              Read
            </button>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
          <Grid
            item
            // xs={4}
            // sm={6}
            md={6}
            lg={6}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              style={buttonStyle(0)}
              onClick={() => setNotificationType(0)}
            >
              Unread
            </button>
          </Grid>
          <Grid
            item
            // xs={4}
            // sm={6}
            md={6}
            lg={6}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              style={buttonStyle(1)}
              onClick={() => setNotificationType(1)}
            >
              Read
            </button>
          </Grid>
        </Grid>

        <div>
          {loading ? (
            // <div className="loaders">
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            // </div>

            <LoaderAnimation />
          ) : (
            <Stack
              spacing={2}
              style={{
                height: "80vh",
                overflowY:
                  paginatedNotifications.length > 0 ? "scroll" : "hidden",
              }}
            >
              {paginatedNotifications.length > 0 ? (
                paginatedNotifications.map((notification) => (
                  <details
                    key={notification.notification_id}
                    style={{ padding: "10px" }}
                  >
                    <summary
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                      <Card
                        style={{
                          width: "26rem",
                          maxWidth:"100%",
                          backgroundColor:
                            notificationType === 0
                              ? "rgb(229, 235, 229)"
                              : "white",
                        }}
                        onClick={() =>
                          handleCardClick(
                            notification.notification_id,
                            notification.isRead,
                            notification.redirectLink
                          )
                        }
                      >
                        <Card.Body>
                          <Grid
                            container
                            rowGap={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xs={12} md={4} lg={7} sx={{marginBottom:"10px"}}>
                              <h5
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  margin: "0px",
                                  whiteSpace:"nowrap"
                                }}
                              >
                                {notification.notification_name}
                              </h5>
                              <small style={{
                                  whiteSpace:"nowrap"
                                }}>
                                {formatTimestamp(notification.created_at)}
                              </small>
                            </Grid>
                           
                          </Grid>
                          <Card.Text>
                            <p style={{ fontSize: "15px", margin: "0px" }}>
                              {notification.notification_message}
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </summary>
                  </details>
                ))
              ) : (
                <p style={{ textAlign: "center" }} className="mt-4">
                  No {notificationType === 0 ? "Unread" : "Read"} Notification
                </p>
              )}
              {paginatedNotifications.length > 0 ? (
                <Pagination
                  count={Math.ceil(totalRows / perPage)}
                  page={page}
                  onChange={handlePageChange}
                  // color="primary"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
          )}
        </div>
      </div>
    </div>

  );
};

export default Notification;
