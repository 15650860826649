import {combineReducers} from 'redux';
import {
  accountStatusReduser,
  activecountrylistreduser,
  activestatelistreduser,
  bagsizeAddReduser,
  typeAddReduser,
  bagSizeListReduser,
  typeListReduser,
  cityaddreduser,
  cityList,
  citystatus,
  countryaddreduser,
  countryList,
  countrystatus,
  documentListReduser,
  documentviewListReduser,
  EditCityReduser,
  EditcountryReduser,
  EditStateReduser,
  getCityReduser,
  getCountryyReduser,
  getStateReduser,
  loginUser,
  restauranttypeListreduser,
  restaurantViewreduser,
  sendemail,
  specialityaddreduser,
  specialitylistreduser,
  specialitystatusreduser,
  stateaddreduser,
  stateList,
  statestatus,
  vendorList,
  typestatusReduser,
  bagsizeStatusReduser,
  SpecialityUpdateReduser,
  SpecialitydetailsReduser,
  bagsizedetailsReduser,
  bagsizeupdateReduser,
  typedetailsReduser,
  typeupdateReduser,
  orderListReduser,
  faqAddReduser,
  faqtypelistReduser,
  faqlistReduser,
  createorderlistReduser,
  supportListReduser,
  getsupportStatusReduser,
  settingReduser,
  getsettingReduser,
  supportStatusReduser,
  chatSendReduser,
  chatGetReduser,
  ExcleDownloadOrder,
  DashboardReducer,
  UserViewreduser,
  Commissionreduser,
  activetypeListReduser,
  notificationreadReduser,
  notificationcountReduser,
  notificationReduser,
  roledropdownReduser,
  permissionUpdateReduser,
  permissionGetReduser,
  permissionGetvalueReduser,
  updateAdminReduser,
  EmailOtpReduser,
  uploadimagereduser,
  bankListreduser,
  getDocumentReduser,
  Changepasswordreduser,
  emptytokenreduser,
  resturantlistreduser
} from './Reducerfile';
import {LOGOUT} from '../type';
import { changeState } from './CoreReduser';
import { UserList } from '../actions';

const rootReducerr = combineReducers({
  loginUser: loginUser,
  emptytokenreduser: emptytokenreduser,
  faqlistReduser: faqlistReduser,
  activetypeListReduser: activetypeListReduser,
  createorderlistReduser:createorderlistReduser,
  faqtypelistReduser: faqtypelistReduser,
  faqAddReduser: faqAddReduser,
  orderListReduser: orderListReduser,
  sendemail: sendemail,
  countryList: countryList,
  stateList: stateList,
  cityList: cityList,
  countrystatus: countrystatus,
  statestatus: statestatus,
  citystatus: citystatus,
  countryaddreduser: countryaddreduser,
  stateaddreduser: stateaddreduser,
  cityaddreduser: cityaddreduser,
  activecountrylistreduser: activecountrylistreduser,
  specialitylistreduser: specialitylistreduser,
  specialityaddreduser: specialityaddreduser,
  specialitystatusreduser: specialitystatusreduser,
  activestatelistreduser: activestatelistreduser,
  restaurantViewreduser: restaurantViewreduser,
  EditCityReduser: EditCityReduser,
  getCityReduser: getCityReduser,
  EditStateReduser: EditStateReduser,
  restauranttypeListreduser: restauranttypeListreduser,
  accountStatusReduser: accountStatusReduser,
  vendorList: vendorList,
  getStateReduser: getStateReduser,
  getCountryyReduser: getCountryyReduser,
  EditcountryReduser: EditcountryReduser,
  documentListReduser: documentListReduser,
  documentviewListReduser: documentviewListReduser,
  bagSizeListReduser: bagSizeListReduser,
  bagsizeAddReduser: bagsizeAddReduser,
  typeListReduser: typeListReduser,
  typeAddReduser: typeAddReduser,
  typestatusReduser:typestatusReduser,
  bagsizeStatusReduser:bagsizeStatusReduser,
  SpecialityUpdateReduser:SpecialityUpdateReduser,
  bagsizedetailsReduser:bagsizedetailsReduser,
  SpecialitydetailsReduser:SpecialitydetailsReduser,
  bagsizeupdateReduser:bagsizeupdateReduser,
  typeupdateReduser:typeupdateReduser,
  typedetailsReduser:typedetailsReduser,
  supportListReduser:supportListReduser,
  getsupportStatusReduser:getsupportStatusReduser,
  settingReduser:settingReduser,
  getsettingReduser:getsettingReduser,
  changeState: changeState,
  supportStatusReduser: supportStatusReduser,
  chatSendReduser: chatSendReduser,
  chatGetReduser: chatGetReduser,
  ExcleDownloadOrder: ExcleDownloadOrder,
  DashboardReducer: DashboardReducer,
  UserList:UserList,
  UserViewreduser:UserViewreduser,
  Commissionreduser:Commissionreduser,
  notificationreadReduser:notificationreadReduser,
  notificationcountReduser:notificationcountReduser,
  notificationReduser:notificationReduser,
  roledropdownReduser:roledropdownReduser,
  permissionUpdateReduser:permissionUpdateReduser,
  permissionGetReduser:permissionGetReduser,
  permissionGetvalueReduser:permissionGetvalueReduser,
  updateAdminReduser:updateAdminReduser,
  EmailOtpReduser:EmailOtpReduser,
  uploadimagereduser: uploadimagereduser,
  bankListreduser: bankListreduser,
  getDocumentReduser: getDocumentReduser,
  Changepasswordreduser:Changepasswordreduser,
  resturantlistreduser:resturantlistreduser,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return rootReducerr(state, action);
};

export default rootReducer;
