import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import {
  getstatelist_api,
  speciality_status,
  specialtyList_api,
  stateStatus_api,
} from "../../API/baseUrl";
import {
  removeDataFromReducer,
  specialitylistRequest,
  specialityStatusRequest,
  stateList,
  stateStatusRequest,
} from "../../redux/actions";
import DataTable from "react-data-table-component";
import ClearIcon from '@mui/icons-material/Clear';
import { CButton, CFormSwitch, CRow, CCol } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { TableSk } from "../skelaton/tableSk";
import { CuisineTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { FormControlLabel, styled, Switch } from "@mui/material";

const Specialty = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { specialitylistdata } =
    useSelector((state) => state?.specialitylistreduser) || {};
  const { specialitystatusdata } =
    useSelector((state) => state?.specialitystatusreduser) || {};

  useEffect(() => {

    document.title = CuisineTitle;
  }, []);

  useEffect(() => {
    if (specialitylistdata?.status) {
      setData(specialitylistdata?.data);
      setTotalRows(specialitylistdata?.count);
      setPending(false);
    } else {
      ToastMessage("error", specialitylistdata?.message);
    }
  }, [specialitylistdata]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: specialtyList_api,
    };
    dispatch(specialitylistRequest(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleAdd = (e) => {
    navigate("/dashboard/speciality/add");
  };

  
  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));



  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      column_name: "id",
      reorder: true,
    },
    {
      name: "Name",
      selector: (row) => row.specialty,
      sortable: true,
      column_name: "specialty",
      reorder: true,
    },

    {
      name: " Status",

      selector: (row) => (

        parsedDatapermission?.cuisneEdit == 1 ?
          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={row.isActive}
                checked={row.isActive == '1'}
                onChange={() => handleStatus(row.id)}
              />
            }
            label={row.isActive === 1 ? "Active" : "Inactive"}
          /> : row.isActive === 1 ? "Active" : "Inactive"


      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },



    // {
    //   name: "Status",

    //   selector: (row) => (
    //     parsedDatapermission?.cuisneEdit == 1 ?
    //     <div className="switch_row">
    //       <CFormSwitch
    //       onClick={() => handleStatus(row.id)}
    //       label=""
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //         color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }}
    //     />
    //       <p>{row.isActive === 1 ? "Active" : "Inactive"}</p>
    //     </div>
    //      : row.isActive === 1 ? "Active" : "Inactive"
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },


    ...(parsedDatapermission?.cuisneEdit == 1
      ? [ 
    {
      name: "Edit",
      selector: (row) => (
        <button className="edit_btn" onClick={() => handleEdit(row.id)}
          style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }}
        >
          Edit
        </button>
      ),
      // sortable: true,
      column_name: "",
      reorder: true,
    },
        ]
      : []),



  ];


  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("SPECIALITY_DETAILS_RESET"));
    navigate(`/dashboard/speciality/add/${id}`);
  };

  const handleStatus = (id) => {
    const parameter = {
      url: `${speciality_status}/${id} `,
    };
    dispatch(specialityStatusRequest(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: specialtyList_api,
    };
    if (specialitystatusdata?.status) {
      ToastMessage("success", specialitystatusdata?.message);
      dispatch(specialitylistRequest(parameter));
      dispatch(removeDataFromReducer("SPECIALITY_STATUS_RESET"));
    } else {
      ToastMessage("error", specialitystatusdata?.message);
    }
  }, [specialitystatusdata]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };

  return (
    <div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Cuisine List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>

          { parsedDatapermission?.cuisneEdit == 1 ?  
           <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            className="me-md-2 btn_add_country"
            onClick={handleAdd}
          // disabled={isLoading}
          >
            Add New
          </CButton> : ""}
        
        </div>
      </div>


      {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-5">
        <CButton color="primary" className="me-md-2" onClick={handleAdd}>
          Add Speciality
        </CButton>
      </div> */}

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default Specialty;
