import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cityStatus_api, getcitylist_api } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';

import {
  cityList,
  cityStatusRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import { CityTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { FormControlLabel, styled, Switch } from "@mui/material";



const City = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(localStorage.getItem('currentPage'));
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { cityListInfo } = useSelector((state) => state?.cityList || {});
  const { citystatusdata } = useSelector((state) => state?.citystatus) || {};
  useEffect(() => {

    document.title = CityTitle;
  }, []);

  const handleAdd = (e) => {
    dispatch(removeDataFromReducer("CITY_DETAILS_RESET"));
    navigate("/dashboard/city/add");
  };


  // Retrieve the saved page number from local storage on component mount
  // useEffect(() => {
  //   const savedPage = localStorage.getItem('currentPage');
  //   if (savedPage) {
  //     setCurrentPage(Number(savedPage));
  //   }
  // }, []);

  // // Update the page and save the current page to local storage
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   localStorage.setItem('currentPage', pageNumber);
  // };

  useEffect(() => {
    if (cityListInfo?.status) {
      setData(cityListInfo?.data);
      setTotalRows(cityListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", cityListInfo?.message);
    }
  }, [cityListInfo]);

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcitylist_api,
    };
    dispatch(cityList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleStatus = (id) => {
    const parameter = {
      url: `${cityStatus_api}/${id} `,
    };
    dispatch(cityStatusRequest(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcitylist_api,
    };
    if (citystatusdata?.status) {
      ToastMessage("success", citystatusdata?.message);
      dispatch(cityList(parameter));
      dispatch(removeDataFromReducer("CITY_STATUS_RESET"));
    } else {
      ToastMessage("error", citystatusdata?.message);
    }
  }, [citystatusdata]);


  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));


  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);


  const columns = [
    {
      name: "Id",
      selector: (row) => row?.cityId,
      sortable: true,
      column_name: "cityId",
      reorder: true,
    },
    {
      name: "Name",
      selector: (row) => row.cityName,
      sortable: true,
      column_name: "cityName",
      reorder: true,
    },
    {
      name: "State ",
      selector: (row) => row.stateName,
      // sortable: true,
      column_name: "stateName",
      reorder: true,
    },
    {
      name: "Country ",
      selector: (row) => row.countryName,
      // sortable: true,
      column_name: "countryName",
      reorder: true,
    },


    {
      name: " Status",

      selector: (row) => (

        parsedDatapermission?.cityEdit == 1 ?
          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={row.isActive}
                checked={row.isActive == 1}
                onChange={() => handleStatus(row.cityId)}
              />
            }
            label={row.isActive === 1 ? "Active" : "Inactive"}
          /> : row.isActive === 1 ? "Active" : "Inactive"


      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },


    // {
    //   name: "Status",
    //   selector: (row) => (
    //     parsedDatapermission?.cityEdit == 1 ?
    //     <CFormSwitch
    //       onClick={() => handleStatus(row.cityId)}
    //       label={row.isActive === 1 ? "Active" : "Inactive"}
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //         color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }}
    //     /> : row.isActive === 1 ? "Active" : "Inactive"
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },

    ...(parsedDatapermission?.cityEdit == 1
      ? [      
    {
      name: "Edit",
      selector: (row) => (
        <button className="edit_btn" onClick={() => handleEdit(row.cityId)}
          style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }}
        >
          Edit
        </button>
      ),
      // sortable: true,
      column_name: "",
      reorder: true,
    },
        ]
      : []),



  ];

  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("CITY_DETAILS_RESET"));
    navigate(`/dashboard/city/add/${id}`);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };
  const handlePerRowsChange = async (newLimit, offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };
  return (
    <div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>City List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div> 
          {parsedDatapermission?.cityEdit == 1 ? 
            <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            className="me-md-2 btn_add_country"
            onClick={handleAdd}
          // disabled={isLoading}
          >
            Add City
          </CButton>:""}
        
        </div>
      </div>
      {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-5">
        <CButton color="primary" className="me-md-2" onClick={handleAdd}>Add City</CButton>
      </div> */}
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default City;
