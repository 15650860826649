import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import "./index.css"

import { CBadge, CNavLink, CSidebarNav } from '@coreui/react'
import { emptytokenaction, removeDataFromReducer } from '../redux/actions'
import { useDispatch } from 'react-redux'
import swal from "sweetalert";
import { empty_devicetoken_Api } from '../API/baseUrl'

export const AppSidebarNav = ({ items }) => {
  const userIdFk = localStorage.getItem("userIdFk")


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Logout = () => {
    const parameter = {
      url: `${empty_devicetoken_Api}${userIdFk}`,
    }

    dispatch(emptytokenaction(parameter));
  }
  const handleLogout = () => {
    swal({
      title: "Are you sure you want to logout?",
      text: "Once logged out, you will need to login!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
  
      if (willDelete) {
        Logout();
        localStorage.clear();
        dispatch(removeDataFromReducer("LOGIN_USER_RESET"));
        dispatch(removeDataFromReducer("PERMISSION_GET_RESET"));
        dispatch(removeDataFromReducer("Eamil_Otp_RESET"));
        navigate("/");
      }
    });
  }

  const handleclick = () => {
    dispatch(removeDataFromReducer("CITY_LIST_RESET"));
    dispatch(removeDataFromReducer("STATE_LIST_RESET"));
    dispatch(removeDataFromReducer("COUNTRY_LIST_RESET"));
    dispatch(removeDataFromReducer("ACTIVETYPE_LIST_RESET"));
    dispatch(removeDataFromReducer("SPECIALITY_LIST_RESET"));
    dispatch(removeDataFromReducer("VENDOR_LIST_RESET"));
    dispatch(removeDataFromReducer("User_LIST_RESET"));
    dispatch(removeDataFromReducer("DASHBOARD_DATA_RESET"));
    dispatch(removeDataFromReducer("FAQ_LIST_RESET"));
    dispatch(removeDataFromReducer("ORDER_LIST_RESET"));
    dispatch(removeDataFromReducer("TYPE_LIST_RESET"));
  }





  const navLink = (name, icon, badge, indent = false, access) => {
    return (

      <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
        {icon
          ? icon
          : indent && (
            <span className="nav-icon">
              <span className="nav-icon-bullet"></span>
            </span>
          )}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </div>
    )
  }

  const navItem = (item, index, indent = false) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      // item.access == true ? 
      <Component as="div" key={index}>
        {rest.to || rest.href ? (
          item.access == true ?
            <CNavLink {...(rest.to && { as: NavLink })} {...rest} onClick={handleclick}>
              {navLink(name, icon, badge, indent)}
            </CNavLink> : ""
        ) :
          <CNavLink sx={{ cursor: "pointer" }} onClick={handleLogout}  {...rest}>
            {navLink(name, icon, badge, indent)}
          </CNavLink>
        }
      </Component>
      // :
      //   <Component as="div" key={index}>
      //   <CNavLink sx={{cursor:"pointer"}} onClick={handleLogout}  {...rest}>
      //   {navLink(name, icon, badge, indent)}
      // </CNavLink>

      // </Component> 
    )
  }

  const navGroup = (item, index) => {
    const { component, name, icon, items, to, ...rest } = item
    const Component = component
    return (

      <Component compact as="div" key={index} toggler={navLink(name, icon)} {...rest}>
        {item.items?.map((item, index) =>
          item.access == true ? (item.items ? navGroup(item, index) : navItem(item, index, true)) : "",
        )}
      </Component>
    )
  }

  return (
    <CSidebarNav as={SimpleBar}>
      {items &&
        items.map((item, index) =>
        (
          item.items ? navGroup(item, index) : navItem(item, index))

        )
      }
    </CSidebarNav>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
