import React, { useEffect, useState } from 'react';
import { TextField, Box, Stack, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import './index.css';

const ExcelDownloader = ({
  showdatefilter,
  showdoenload,
  callBack,
  value,
  margin,
  setSearchtext,
  startvalue,
  endvalue,
  setStartvalue,
  setEndvalue,
  handleExcel,
  excelLoading
}) => {
  // Local state to manage the date values
  const [startDate, setStartDate] = useState(startvalue || '');
  const [endDate, setEndDate] = useState(endvalue || '');

  const handleDownload = () => {
    // console.log('Start Date:', startDate, 'End Date:', endDate);
    // const formattedStartDate = dayjs(startDate).format('DD-MM-YYYY');
    // const formattedEndDate = dayjs(endDate).format('DD-MM-YYYY');
    // setStartvalue(startDate);
    // setEndvalue(endDate);
    // handleExcel();
    // callBack(value);
    // console.log("valueeeeeee",value)


    console.log('Start Date:', startDate, 'End Date:', endDate);

    const formattedStartDate = dayjs(startDate).format('DD-MM-YYYY');
    const formattedEndDate = dayjs(endDate).format('DD-MM-YYYY');
    

    console.log('Formatted Start Date:', formattedStartDate);
    console.log('Formatted Start Date:', formattedEndDate);
    
    setStartvalue(formattedStartDate); 
    setEndvalue(formattedEndDate);     
    
    handleExcel();
    callBack(value);
    
    console.log("valueeeeeee", value);
  };

  useEffect(() => {
    setStartvalue(dayjs(startDate).format('DD-MM-YYYY')); 
    setEndvalue(dayjs(endDate).format('DD-MM-YYYY'));
    // setStartvalue(startDate);
    // setEndvalue(endDate);
  }, [startDate, endDate]);


  const today = dayjs().format('YYYY-MM-DD'); // Get today's date in 'YYYY-MM-DD' format

  return (
    <Box sx={{ marginBottom: '25px' }}>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        {showdatefilter && (
          <>
            <TextField
              focused
              size="small"
              name="start-date"
              type="date"
              label="From"
              value={startDate}

              onChange={(e) => {
                const selectedDate = e.target.value;
                setStartDate(selectedDate);
                setEndDate(today)
              }}
              sx={{ width: '30%', minWidth: '250px', margin: '7px' }}

              inputProps={{ max: today }}
            />

            <TextField
              focused
              size="small"
              name="end-date"
              type="date"
              label="To"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.target.value;
                setEndDate(selectedDate); // Update state with the original format
              }}
              sx={{ width: '30%', minWidth: '250px', margin: '7px' }}
              // Ensure End Date is after Start Date
              inputProps={{ min: startDate, max: today }}
            />
          </>
        )}
        {showdoenload == true ?
          <button className="excle_btn" variant="" onClick={handleDownload}>
            {excelLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Download Excel'}
          </button> : ""}



      </Stack>
    </Box>
  );
};

export default ExcelDownloader;
