import { CButton, CCol, CFormInput, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminuser_creat_api, change_password_Admin_Api, cityStatus_api, editUseradmin_api, getcitylist_api, useradmin_api, userrole_dropdown_api } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput, styled, Switch, TextField, Typography
} from "@mui/material";
import {
  addcityRequest,
  changepasswordaction,
  cityList,
  cityStatusRequest,
  removeDataFromReducer,
  roledropdownaction,
  updateadminUser,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import { CityTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import { useFormik } from "formik";
import { adminuserscehema } from "../../utils/schema";



const Adminuser = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);
  const [editid, setEditid] = useState();

  const [editid1, setEditid1] = useState();
  const [open, setOpen] = React.useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openedit1, setOpenedit1] = React.useState(false);
  console.log("editid1", editid1)
  const [passwordchangemodal, setPasswordchangemodal] = React.useState(false);
  const [roledropdownval, setRoledropdownval] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(localStorage.getItem('currentPage'));
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [roleError, setRoleError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [checkedActivevalue, setCheckedActivevalue] = useState(1);
  const [password, setPassword] = useState('');
  console.log("password", password)
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { cityListInfo } = useSelector((state) => state?.cityList || {});
  const { citystatusdata } = useSelector((state) => state?.citystatus) || {};
  const { roledropdownData } = useSelector((state) => state?.roledropdownReduser) || {};
  const { citycreatedata } = useSelector((state) => state?.cityaddreduser) || {};
  const { updateAdminData } = useSelector((state) => state?.updateAdminReduser) || {};
  const { changepassworddata } = useSelector((state) => state?.Changepasswordreduser) || {};
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const datapermission = localStorage.getItem("permissiondata")
  const parsedDatapermission = JSON.parse(datapermission);
  const handleStatusChangeActive = (e) => {
    setCheckedActivevalue(checkedActivevalue == 0 ? 1 : 0)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: "none",
    p: 2,
  };

  const Intial = {
    roleid: "",
    email: "",                                                                                                                                                                                                                                           
    password: "",
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: adminuserscehema,
    onSubmit: (values) => ApiHandlesubmit(),
  });

  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      email: values.email,
      password: values.password,
      roleid: values.roleid,
      url: adminuser_creat_api,
    };
    dispatch(addcityRequest(parameter));
  };

  const ChangepasswordApi = () => {
    setLoading(true);
    const parameter = {
      url: `${change_password_Admin_Api}${editid1}`,
      password: password,
    };
    if (!password) {
      setPasswordError("This field is required")
      setLoading(false);
    }
    else{
      dispatch(changepasswordaction(parameter));
    }
  };

  useEffect(() => {
    if (changepassworddata) {
      setLoading(false);
      if (changepassworddata.status) {
        ToastMessage("success", changepassworddata?.message);
        handleCloseedit1(false);
        setPassword("");
        dispatch(removeDataFromReducer("CHANGE_PASSWORD_RESET"));
      } else {
        ToastMessage("error", changepassworddata?.message);
      }
    }
  }, [changepassworddata]);




  useEffect(() => {
    document.title = "Users";
  }, []);

  const handleAdd = (e) => {
    values.roleid = ""
    setFieldValue("roleid", "")
    setFieldValue("email", "")
    setFieldValue("password", "")
    setOpen(true)
  };


  const handleClose = () => setOpen(false);
  const handleCloseedit = () => setOpenedit(false);
  const handleCloseedit1 = () => setOpenedit1(false);
  const handleClose1 = () => setOpen1(false);

  useEffect(() => {
    if (cityListInfo?.status) {
      setData(cityListInfo?.data);
      setTotalRows(cityListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", cityListInfo?.message);
    }
  }, [cityListInfo]);

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: useradmin_api,
    };
    if (updateAdminData?.status) {
      ToastMessage("success", updateAdminData?.message);

      setLoading(false)
      setOpenedit(false)
      dispatch(cityList(parameter));
      dispatch(removeDataFromReducer("UPDATE_ADMIN_RESET"));
    } else {
      ToastMessage("error", updateAdminData?.message);
    }
  }, [updateAdminData]);


  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: useradmin_api,
    };
    if (citycreatedata?.status) {
      setLoading(false)
      ToastMessage("success", citycreatedata?.message);
      setOpen(false)
      dispatch(cityList(parameter));
      dispatch(removeDataFromReducer("ADD_CITY_RESET"));
    } else {
      setLoading(false)
      ToastMessage("error", citycreatedata?.message);
    }
  }, [citycreatedata]);

  useEffect(() => {
    if (roledropdownData?.status) {
      setRoledropdownval(roledropdownData?.data);
    } else {
      ToastMessage("error", roledropdownData?.message);
    }
  }, [roledropdownData]);

  useEffect(() => {
    setPending(true);
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: useradmin_api,
    };
    dispatch(cityList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleStatus = (id) => {
    const parameter = {
      url: `${cityStatus_api}/${id} `,
    };
    dispatch(cityStatusRequest(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcitylist_api,
    };
    if (citystatusdata?.status) {
      ToastMessage("success", citystatusdata?.message);
      dispatch(cityList(parameter));
      dispatch(removeDataFromReducer("CITY_STATUS_RESET"));
    } else {
      ToastMessage("error", citystatusdata?.message);
    }
  }, [citystatusdata]);

  useEffect(() => {
    const parameter = {
      url: userrole_dropdown_api,
    };
    dispatch(roledropdownaction(parameter));
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      column_name: "id",
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      // sortable: true,
      column_name: "email",
      reorder: true,
    },
    {
      name: "Role ",
      selector: (row) => row.user_role,
      // sortable: true,
      column_name: "user_role",
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.isActive === 1 ? "Active" : "Inactive",
      column_name: "isActive",
      reorder: true,
    },



    ...(parsedDatapermission?.adminUseredit == 1
      ? [
        {
          name: "Edit",
          selector: (row) => (
            <button className="edit_btn" onClick={() => handleEdit(row.id, row.roleIdfk, row.isActive)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Edit
            </button>
          ),
          column_name: "",
          reorder: true,
        },
      ]
      : []),




    ...(parsedDatapermission?.adminUseredit == 1
      ? [
        {
          name: "Change Password",
          selector: (row) => (
            <button className="edit_btn" onClick={() => handleEdit1(row.id)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Change
            </button>
          ),
          column_name: "",
          reorder: true,
        },
      ]
      : []),


    // {
    //   name: "Edit",
    //   selector: (row) => (
    //     <button className="edit_btn" onClick={() => handleEdit(row.id)}
    //       style={{
    //         backgroundColor: "var(--colorused)",
    //         border: "none",
    //         color: "var(--colorWhite)",
    //       }}
    //     >
    //       Edit
    //     </button>
    //   ),
    //   column_name: "",
    //   reorder: true,
    // },

  ];

  const handleEdit = (id, role, active) => {
    setCheckedActivevalue(active)
    values.roleid = role
    setFieldValue("roleid", role)
    values.password = ""
    setEditid(id)
    setOpenedit(true)
  };

  const handleEdit1 = (id, role, active) => {
    setEditid1(id)
    setOpenedit1(true)
  };

  const handlepasswordchange = (id, role, active) => {
    setPasswordchangemodal(true)
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };


  const handlePerRowsChange = async (newLimit, offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };

  const handleRoleChange = (event) => {
    values.roleid = event.target.value
    setFieldValue("roleid", event.target.value)
    setRole(event.target.value);
  };

  const handleclearSearch = () => {
    setSearchText("");
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };

  const handleSubmitedit = (e) => {
    const parameter = {
      roleIDfk: values.roleid,
      isActive: checkedActivevalue,
      url: `${editUseradmin_api}${editid}`,
    };
    if (!values.roleid) {
      setRoleError("Role is required")
    }
    else {
      setLoading(true)
      dispatch(updateadminUser(parameter));
    }
  };


  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));


  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Admin User List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>

          {parsedDatapermission?.adminUseredit == 1 ?
            <CButton
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              className="me-md-2 btn_add_country"
              onClick={handleAdd}
            // disabled={isLoading}
            >
              Add User
            </CButton> : ""}


        </div>
      </div>
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />



      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
          component="form"
          onSubmit={handleSubmit2}
        >

          <Typography sx={{ mb: "5px" }} id="modal-modal-title" variant="h6" component="h2">
            Add Admin
          </Typography>

          {/* <FormControl
            fullWidth
            style={{ marginBottom: "20px", width: "100%", background: "#e8f0fe" }}
          >
            <InputLabel>Role</InputLabel>
            <Select
              name="roleid"
              label="Role"
              value={values.roleid}
              onChange={handleRoleChange}
            >
              {roledropdownval?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.user_role}
                </MenuItem>
              ))}
            </Select>

            {errors.roleid && touched.roleid && (
              <p className="form-error">{errors.roleid}</p>
            )}
          </FormControl> */}
          <div>
            <FormControl
              fullWidth
              style={{ marginBottom: "10px", background: "#e8f0fe" }}
            >
              <InputLabel>Role</InputLabel>
              <Select
                name="roleid"
                label="Role"
                value={values.roleid || ''} // Default value if roleid is not set
                onChange={handleRoleChange}
              >
                {roledropdownval?.length > 0 ? (
                  roledropdownval.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.user_role}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>No roles available</em>
                  </MenuItem>
                )}
              </Select>


            </FormControl>
            {errors.roleid && touched.roleid && (
              <p style={{ color: "red" }}>
                {errors.roleid}
              </p>
            )}
          </div>


          <FormControl
            sx={{ width: "100%", mb: 2 }}
            variant="outlined"
            className="textfield_background"
          >
            <InputLabel htmlFor="outlined-adornment-email">
              Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              name="email"
              autoComplete="false"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Email"
              sx={{ backgroundColor: "rgb(232, 240, 254)" }}
            />
            {errors.email && touched.email && (
              <p className="form-error">{errors.email}</p>
            )}
          </FormControl>

          <FormControl
            sx={{ width: "100%", mb: 2 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              autoComplete="false"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {!showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              sx={{ backgroundColor: "rgb(232, 240, 254)" }}
            />
            {errors.password && touched.password && (
              <div className="form-error">{errors.password}</div>
            )}
          </FormControl>


          <div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">

            <CButton style={{
              backgroundColor: "red",
              border: "none",
              // width: "120px",
              color: "var(--colorWhite)",
            }} type="button"
              onClick={handleClose}
              className="me-md-2">
              <>Cancel</>


            </CButton>

            <CButton style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              // width: "120px",
              color: "var(--colorWhite)",
            }} type="submit" className="me-md-2">
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) :

                <>Submit</>
              }

            </CButton>
          </div>


        </Box>
      </Modal>

      <Modal
        open={openedit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
        >
          <Typography sx={{ mb: "7px" }} id="modal-modal-title" variant="h6" component="h2">
            Edit Admin
          </Typography>
          <FormControl
            fullWidth
            style={{ marginBottom: "20px", width: "100%", background: "#e8f0fe" }}
          >
            <InputLabel>Role</InputLabel>
            <Select
              name="roleid"
              label="Role"
              value={values.roleid}
              onChange={handleRoleChange}
            >
              {roledropdownval?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.user_role}
                </MenuItem>
              ))}
            </Select>
            {roleError ?
              <p className="form-error">{roleError}</p> : ""}
          </FormControl>




          {/* <FormControl
            sx={{ width: "100%", mb: 2 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              autoComplete="false"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {!showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              sx={{ backgroundColor: "rgb(232, 240, 254)" }}
            />
            {passwordError ?
              <p className="form-error">{passwordError}</p> : ""}
          </FormControl> */}



          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={checkedActivevalue}
                checked={checkedActivevalue == 1}
                onChange={(e) => handleStatusChangeActive(e)}
              />
            }
            label={checkedActivevalue === 1 ? "Active" : "Inactive"}
          />


          <div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">

            <CButton style={{
              backgroundColor: "red",
              border: "none",
              // width: "120px",
              color: "var(--colorWhite)",
            }} type="button"
              onClick={handleCloseedit}
              className="me-md-2">
              <>Cancel</>


            </CButton>

            <CButton style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              // width: "120px",
              color: "var(--colorWhite)",
            }} type="button" onClick={handleSubmitedit} className="me-md-2">
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) :

                <>Update</>
              }

            </CButton>
          </div>


        </Box>
      </Modal>

      <Modal
        open={openedit1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
        >
          <Typography sx={{ mb: "7px" }} id="modal-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>
          <FormControl
            fullWidth
            style={{ marginBottom: "20px", width: "100%", background: "#e8f0fe" }}
          >
            <TextField
              id="outlined-basic"
              label="Change Password"
              variant="outlined"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                console.log(e.target.value, "fjdsfsd");
              }}
            />

            {roleError ?
              <p className="form-error">{roleError}</p> : ""}
          </FormControl>



          <div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">
            <CButton style={{
              backgroundColor: "red",
              border: "none",
              color: "var(--colorWhite)",
            }} type="button"
              onClick={handleCloseedit1}
              className="me-md-2">
              <>Cancel</>
            </CButton>

            <CButton style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
            }} type="button" onClick={ChangepasswordApi} className="me-md-2">
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) :
                <>Update</>
              }
            </CButton>
          </div>

        </Box>
      </Modal>

    </div>
  );
};

export default Adminuser;

