// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import "./index.css";
// import {
//   supportChatgETAction,
//   supportChatSendAction,
// } from "../../../redux/actions";
// import ToastMessage from "../../../utils/ToastMessage";
// import {
//   GetTicketChatData_api,
//   TicketChatData_api,
// } from "../../../API/baseUrl";
// import { Avatar } from "@mui/material";
// import SendIcon from '@mui/icons-material/Send';
// import CloseIcon from '@mui/icons-material/Close';
// const ChatBox = ({ supportTicketIdFk, ticketId, onClose }) => {
//   const [TicketChatData, setTicketChatData] = useState({});
//   const [messageContent, setMessageContent] = useState(""); // State to store the input value
//   const [ChatGetData, setGetChatData] = useState([]);
//   const dispatch = useDispatch();
//   const { chatsenddata } = useSelector((state) => state?.chatSendReduser || {});
//   const { getChatdata } = useSelector((state) => state?.chatGetReduser || {});
//   const chatMessagesEndRef = useRef(null);
//   useEffect(() => {
//     chatMessagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [ChatGetData]);

//   // *************Post API**********
//   useEffect(() => {

//     if (chatsenddata?.status) {
//       console.log("Ticket data:", chatsenddata?.data);
//       GetChatData();
//       setTicketChatData(chatsenddata?.data);
//     } else {
//       ToastMessage("error", chatsenddata?.message);
//     }
//   }, [chatsenddata]);

//   const ChatData = () => {
//     GetChatData();
//     if (messageContent.trim() === "") {
//       ToastMessage("error", "Message cannot be empty");
//       return;
//     }
//     const parameter = {
//       supportTicketIdFk: supportTicketIdFk,
//       from: "admin",
//       fromType: "admin",
//       ticketId: ticketId,
//       content: messageContent,
//       type: "text",
//       url: TicketChatData_api,
//     };

//     dispatch(supportChatSendAction(parameter));
//     setMessageContent("");
//   };

//   const handleInputChange = (e) => {
//     setMessageContent(e.target.value);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       ChatData();
//     }
//   };

//   // **************Get Chat************
//   useEffect(() => {
//     if (getChatdata?.status) {
//       console.log("Ticket data:", getChatdata?.chatData);
//       setGetChatData(getChatdata?.chatData);
//     } else {
//       ToastMessage("error", getChatdata?.message);
//     }
//   }, [getChatdata]);

//   const GetChatData = () => {
//     const parameter = {
//       ticketId: ticketId,
//       url: GetTicketChatData_api,
//     };

//     dispatch(supportChatgETAction(parameter));
//     setMessageContent("");
//   };

//   useEffect(() => {
//     GetChatData();
//   }, []);
//   return (
//     <div className='chat-room'>
//       <div className="crossbtn">
//         < CloseIcon onClick={onClose} className="CrossIcon"
//         /></div>
//       <div className="chat-messages">
//         {ChatGetData?.map((TicketChatData, index) => {
//           const messageClass =
//             TicketChatData?.chatFromType === "admin"
//               ? "admin-message"
//               : "vendor-message";

//           return (
//             <div key={index} className={`message-container ${messageClass}`}>

//               <div
//                 className='d-flex  gap-2'
//               >

//                 <Avatar
//                   alt={TicketChatData?.chatFromType}
//                   src="/static/images/avatar/1.jpg"
//                 />
//                 <span
//                   className="ml-2"
//                   style={{
//                     marginLeft: "3px",
//                   }}
//                 >
//                   {" "}
//                   {TicketChatData?.chatContent}
//                 </span>
//               </div>
//             </div>
//           );
//         })}
//         <div ref={chatMessagesEndRef} />
//       </div>

//       <div className="chatinput">
//         <input
//           type="text"
//           value={messageContent}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           placeholder="Type a Message "
//           className="message_box_input"
//         />
//         <button type="submit" onClick={ChatData} className="send-button" >
//           <SendIcon />
//         </button>
//       </div>
//     </div>
//   );
// };


// export default ChatBox;

// **********

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  supportChatgETAction,
  supportChatSendAction,
} from "../../../redux/actions";
import ToastMessage from "../../../utils/ToastMessage";
import {
  GetTicketChatData_api,
  image_support_chat,
  ImageUrl,
  TicketChatData_api,
} from "../../../API/baseUrl";
import { Avatar, Box, Paper, IconButton, TextField, Typography, InputAdornment, CircularProgress, Modal } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { toast } from "react-toastify";
import ImagePop from './../../../components/ImagePop/Index';

const ChatBox = ({ supportTicketIdFk, ticketId, onClose }) => {
  const [TicketChatData, setTicketChatData] = useState({});
  const [messageContent, setMessageContent] = useState("");
  const [ChatGetData, setGetChatData] = useState([]);
  const dispatch = useDispatch();
  const { chatsenddata } = useSelector((state) => state?.chatSendReduser || {});
  const { getChatdata } = useSelector((state) => state?.chatGetReduser || {});
  const chatMessagesEndRef = useRef(null);
  const userIdFk = localStorage.getItem("userIdFk")
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)

  const [SendMessgae, SetSendMEssage] = useState(false)
  const [open, setOpen] = React.useState(false);
  console.log(open, "openopen")
  const [modleImage, setModleImage] = useState('')
  const handleOpen = (image) => {
    setModleImage(image)
    setOpen(true);
  }
  const handleClose = () => {
    setModleImage("")
    setOpen(false);
  }


  useEffect(() => {
    chatMessagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [ChatGetData]);

  useEffect(() => {
    if (chatsenddata?.status) {
      GetChatData();
      setTicketChatData(chatsenddata?.data);
    } else {
      ToastMessage("error", chatsenddata?.message);
    }
  }, [chatsenddata]);

  // const ChatData = () => {
  //   GetChatData();
  //   if (messageContent.trim() === "") {
  //     ToastMessage("error", "Message cannot be empty");
  //     return;
  //   }
  //   const parameter = {
  //     supportTicketIdFk: supportTicketIdFk,
  //     from: "admin",
  //     fromType: "admin",
  //     ticketId: ticketId,
  //     content: messageContent,
  //     type: "text",
  //     url: TicketChatData_api,
  //   };

  //   dispatch(supportChatSendAction(parameter));
  //   setMessageContent("");
  // };



  const ChatData = async () => {
    if (messageContent.trim() === "" && !file) {

      return;
    }



    const fd = new FormData();
    fd.append('supportTicketIdFk', supportTicketIdFk);
    fd.append('from', String(userIdFk));
    fd.append('fromType', 'admin');
    fd.append('ticketId', ticketId);
    fd.append('content', messageContent ? messageContent : "");
    fd.append('type', file ? 'files' : 'text');

    if (file) {
      fd.append('files', file);
    }

    try {
      const parameter = {
        data: fd,
        url: image_support_chat,
      };
      dispatch(supportChatSendAction(parameter));
      setFile("");
      setMessageContent('');
    } catch (error) {
      ToastMessage('error', 'Failed to send message');
    }
  };



  const handleInputChange = (e) => {
    const value = e.target.value;

    setMessageContent(value);
    SetSendMEssage(value.trim() !== "");

  }



  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      ChatData();
    }
  };

  useEffect(() => {
    if (getChatdata?.status) {
      setGetChatData(getChatdata?.chatData);
      setLoading(false)
    } else {
      ToastMessage("error", getChatdata?.message);
    }
  }, [getChatdata]);

  const GetChatData = () => {
    const parameter = {
      ticketId: ticketId,
      url: GetTicketChatData_api,
    };

    dispatch(supportChatgETAction(parameter));
    setMessageContent("");
  };

  useEffect(() => {
    GetChatData();
  }, []);

  const handleAttachFile = () => {
    // Create an input element dynamically to trigger the file dialog
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*,/*'; // Specify file types if needed (optional)

    // When the user selects a file, set it to the state
    fileInput.onchange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        SetSendMEssage(true)
        setFile(selectedFile);
        console.log('File selected:', selectedFile);
      }
    };
    console.log(file, "file")
    // Trigger the file input click event
    fileInput.click();
  };
  const handleRemoveImgae = () => {
    SetSendMEssage(false)
    setFile("")
  }
  return (
    <Paper elevation={3} sx={{ width: "100%", height: "88vh", display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #eee' }}>
        <Typography variant="h6">Chat Support</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
        {ChatGetData?.map((chat, index) => {
          console.log("kkkkk", chat)
          const isAdmin = chat?.chatFromType === "admin";
          const fileExtension = chat?.chatContent.split('.').pop()?.toLowerCase();
          const isImage = fileExtension && ["png", "jpg", "jpeg", "gif"].includes(fileExtension);
          const isVideo = fileExtension && ["mp4", "mov", "avi"].includes(fileExtension);
          const isText = chat?.contentType === "text" && chat?.chatContent?.trim() !== "";

          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                flexDirection: isAdmin ? 'row-reverse' : 'row',
              }}
            >
              <Avatar alt={chat?.chatFromType} src="/static/images/avatar/1.jpg" />
              <Paper
                elevation={1}
                sx={{
                  p: 1,
                  ml: isAdmin ? 0 : 2,
                  mr: isAdmin ? 2 : 0,
                  backgroundColor: isAdmin ? 'var(--colorused)' : '#f5f5f5',
                  color: isAdmin ? 'white' : 'black',
                  borderRadius: 2,
                  maxWidth: '70%',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              > {isText && (
                <Typography variant="body2">{chat?.chatContent}</Typography>
              )}


                {isImage && (
                  <img
                    src={`${ImageUrl}${chat?.chatContent}`}
                    alt="Chat Image"
                    style={{
                      maxWidth: '100%',
                      borderRadius: '8px',
                      display: 'block',
                      height: "100px"

                    }}
                    onClick={(e) => {
                      handleOpen(`${ImageUrl}${chat?.chatContent}`)
                    }}
                  />
                )}
                {isVideo && (
                  <video
                    src={`${ImageUrl}${chat?.chatContent}`}
                    controls
                    style={{
                      maxWidth: '100%',
                      borderRadius: '8px',
                      display: 'block',
                      height: "200px"
                    }}
                    onError={(e) => console.error("Error loading video:", e)}
                  >
                    Your browser does not support the video tag.
                  </video>
                )}
              </Paper>
            </Box>
          );
        })}
        <div ref={chatMessagesEndRef} />
      </Box>
      <Box sx={{ p: 2 }}>
        {file && (
          <Box style={{ display: "flex", flexDirection: "column", alignItems: "end", maxWidth: '30%' }}>
            <CloseIcon onClick={handleRemoveImgae} />
            {file.type.startsWith('image') ? (
              <img src={URL.createObjectURL(file)} alt="File preview" style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
              <Typography variant="body2">{file.name}</Typography>
            )}
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <ImagePop  src={modleImage}    onClose={handleClose}/>

        </Box>
      </Modal>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          borderTop: '1px solid #eee',
        }}
      >
        <TextField
          fullWidth
          value={messageContent}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message"
          variant="outlined"
          size="small"
          disabled={Boolean(file)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleAttachFile}
                  style={{ padding: 2 }}
                  disabled={Boolean(messageContent)}
                >
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          onClick={ChatData}

          style={{
            color: "var(--colorused)",
            cursor: SendMessgae ? "pointer" : "not-allowed"
          }} >
          {loading ? (
            <CircularProgress style={{ color: "var(--colorused)" }} />
          ) :
            (
              <SendIcon />
            )}


        </IconButton>
      </Box>
    </Paper>
  );
};

export default ChatBox;
