import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

const ImagePop = ({ src, onClose }) => {
  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      onClick={handleClickOutside}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 9999,
        padding: '20px',

      }}
    >

      <Box style={{
        flexDirection: 'column',
        position: 'relative',
        width: '30% ',
        maxWidth: '800px',
        height: '50vh',
        backgroundColor: 'white',
        borderRadius: '20px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
      }} >

        <IconButton
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'black',
            zIndex: 1000,
            padding: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>


        <div

        >
          <img
            src={src}
            alt="Pop"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </div>
      </Box>


    </div >
  );
};

export default ImagePop;
