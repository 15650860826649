import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Box,
  Container,
  Typography,
  Divider,
} from "@mui/material";
import ViewScreen from "../../components/viewScreen";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ImageUrl,
  document_viewlist_api,
  get_ticket_status_api,
  restaurantView_api,
  supportTicket_getbyid_api,
} from "../../API/baseUrl";
import CloseIcon from '@mui/icons-material/Close';
import {
  documentViewRequest,
  getTicketStutus,
  restaurantViewRequest,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { CFormSelect, CFormTextarea } from "@coreui/react";
import DocumentStatusModal from "../../components/statusModal";
import SupportStatusModal from "./supportStatus";
import { Modal } from "@mui/material";
import { AiFillMessage } from "react-icons/ai";
import ChatBox from "./ChatByAPI/Index";
import { Overlay } from "react-bootstrap";
import ImageIcon from '@mui/icons-material/Image';
import {
  Chat,
  ConfirmationNumber,
  DateRange,
  Info,
  Person,
  Phone,
} from "@mui/icons-material";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EmailIcon from '@mui/icons-material/Email';
import SubjectIcon from '@mui/icons-material/Subject';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TicketSkeleton from "./ticketskeleton";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const style = {
  position: "absolute",
  top: "50%",
  right: "10px",
  transform: "translateY(-50%)",
  bgcolor: "none",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 0,
  width: '80vw',
  height: '80vh',
  maxWidth: '500px',
  maxHeight: '90vh',
  borderRadius: "8px",
  //   overflowY: "scroll",
};

const ViewSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurantdata, setRestaurantdata] = useState("");
  const [statusvalue, setStatusvalue] = useState("");
  const [accountstatus, setAccountstatus] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [statusArray, setStatusArray] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { documentviewListdata } = useSelector(
    (state) => state?.documentviewListReduser || {}
  );
  const { supportStatusdata } = useSelector(
    (state) => state?.getsupportStatusReduser || {}
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const parameter = {
      url: `${supportTicket_getbyid_api}${id}`,
    };
    dispatch(documentViewRequest(parameter));
  }, [id]);

  useEffect(() => {
    if (documentviewListdata?.status) {
      setRestaurantdata(documentviewListdata?.data);
      setStatusvalue(documentviewListdata?.data?.supportTicketStatusIdFk);
      setLoading(false)
    } else {
      ToastMessage("error", documentviewListdata?.message);
      setLoading(false)
    }
  }, [documentviewListdata]);

  const checkAvailability = (data) => data || "-";

  useEffect(() => {
    const parameter = {
      url: get_ticket_status_api,
    };
    dispatch(getTicketStutus(parameter));
  }, [id]);

  useEffect(() => {
    if (supportStatusdata?.status) {
      setStatusArray(supportStatusdata?.data);
    } else {
      ToastMessage("error", supportStatusdata?.message);
    }
  }, [supportStatusdata]);

  const handleselectchange = (e) => {
    const selectedStatus = e.target.value;
    setAccountstatus(e.target.value);
    setVisible(true);
  };

  const handleOpenImage = (url) => {

    const newWindow = window.open(url);
    if (newWindow) {
      newWindow.focus();
    }
  };



  const filePath = `${ImageUrl}${checkAvailability(restaurantdata?.file)}`;
  const isPdf = restaurantdata?.file?.toLowerCase()?.endsWith('.pdf');




  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);


  return (

    <div className="">
      <Box maxWidth="false" >
        <Stack className='editAndAddHeadClass headandback' >
          <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
          <h2>View Support Ticket</h2>
        </Stack>
      </Box>

      {loading ? <LoaderAnimation /> :

        <>
          {visible ? (
            <SupportStatusModal
              ticketId={restaurantdata?.ticketIdFk}
              ticket="true"
              {...{ visible, setVisible, setStatusvalue }}
              restaurantId={id}
              statusId={accountstatus}
            />
          ) : (
            ""
          )}



          <Box
            sx={{
              p: 3,
              borderRadius: "8px",
              bgcolor: "white",
              //   boxShadow: 2,
              border: "1px solid #ddd",
            }}
          >

            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <ConfirmationNumber sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Ticket ID :</strong>{" "}
                  {checkAvailability(restaurantdata?.ticketId)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <FolderSharedIcon sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>User Id : </strong>{" "}
                  {checkAvailability(restaurantdata?.userIdFk)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Person sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>User Name : </strong>{" "}
                  {checkAvailability(restaurantdata?.userName)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <EmailIcon sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Email : </strong>{" "}
                  {checkAvailability(restaurantdata?.email)}
                </Typography>
              </Grid>



              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <SubjectIcon sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Purpose : </strong>{" "}
                  {checkAvailability(restaurantdata?.purpose)}
                </Typography>
              </Grid>




              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <DateRange sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Ticket Creation Date : </strong>
                  {checkAvailability(restaurantdata?.ticketCreationDate)}
                </Typography>
              </Grid>



              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Phone sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Mobile Number : </strong>{" "}
                  {restaurantdata?.countryCode}{" "}
                  {checkAvailability(restaurantdata?.mobileNumber)}
                </Typography>
              </Grid>

{ parsedDatapermission?.supportEdit == 1 ?

<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
<Typography sx={{ display: "flex", alignItems: "center" }}>
  <Info sx={{ color: "var(--colorused)", mr: 1 }} />
  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}>Ticket Status : </strong>{" "}
  <CFormSelect
    name="status"
    label=""
    placeholder="Document Status"
    value={statusvalue}
    // defaultValue={restaurantdata?.documentStatus}
    onChange={handleselectchange}
  >
    {statusArray?.map((item, index) => (
      <option key={index} value={item.statusIdFk}>
        {item.supportTicketStatus}
      </option>
    ))}
  </CFormSelect>{" "}
</Typography>
</Grid> :""

}



              {
                restaurantdata?.file == null ? "" :

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Typography sx={{ display: "flex", mb: 1 }}>
                      <ImageIcon sx={{ color: "var(--colorused)", mr: 1 }} />
                      <strong style={{ whiteSpace: "nowrap" }}>File : </strong>{" "}

                      {/* <img height={80} width={80}  onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(restaurantdata?.file)}`)} src={`${ImageUrl}${checkAvailability(restaurantdata?.file)}`} alt=""
                    style={{ marginLeft: "10px" ,cursor:"pointer"}}
                  /> */}

                      {isPdf ? (
                        <a
                          href={filePath}
                          // target="_blank"
                          rel="noopener noreferrer"
                          style={{ cursor: 'pointer', display: 'block', width: '80px', textAlign: 'center' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          View PDF
                        </a>
                      ) : (
                        <img
                          src={filePath}
                          height={80}
                          width={80}
                          alt=""
                          onClick={() => handleOpenImage(filePath)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}


                    </Typography>
                  </Grid>
              }
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography sx={{ display: "flex", mb: 1, flexDirection: "column" }}>
                <div style={{ display: "flex", mb: 1 }}>
                  <Chat sx={{ color: "var(--colorused)", mr: 1 }} />
                  <strong style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                  >Message : </strong>{" "}
                </div>
                <CFormTextarea
                  className="mb-3 mt-2"
                  rows="8"
                  placeholder={checkAvailability(restaurantdata?.message)}
                  aria-label="Disabled textarea example"
                  disabled
                ></CFormTextarea>


              </Typography>
            </Grid>

          </Box>

{parsedDatapermission?.supportEdit == 1 ?
          <div className="chat-body">
            {showPopup && <div className="popup-message">Chat with us</div>}
            <AiFillMessage
              variant="contained"
              style={{ fontSize: "50px", color: `var(--colorused)` }}
              onClick={handleOpen}
              className="chatBtn"
            />

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"

            >
              <Box sx={style}
              >
                <div className="chat-content"
                >
                  <ChatBox
                    supportTicketIdFk={id}
                    ticketId={restaurantdata?.ticketId}
                    onClose={handleClose}
                  />
                </div>
              </Box>
            </Modal>
          </div> :"" }




        </>

      }



    </div>
  );
};

export default ViewSupport;
