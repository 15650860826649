import React from 'react'

import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Typography,
    CircularProgress,
  } from "@mui/material";

const Permissionui = ({values, handlePermissionChangenew}) => {

  return (
    
    <Grid container spacing={3}>




    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Vendors
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Live Vendors</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.liveVendorsview || values.liveVendorsedit}
                  disabled={values.liveVendorsedit}
                  onChange={() => handlePermissionChangenew(values.liveVendorsview, "liveVendorsview", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.liveVendorsedit}
                  onChange={(e) => handlePermissionChangenew(values.liveVendorsedit, "liveVendorsedit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Vendor Accounts</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.vendorsAccountsview || values.vendorsAccountsedit}
                  disabled={values.vendorsAccountsedit}
                  onChange={(e) => handlePermissionChangenew(values.vendorsAccountsview, "vendorsAccountsview", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.vendorsAccountsedit}
                  onChange={(e) => handlePermissionChangenew(values.vendorsAccountsedit, "vendorsAccountsedit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Vendor Documents</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.vendorsDocumentview || values.vendorsDocumentedit}
                  disabled={values.vendorsDocumentedit}
                  onChange={(e) => handlePermissionChangenew(values.vendorsDocumentview, "vendorsDocumentview", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.vendorsDocumentedit}
                  onChange={(e) => handlePermissionChangenew(values.vendorsDocumentedit, "vendorsDocumentedit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Admin User Management
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Admin Users</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminUserview || values.adminUseredit}
                  disabled={values.adminUseredit}
                  onChange={() => handlePermissionChangenew(values.adminUserview, "adminUserview", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminUseredit}
                  onChange={(e) => handlePermissionChangenew(values.adminUseredit, "adminUseredit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Role</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminRoleview || values.adminRoleedit}
                  disabled={values.adminRoleedit}
                  onChange={(e) => handlePermissionChangenew(values.adminRoleview, "adminRoleview", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminRoleedit}
                  onChange={(e) => handlePermissionChangenew(values.adminRoleedit, "adminRoleedit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Permissions</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminPermissionView || values.adminPermissionedit}
                  disabled={values.adminPermissionedit}
                  onChange={(e) => handlePermissionChangenew(values.adminPermissionView, "adminPermissionView", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.adminPermissionedit}
                  onChange={(e) => handlePermissionChangenew(values.adminPermissionedit, "adminPermissionedit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>

      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Location
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">Country</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.countryView || values.countryEdit}
                  disabled={values.countryEdit}
                  onChange={() => handlePermissionChangenew(values.countryView, "countryView", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.countryEdit}
                  onChange={(e) => handlePermissionChangenew(values.countryEdit, "countryEdit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">State</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.stateView || values.stateEdit}
                  disabled={values.stateEdit}
                  onChange={(e) => handlePermissionChangenew(values.stateView, "stateView", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.stateEdit}
                  onChange={(e) => handlePermissionChangenew(values.stateEdit, "stateEdit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography variant="subtitle1">City</Typography>
          <div className="view-edit-permission">
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.cityView || values.cityEdit}
                  disabled={values.cityEdit}
                  onChange={(e) => handlePermissionChangenew(values.cityView, "cityView", "view")}
                />
              }
              label="View"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.cityEdit}
                  onChange={(e) => handlePermissionChangenew(values.cityEdit, "cityEdit", "edit")}
                />
              }
              label="Add/Edit"
            />
          </div>
        </Grid>

      </Grid>
    </Grid>



    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Dashboard</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.dashboardView || values.dashboardEdit}
              disabled={values.dashboardEdit}
              onChange={(e) => handlePermissionChangenew(values.dashboardView, "dashboardView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.dashboardEdit}
              onChange={(e) => handlePermissionChangenew(values.dashboardEdit, "dashboardEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Users</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.usersView || values.usersEdit}
              disabled={values.usersEdit}
              onChange={(e) => handlePermissionChangenew(values.usersView, "usersView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.usersEdit}
              onChange={(e) => handlePermissionChangenew(values.usersEdit, "usersEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Orders</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.orderView || values.orderEdit}
              disabled={values.orderEdit}
              onChange={(e) => handlePermissionChangenew(values.orderView, "orderView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.orderEdit}
              onChange={(e) => handlePermissionChangenew(values.orderEdit, "orderEdit", "edit")}
            />
          }
          label="Downloads"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Billings</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.billingsView || values.billingsEdit}
              disabled={values.billingsEdit}
              onChange={(e) => handlePermissionChangenew(values.billingsView, "billingsView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.billingsEdit}
              onChange={(e) => handlePermissionChangenew(values.billingsEdit, "billingsEdit", "edit")}
            />
          }
          label="Downloads"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Restaurant Type</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.restaurantView|| values.restaurantEdit}
              disabled={values.restaurantEdit}
              onChange={(e) => handlePermissionChangenew(values.restaurantView, "restaurantView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.restaurantEdit}
              onChange={(e) => handlePermissionChangenew(values.restaurantEdit, "restaurantEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Magic Bag Size</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.magicbagSizeview || values.magicbagSizeedit}
              disabled={values.magicbagSizeedit}
              onChange={(e) => handlePermissionChangenew(values.magicbagSizeview, "magicbagSizeview", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.magicbagSizeedit}
              onChange={(e) => handlePermissionChangenew(values.magicbagSizeedit, "magicbagSizeedit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Cuisine</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.cuisineView || values.cuisneEdit}
              disabled={values.cuisneEdit}
              onChange={(e) => handlePermissionChangenew(values.cuisineView, "cuisineView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.cuisneEdit}
              onChange={(e) => handlePermissionChangenew(values.cuisneEdit, "cuisneEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Bank</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.bankView || values.bankEdit}
              disabled={values.bankEdit}
              onChange={(e) => handlePermissionChangenew(values.bankView, "bankView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.bankEdit}
              onChange={(e) => handlePermissionChangenew(values.bankEdit, "bankEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">FAQ</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.faqView || values.faqEdit}
              disabled={values.faqEdit}
              onChange={(e) => handlePermissionChangenew(values.faqView, "faqView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.faqEdit}
              onChange={(e) => handlePermissionChangenew(values.faqEdit, "faqEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Support</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.supportView || values.supportEdit}
              disabled={values.supportEdit}
              onChange={(e) => handlePermissionChangenew(values.supportView, "supportView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.supportEdit}
              onChange={(e) => handlePermissionChangenew(values.supportEdit, "supportEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>


    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Privacy Policy</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.privacyPolicyView || values.privacyPolicyEdit}
              disabled={values.privacyPolicyEdit}
              onChange={(e) => handlePermissionChangenew(values.privacyPolicyView, "privacyPolicyView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.privacyPolicyEdit}
              onChange={(e) => handlePermissionChangenew(values.privacyPolicyEdit, "privacyPolicyEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Terms and Conditions</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.termsandconditionView || values.termsandconditionEdit}
              disabled={values.termsandconditionEdit}
              onChange={(e) => handlePermissionChangenew(values.termsandconditionView, "termsandconditionView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.termsandconditionEdit}
              onChange={(e) => handlePermissionChangenew(values.termsandconditionEdit, "termsandconditionEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>

    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography variant="h6">Settings</Typography>
      <div className="view-edit-permission">
        <FormControlLabel
          control={
            <Checkbox
              checked={values.settingsView || values.settingsEdit}
              disabled={values.settingsEdit}
              onChange={(e) => handlePermissionChangenew(values.settingsView, "settingsView", "view")}
            />
          }
          label="View"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.settingsEdit}
              onChange={(e) => handlePermissionChangenew(values.settingsEdit, "settingsEdit", "edit")}
            />
          }
          label="Add/Edit"
        />
      </div>
    </Grid>







  </Grid>

  )
}

export default Permissionui
