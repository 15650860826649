
import { CircularProgress, Container, Grid } from '@mui/material';
import './index.css'
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { CButton, CCol, CRow } from '@coreui/react';
import CkEditor from '../../ckEditor';
import { create_setting_api, get_setting_api } from '../../API/baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { getsettingAction, removeDataFromReducer, settingAction } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import VendorPrivacy from './vendorprivacy';
import UserPrivacy from './userPrivacy';
import { PrivacyPolicyTitle } from '../Title';

const Intial = {
    data: '',
    type: ''
}

export default function Privacy() {
    const [ckValue, setCkValue] = useState("")
    const { settingdata } = useSelector((state) => state?.settingReduser || {});
    const { getsettingdata } = useSelector((state) => state?.getsettingReduser || {});
    const dispatch = useDispatch();
    

    const [editTerms, setEditTerms] = useState(true)
    const [loading, setLoading] = useState(false)

    const { setValues, handleBlur, handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
        initialValues: Intial,
        validationSchema: "",
        enableReinitialize: true,
        // onSubmit: () => handlePrivacyData(),
    });

    useEffect(() => {

      document.title = PrivacyPolicyTitle;
    }, []);
    const handlePrivacyData = async () => {
        setLoading(true)
        const parameter = {
            data: ckValue,
            type: "vendorPrivacyPolicy",
            url:create_setting_api,
        };
        dispatch(settingAction(parameter));
    }



    const ckChange = (text, type, key, index) => {
        values.privacyContent = type.getData()
        setCkValue(type.getData())
        setFieldValue("privacyContent", values.privacyContent)
    }


    
  useEffect(() => {
    if (settingdata?.status) {
      ToastMessage("success", settingdata?.message);
      dispatch(removeDataFromReducer("CREATE_SETTING_RESET"));
      setLoading(false); 
    } else {
      ToastMessage("error", settingdata?.message);
      setLoading(false); 
    }
  }, [settingdata]);


  useEffect(() => {
    if (getsettingdata?.status) {
        setCkValue(getsettingdata.data?.data)
      ToastMessage("success", getsettingdata?.message);
      setLoading(false); 
    } else {
    //   ToastMessage("error", getsettingdata?.message);
      setLoading(false); 
    }
  }, [getsettingdata]);

    


  useEffect(() => {
    const parameter = {
        type: "vendorPrivacyPolicy",
        url:get_setting_api,
    };
    dispatch(getsettingAction(parameter));
  }, []);


    const handleEditTerms = () => {
        setEditTerms(false)
        // setFieldValue("privacyContent", values.privacyContent)
    }

    // const handleUpdateTerms = () => {
    //     setEditTerms(true)
    //     setFieldValue("privacyContent", values.privacyContent)
    //     handleSubmit()
    // }


    const privacyGet = async () => {
        // const id = 1
        // try {
        //     const result = await getApiCallToken(`${getTermsandCondition}${id}`)
        //     setCkValue(result.data.data.privacy_policy)
        // } catch (error) {
        //     console.log("errors", error);
        // }
    }


    useEffect(() => {
        privacyGet()
    }, [])



    const toolbarConfig = {

        toolbar: [
            'heading',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
        ],
    };



    const datapermission = localStorage.getItem("permissiondata")


    const parsedDatapermission = JSON.parse(datapermission);


    return (
        <div className='dashboardclass'>

            <Grid item xs={12} md={12} sx={{ padding: "5px 25px" }}>

            <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-2"
            lg={7}
            md={6}
            sm={4}
            xs={12}
          >
            <h3>Privacy Policy</h3>
          </CCol>
        </CRow>

        <Tabs style={{ }} >
                    <TabList className="TabList">
                        <Tab className="Tab">
                           Vendor
                        </Tab>
                        <Tab className="Tab">Customer</Tab>
                    </TabList>
                    <TabPanel>
                        <VendorPrivacy showupdate={parsedDatapermission?.privacyPolicyEdit == 1 ? true : false}  />
                    </TabPanel>
                    <TabPanel>
                      <UserPrivacy  showupdate={parsedDatapermission?.privacyPolicyEdit == 1 ? true : false} />
                    </TabPanel>
                   
         </Tabs>
            </Grid>
        </div>
    );
}
