import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, FormControlLabel, MenuItem, Select, styled, Switch } from "@mui/material";
import {
  bagSizeList_api,
  getstatelist_api,
  resturant_type_active_api,
  sizeStatus_api,
  typeList_api,
} from "../../API/baseUrl";
import {
  bagSizelist,
  bagsizeStatus,
  removeDataFromReducer,
  typeactivelist,
  typelist,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import { MagicBagSizeTitle, RestaurantTypeTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const SurpriceBagSize = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [filterDropdownData, setFilterDropdownData] = useState([]);
  const dispatch = useDispatch();
  const [Filter, setFilter] = useState(0)
  const { surpricebagListdata } = useSelector(
    (state) => state?.bagSizeListReduser || {}
  );
  const { bagsizestatusdata } =
    useSelector((state) => state?.bagsizeStatusReduser) || {};
    useEffect(() => {

      document.title = MagicBagSizeTitle;
    }, []);
  

  const { RestaurantactiveTypeData } = useSelector(
    (state) => state?.activetypeListReduser || {}
  );

  useEffect(() => {
    if (surpricebagListdata?.status) {
      setData(surpricebagListdata?.data);
      setTotalRows(surpricebagListdata?.count);
      setPending(false);
    } else {
      ToastMessage("error", surpricebagListdata?.message);
    }
  }, [surpricebagListdata]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      filterOption: Filter,
      url: bagSizeList_api,
      // url:sizeStatus_api
    };
    dispatch(bagSizelist(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy,Filter]);

  const handleAdd = (e) => {
    dispatch(removeDataFromReducer("BAGSIZE_UPDATE_RESET"));
    dispatch(removeDataFromReducer("BAGSIZE_DETAILS_RESET"));
    navigate("/dashboard/bagsize/add");
  };


  

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));
  

  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.surpriseBagSizeIdFK,
      sortable: true,
      column_name: "surpriseBagSizeIdFK",
      reorder: true,
    },
    {
      name: "Bag Size",
      width: "180px",
      selector: (row) => row.surpriseBagSize,
      sortable: true,
      column_name: "surpriseBagSize",
      reorder: true,
    },
    {
      name: "Commission",
      width: "180px",
      selector: (row) => row.commission,
      sortable: true,
      column_name: "commission",
      reorder: true,
    },
    {
      name: "Retail Price",
      width: "180px",
      // selector: (row) => row.surpriseBagRetailPrice,
      selector: (row) => `₹${row?.surpriseBagRetailPrice || "---"}`,
      sortable: true,
      column_name: "surpriseBagRetailPrice",
      reorder: true,
    },
    {
      name: "Selling Price",
      width: "180px",
      // selector: (row) => row.surpriseBagSellingPrice,
      selector: (row) => `₹${row?.surpriseBagSellingPrice || "---"}`,
      sortable: true,
      column_name: "surpriseBagSellingPrice",
      reorder: true,
    },
    {
      name: "Discount (in %)",
      width: "180px",
      selector: (row) =>  row?.discount || "0" ,
      sortable: true,
      column_name: "discount",
      reorder: true,
    },
    {
      name: "Restaurant Type",
      width: "250px",
      selector: (row) => row.restaurantType || "-",
      sortable: true,
      column_name: "restaurantType",
      reorder: true,
    },
    
    {
      name: "Restaurant Id",
      width: "250px",
      selector: (row) => row.restaurantId || "-",
      sortable: true,
      column_name: "restaurantId",
      reorder: true,
    },
    {
      name: "Restaurant Name",
      width: "250px",
      selector: (row) => row.restaurantName || "-",
      sortable: true,
      column_name: "restaurantName",
      reorder: true,
    },
    {
      name: "Restaurant Email",
      width: "250px",
      selector: (row) => row.restaurantEmail || "-",
      sortable: true,
      column_name: "restaurantEmail",
      reorder: true,
    },
    {
      name: " Status",

      selector: (row) => (

        parsedDatapermission?.magicbagSizeedit == 1 ?
          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={row.isActive}
                checked={row.isActive == '1'}
                onChange={() => handleStatus(row.surpriseBagSizeIdFK)}
              />
            }
            label={row.isActive === 1 ? "Active" : "Inactive"}
          /> : row.isActive === 1 ? "Active" : "Inactive"


      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },



    // {
    //   name: "Status",
    //   width: "250px",
    //   selector: (row) => (
    //     parsedDatapermission?.magicbagSizeedit == 1 ?
    //     <div className="switch_row">
    //     <CFormSwitch
    //       onClick={() => handleStatus(row.surpriseBagSizeIdFK)}
    //       label=""
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //         color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }}
    //     />
    //     <p>{row.isActive === 1 ? "Active" : "Inactive"}</p>
    //     </div>
        
    //      : row.isActive === 1 ? "Active" : "Inactive"
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },

    ...(parsedDatapermission?.magicbagSizeedit == 1
      ? [ 
        {
          name: "Edit",
          selector: (row) => (
            <button
              className="edit_btn"
              onClick={() => handleEdit(row.surpriseBagSizeIdFK)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Edit
            </button>
          ),
          // sortable: true,
          column_name: "",
          reorder: true,
        },
        ]
      : []),
   
  ];

  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("BAGSIZE_DETAILS_RESET"));
    navigate(`/dashboard/bagsize/add/${id}`);
  };

  const handleStatus = (id) => {
    const parameter = {
      url: `${sizeStatus_api}${id}`,
    };
    dispatch(bagsizeStatus(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: bagSizeList_api,
      filterOption: Filter,
      // url:sizeStatus_api
    };
    if (bagsizestatusdata?.status) {
      ToastMessage("success", bagsizestatusdata?.message);
      // alert("ok")
      dispatch(bagSizelist(parameter));
      dispatch(removeDataFromReducer("BAGSIZE_STATUS_RESET"));
      // setPending(false);
    } else {
      ToastMessage("error", bagsizestatusdata?.message);
    }
  }, [bagsizestatusdata]);

  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };



  const handleclearSearch = () => {
    setSearchText("");
  };



  useEffect(() => {
    if (RestaurantactiveTypeData?.status) {
      setFilterDropdownData(RestaurantactiveTypeData?.data);
      console.log("filterDropdownData", filterDropdownData);
    } else {
      ToastMessage("error", RestaurantactiveTypeData?.message);
    }
  }, [RestaurantactiveTypeData]);

  useEffect(() => {
    const parameter = {
      url: resturant_type_active_api,
    };
    dispatch(typeactivelist(parameter));
  }, []);



  const handleChangePeriod = (event) => {
    const selectedItem = event.target.value;
    // setSearchText(selectedItem.type);
    setFilter(selectedItem.restaurantTypeIdFk)
    console.log("Selected restaurantTypeIdFk:", selectedItem.restaurantTypeIdFk);

  };


  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap:"wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Magic Bag Size</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>

          {parsedDatapermission?.magicbagSizeedit == 1 ? 
              <CButton
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              className="me-md-2 btn_add_country"
              onClick={handleAdd}
            // disabled={isLoading}
            >
              Add New
            </CButton> : "" }
      
        </div>
      </div>

      <div className="periodDivClass">
        <p>Filter</p>
        <div className="periodDivClassDropdown">
          <FormControl sx={{ m: 1, width: 300 }}>
            {/* <Select
              labelId="demo-simple-select-label"
            // value={filter}
            onChange={handleChangePeriod}
            >

              {filterDropdownData?.map((item, index) => (
                <MenuItem key={index} value={item.type}>{item.type}</MenuItem>
              ))}

            </Select> */}

            <Select
              labelId="demo-simple-select-label"

              onChange={handleChangePeriod}
            >
              {filterDropdownData?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default SurpriceBagSize;
