import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { CButton, CRow, CCol } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { live_vendorList_api, user_active_inactive, User_api, vendorActive_deactive_api, vendorList_api } from "../../API/baseUrl";
import { countryStatusRequest, removeDataFromReducer, UserList, vendorList } from "../../redux/actions";
import moment from 'moment/moment';
import ClearIcon from '@mui/icons-material/Clear';
import { TableSk } from "../skelaton/tableSk";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import { UsersTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";


const User = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { vendorListInfo } = useSelector((state) => state?.vendorList || {});
  const { countrystatusdata } =
    useSelector((state) => state?.countrystatus) || {};


  const handleView = (id) => {
    dispatch(removeDataFromReducer("RESTAURANT_VIEW_RESET"));
    navigate(`/dashboard/userView/${id}`);
  }

  useEffect(() => {

    document.title = UsersTitle;
  }, []);

  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };



  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));



  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);




  const columns = [
    {
      name: "Id",
      width: "180px",
      selector: (row) => <p className="id_navigate" onClick={() => handleView(row?.userIdFk)}>
        {row?.userIdFk ? `UID00${row.userIdFk}` : "---"}

      </p>,
      sortable: true,
      column_name: "userIdFk",
      reorder: true,

    },
    {
      name: "Name",
      width: "250px",
      selector: (row) => row.userName ? row.userName : "---",
      sortable: true,
      column_name: "UserName",
      reorder: true,
    },
    {
      name: "User Mobile Number",
      width: "250px",
      selector: (row) => row.userMobileNumber ? <p className="margin-0">{row.userCountryCode} {row.userMobileNumber}</p> : "---",
      // sortable: true,
      column_name: "UserMobileNumber",
      reorder: true,
    },

    {
      name: "User Email",
      width: "250px",
      selector: (row) => row.userEmail ? row.userEmail : "---",
      // sortable: true,
      column_name: "userEmail",
      reorder: true,
    },
    {
      name: 'Created Date',
      selector: row => row.createdAt ,
      format: (row) =>row.createdAt ? moment(row.createdAt).format('MMMM Do YYYY') :"---",
      sortable: true,
      column_name: 'createdAt',
      reorder: true,
      // width: "150px",
    },

    {
      name: " Status",

      selector: (row) => (
        parsedDatapermission?.usersEdit == 1 ?
        <FormControlLabel
          className='form_control_lable'
          control={
            <PinkSwitch
              size="small"
              defaultChecked={row.isActive}
              checked={row.isActive == '1'}
              onChange={() => handleStatus(row.userIdFk)}
            />
          }
          label={row.isActive === 1 ? "Active" : "Inactive"}
        /> :  row.isActive === 1 ? "Active" : "Inactive"
      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },

  ];



  const handleStatus = (id) => {
    const parameter = {
      url: `${user_active_inactive}${id} `,
    };
    dispatch(countryStatusRequest(parameter));
  };


  // useEffect(() => {




  //   if (vendorListInfo?.status) {
  //     setData(vendorListInfo?.data);
  //     setTotalRows(vendorListInfo?.count);
  //     setPending(false);
  //   } else {
  //     ToastMessage("error", vendorListInfo?.message);
  //   }
  // }, [vendorListInfo]);


  useEffect(() => {
    if (vendorListInfo) {
      setPending(true); 
      const timer = setTimeout(() => {
        setPending(false);
        if (vendorListInfo?.status) {
          setData(vendorListInfo?.data);
          setTotalRows(vendorListInfo?.count);
          setPending(false);
        } else {
          ToastMessage("error", vendorListInfo?.message);
        }
      }, 200); 
      return () => clearTimeout(timer);
    }
  }, [vendorListInfo]);


  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: User_api,
    };
    dispatch(UserList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: User_api,
    };
    if (countrystatusdata?.status) {
      ToastMessage("success", countrystatusdata?.message);
      dispatch(vendorList(parameter))
      dispatch(removeDataFromReducer("COUNTRY_STATUS_RESET"));
    } else {
      ToastMessage("error", countrystatusdata?.message);
    }
  }, [countrystatusdata]);


  return (
    <div>


      <div>
      
        <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
            lg={9}
            md={9}
            sm={8}
            xs={12}
          >
            <h3>Users</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
            lg={3}
            md={3}
            sm={3}
            xs={7}>
            <div className="search-feild">
              <input
                type="text"
                placeholder="Search"

                style={{ outline: 'none' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText ? <ClearIcon onClick={handleclearSearch} sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }} /> : ""}

            </div>
          </CCol>

        </CRow>
      </div>

      {/* </div> */}

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        pagination
        progressPending={pending}
        progressComponent={<LoaderAnimation/>}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default User;
