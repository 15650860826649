import { all } from 'redux-saga/effects';
import {

  accountStatusSaga,
  activecountrySaga,
  activestatelistSaga,
  addFaqSaga,
  bagsizeAddSaga,
  bagsizedetailsSaga,
  bagsizeeditSaga,
  bagsizelistSaga,
  bagsizeStatusSaga,
  cityaddSaga,
  cityDetailsSaga,
  cityeditSaga,
  cityListSaga,
  citystatusSaga,
  countryaddSaga,
  countryDetailsSaga,
  countryeditSaga,
  countryListSaga,
  countrystatusSaga,
  documentListSaga,
  documentViewSaga,
  FaqlistSaga,
  createorderlistSaga,
  FaqtypeSaga,
  getsettingSaga,
  getSupportStatusSaga,
  loginSaga,
  orderListSaga,
  restraurantTypeListSaga,
  restraurantviewSaga,
  sendemailSaga,
  settingSaga,
  specialityaddSaga,
  specialitydetailsSaga,
  specialityeditSaga,
  specialitySaga,
  specialitystatusSaga,
  stateaddSaga,
  stateDetailsSaga,
  stateeditSaga,
  stateListSaga,
  statestatusSaga,
  supportlistSaga,
  typeAddSaga,
  typedetailsSaga,
  typeeditSaga,
  typelistSaga,
  typeStatusSaga,
  vendorListSaga,
  supportStatusSaga,
  sendChatSaga,
  getChatSaga,
  ExcleDownloadOrderSaga,
  DashboardSaga,
  userListSaga,
  userviewSaga,
  CommissionSaga,
  activerestaurantTypeSaga,
  roledropdownSaga,
  permissionUpdateSaga,
  permissionGetSaga,
  updateadminSaga,
  permissionGetValueSaga,
  EmailOtpSaga,
  uploadimageSaga,
  notificationSagas,
  banklistSaga,
  documentgetSaga,
  notificationreadSagas,
  notificationCountSagas,
  changepasswordSagas,
  emptytokenSagas,
  resturantlistSaga
} from './Sagafile';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    permissionGetValueSaga(),
    notificationSagas(),
    updateadminSaga(),
    permissionGetSaga(),
    permissionUpdateSaga(),
    roledropdownSaga(),
    DashboardSaga(),
    ExcleDownloadOrderSaga(),
    sendChatSaga(),
    activerestaurantTypeSaga(),
    getChatSaga(),
    supportStatusSaga(),
    settingSaga(),
    getsettingSaga(),
    getSupportStatusSaga(),
    supportlistSaga(),
    FaqlistSaga(),
    createorderlistSaga(),
    FaqtypeSaga(),
    sendemailSaga(),
    countryListSaga(),
    stateListSaga(),
    cityListSaga(),
    countrystatusSaga(),
    statestatusSaga(),
    citystatusSaga(),
    countryaddSaga(),
    stateaddSaga(),
    cityaddSaga(),
    activecountrySaga(),
    specialitySaga(),
    specialityaddSaga(),
    specialitystatusSaga(),
    activestatelistSaga(),
    vendorListSaga(),
    restraurantviewSaga(),
    restraurantTypeListSaga(),
    accountStatusSaga(),
    cityDetailsSaga(),
    stateDetailsSaga(),
    countryDetailsSaga(),
    cityeditSaga(),
    stateeditSaga(),
    countryeditSaga(),
    documentListSaga(),
    documentViewSaga(),
    bagsizeAddSaga(),
    bagsizelistSaga(),
    typeAddSaga(),
    typelistSaga(),
    bagsizeStatusSaga(),
    typeStatusSaga(),
    specialityeditSaga(),
    specialitydetailsSaga(),
    bagsizedetailsSaga(),
    bagsizeeditSaga(),
    typedetailsSaga(),
    typeeditSaga(),
    orderListSaga(),
    addFaqSaga(),
    userListSaga(),
    userviewSaga(),
    CommissionSaga(),
    EmailOtpSaga(),
    uploadimageSaga(),
    banklistSaga(),
    documentgetSaga(),
    notificationreadSagas(),
    notificationCountSagas(),
    changepasswordSagas(),
    emptytokenSagas(),
    resturantlistSaga(),
  ]);
}
