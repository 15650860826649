import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import {
  bank_inactive_api,
  BankList_api,
  getstatelist_api,
  typeList_api,
  typestatus_api,
} from "../../API/baseUrl";
import {
  removeDataFromReducer,
  typelist,
  typestatus,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import { RestaurantTypeTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { FormControlLabel, styled, Switch } from "@mui/material";

const BankList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { RestaurantTypeData } = useSelector(
    (state) => state?.typeListReduser || {}
  );
  const { typestatusdata } =
    useSelector((state) => state?.typestatusReduser) || {};
    useEffect(() => {

      document.title = RestaurantTypeTitle;
    }, []);
  
  useEffect(() => {
    if (RestaurantTypeData?.status) {
      setData(RestaurantTypeData?.data);
      setTotalRows(RestaurantTypeData?.count);
      setPending(false);
    } else {
      ToastMessage("error", RestaurantTypeData?.message);
    }
  }, [RestaurantTypeData]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: BankList_api,
    };
    dispatch(typelist(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleAdd = (e) => {
    navigate("/dashboard/bank/add");
  };


  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));
  

  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);


  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      column_name: "id",
      reorder: true,
    },
    {
      name: "bank",
      selector: (row) => row.bank,
      sortable: true,
      column_name: "bank",
      reorder: true,
    },


    {
      name: " Status",

      selector: (row) => (

        parsedDatapermission?.bankEdit == 1 ?
          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={row.isActive}
                checked={row.isActive == '1'}
                onChange={() => handleStatus(row.id)}
              />
            }
            label={row.isActive === 1 ? "Active" : "Inactive"}
          /> : row.isActive === 1 ? "Active" : "Inactive"


      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },


    // {
    //   name: "Status",
    //   selector: (row) => (
    //     parsedDatapermission?.bankEdit == 1 ?
    //     <CFormSwitch
    //       onClick={() => handleStatus(row.id)}
    //       label={row.isActive === 1 ? "Active" : "Inactive"}
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //         color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }
    //       }
    //     /> : row.isActive === 1 ? "Active" : "Inactive"
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },

    
    ...(parsedDatapermission?.bankEdit == 1
      ? [      
        {
          name: "Edit",
          selector: (row) => (
            <button className="edit_btn" onClick={() => handleEdit(row.id)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Edit
            </button>
          ),
          // sortable: true,
          column_name: "",
          reorder: true,
        },
        ]
      : []),

   
  ];


  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("TYPE_DETAILS_RESET"));
    navigate(`/dashboard/bank/add/${id}`);
  };



  const handleStatus = (id) => {
    const parameter = {
      url: `${bank_inactive_api}${id}`,
    };
    dispatch(typestatus(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: BankList_api,
    };
    if (typestatusdata?.status) {
      ToastMessage("success", typestatusdata?.message);
      dispatch(removeDataFromReducer("TYPE_STATUS_RESET"));
      dispatch(typelist(parameter));
      // setPending(false);
    } else {
      ToastMessage("error", typestatusdata?.message);
    }
  }, [typestatusdata]);

  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };

  return (

    <div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap:"wrap",
        }}

      >
        <div>
          <h3 style={{ margin: 0 }}>Bank List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"

              style={{ outline: 'none' }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? <ClearIcon onClick={handleclearSearch} sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }} /> : ""}



          </div>
{parsedDatapermission?.bankEdit == 1 ?
  <CButton
  style={{
    backgroundColor: "var(--colorused)",
    border: "none",
    color: "var(--colorWhite)",
    fontSize: "13px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }}
  className="me-md-2 btn_add_country"
  onClick={handleAdd}
// disabled={isLoading}
>
  Add New
</CButton> :""}


        

        </div>

      </div>



      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default BankList;
