// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import "./index.css";
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CForm,
//   CRow,
// } from "@coreui/react";
// import {
//   FormControl,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   OutlinedInput,
// } from "@mui/material";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import Visibility from "@mui/icons-material/Visibility";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { useFormik } from "formik";
// import { EmailOtp_api, permission_get_api, signIn_api } from "../../../API/baseUrl";
// import { loginschema } from "../../../utils/schema";
// import { useDispatch, useSelector } from "react-redux";
// import { EmailOtp, loginUser, permissiongetaction } from "../../../redux/actions";
// import ToastMessage from "../../../utils/ToastMessage";
// import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress from Material-UI
// import Logo from "../../../components/logo";
// import OtpInput from 'react-otp-input';
// const Intial = {
//   email: "",
//   password: "",
// };
// const OtpScreen = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { loginInfo } = useSelector((state) => state?.loginUser || {});
//   const [otp, setOtp] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const EmailId = localStorage.getItem("email")
//   const userIdFk = localStorage.getItem("userIdFk")
//   console.log("EmailId", EmailId)
//   const {
//     handleChange,
//     handleSubmit,
//     setFieldValue,
//     handleBlur,
//     values,
//     errors,
//     touched,
//   } = useFormik({
//     initialValues: Intial,
//     validationSchema: "",
//     onSubmit: (values) => handleLogin(values),
//   });
//   const [timeLeft, setTimeLeft] = useState(60);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
//     }, 2000);

//     return () => { clearInterval(timer); };
//   }, []);

//   const minutes = Math.floor(timeLeft / 60);
//   const seconds = timeLeft % 60;



//   const handleLogin = () => {

//   }

//   const VerifyEmail = async () => {
//     const parameter = {
//       email: EmailId,
//       userIdFk:userIdFk,

//       url: `${EmailOtp_api}`,
//     };
//     dispatch(EmailOtp(parameter));
//   };



//   return (



//     <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bglogin">
//       <CContainer>
//         <CRow className="justify-content-center login_containrr">
//           <CCol md={6} sx={12} sm={10}>
//             <CCardGroup>
//               <CCard className="p-2">
//                 <CCardBody>
//                   <CForm onSubmit={handleSubmit} className="otp_screen_main_container">
//                     <Logo />

//                     <div className="Otp_form_box" id="">


//                       <h3 className="otpmsg">
//                         Check your inbox for OTP**
//                       </h3>


//                       <OtpInput
//                         skipDefaultStyles="true"
//                         inputStyle='otp_input_type'
//                         value={otp}
//                         onChange={setOtp}
//                         numInputs={6}
//                         renderSeparator={<span>-</span>}
//                         renderInput={(props) => <input {...props} />}
//                       />

//                       <div className="verification">
//                         <p className='otpverify_resend'> {minutes === 0 && seconds === 0 ? "" :
//                           <p className='otpverify_resend'>
//                             0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
//                           </p>
//                         }
//                         </p>

//                         <p className='otpverify_resend_btn'> Not received OTP ?
//                           <button
//                             // onClick={handleresend}
//                             className='otpverify_resend_btn' type='button' disabled={minutes !== 0 || seconds !== 0}

//                             style={{
//                               cursor: (minutes === 0 && seconds === 0) ? "pointer" : "no-drop",
//                               color: (minutes === 0 && seconds === 0) ? "green" : "grey",
//                             }}
//                           > Resend OTP</button></p>


//                       </div>
//                       <br />

//                     </div>


//                   </CForm>
//                 </CCardBody>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>

//   );
// };

// export default OtpScreen;




import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import {
  CContainer,
  CRow,
  CCol,
  CCardGroup,
  CCard,
  CCardBody,
  CForm,
} from "@coreui/react";
import OtpInput from "react-otp-input";
import Logo from "../../../components/logo";
import { useDispatch, useSelector } from "react-redux";
import { EmailOtp_api } from "../../../API/baseUrl";
import { EmailOtp } from "../../../redux/actions";
import ToastMessage from "../../../utils/ToastMessage";

const OtpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { EmailOtpData } = useSelector((state) => state?.EmailOtpReduser || {});
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const EmailId = localStorage.getItem("email");
  const userIdFk = localStorage.getItem("userIdFk");
  const { permissionGetData } = useSelector((state) => state?.permissionGetReduser) || {};



  useEffect(() => {
    if (permissionGetData?.status) {
      // console.log(permissionGetData?.data?.adminPermissionView, "permissionGetData")  
      // localStorage.setItem("auth", true);
      // localStorage.setItem("accessToken", permissionGetData?.accessToken);

      // localStorage.setItem("refreshToken", permissionGetData?.refreshToken);
      localStorage.setItem("permissiondata", JSON.stringify(permissionGetData?.data));
    } else {
      ToastMessage("error", permissionGetData?.message);
    }
  }, [permissionGetData]);




  useEffect(() => {
    if (EmailOtpData?.status) {
      localStorage.setItem("auth", true);
      localStorage.setItem("accessToken", EmailOtpData?.accessToken);
      localStorage.setItem("refreshToken", EmailOtpData?.refreshToken);
      ToastMessage("success", EmailOtpData?.message);
      navigate("/dashboard/app");
    } else {
      ToastMessage("error", EmailOtpData?.message);
    }
  }, [EmailOtpData]);



  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (otp.length === 4) {
      VerifyEmail(otp);
    }
  }, [otp]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  // Verify the email OTP
  const VerifyEmail = async (otpValue) => {
    const parameter = {
      email: EmailId,
      userIdFk: Number(userIdFk),
      Otp: otpValue,
      url: `${EmailOtp_api}`,
    };
    dispatch(EmailOtp(parameter)); // Dispatch the action with OTP
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bglogin">
      <CContainer>
        <CRow className="justify-content-center login_containrr">
          <CCol md={6} sx={12} sm={10}>
            <CCardGroup>
              <CCard className="p-2">
                <CCardBody>
                  <CForm className="otp_screen_main_container">
                    <Logo />
                    <div className="Otp_form_box">
                      <h3 className="otpmsg">The OTP has been sent to the designated recipient. Please contact them to proceed.</h3>

                      <OtpInput
                        skipDefaultStyles={true}
                        inputStyle="otp_input_type"
                        value={otp}
                        onChange={setOtp} // Update OTP state
                        numInputs={4} // Limit to 4 digits
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus // Automatically focus the first input
                        isInputNum // Ensure input is numeric
                      />

                      {/* <div className="verification">
                        <p className="otpverify_resend">
                          {minutes === 0 && seconds === 0 ? (
                            ""
                          ) : (
                            <p className="otpverify_resend">
                              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          )}
                        </p>

                        <p className="otpverify_resend_btn">
                          Not received OTP?
                          <button
                            className="otpverify_resend_btn"
                            type="button"
                            disabled={minutes !== 0 || seconds !== 0}
                            style={{
                              cursor: minutes === 0 && seconds === 0 ? "pointer" : "no-drop",
                              color: minutes === 0 && seconds === 0 ? "green" : "grey",
                            }}
                          >
                            Resend OTP
                          </button>
                        </p>
                      </div> */}
                      <br />
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default OtpScreen;
