export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_RESET = "LOGIN_USER_RESET";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export const COUNTRY_LIST_REQUEST = "COUNTRY_LIST_REQUEST";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_ERROR = "COUNTRY_LIST_ERROR";
export const COUNTRY_LIST_RESET = "COUNTRY_LIST_RESET";

export const STATE_LIST_REQUEST = "STATE_LIST_REQUEST";
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_ERROR = "STATE_LIST_ERROR";
export const STATE_LIST_RESET = "STATE_LIST_RESET";

export const CITY_LIST_REQUEST = "CITY_LIST_REQUEST";
export const CITY_LIST_SUCCESS = "CITY_LIST_SUCCESS";
export const CITY_LIST_ERROR = "CITY_LIST_ERROR";
export const CITY_LIST_RESET = "CITY_LIST_RESET";

export const COUNTRY_STATUS_REQUEST = "COUNTRY_STATUS_REQUEST";
export const COUNTRY_STATUS_SUCCESS = "COUNTRY_STATUS_SUCCESS";
export const COUNTRY_STATUS_ERROR = "COUNTRY_STATUS_ERROR";
export const COUNTRY_STATUS_RESET = "COUNTRY_STATUS_RESET";

export const ACTIVETYPE_LIST_REQUEST = "ACTIVETYPE_LIST_REQUEST";
export const ACTIVETYPE_LIST_SUCCESS = "ACTIVETYPE_LIST_SUCCESS";
export const ACTIVETYPE_LIST_ERROR = "ACTIVETYPE_LIST_ERROR";
export const ACTIVETYPE_LIST_RESET = "ACTIVETYPE_LIST_RESET";

export const STATE_STATUS_REQUEST = "STATE_STATUS_REQUEST";
export const STATE_STATUS_SUCCESS = "STATE_STATUS_SUCCESS";
export const STATE_STATUS_ERROR = "STATE_STATUS_ERROR";
export const STATE_STATUS_RESET = "STATE_STATUS_RESET";

export const CITY_STATUS_REQUEST = "CITY_STATUS_REQUEST";
export const CITY_STATUS_SUCCESS = "CITY_STATUS_SUCCESS";
export const CITY_STATUS_ERROR = "CITY_STATUS_ERROR";
export const CITY_STATUS_RESET = "CITY_STATUS_RESET";

export const ADD_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_ERROR = "ADD_COUNTRY_ERROR";
export const ADD_COUNTRY_RESET = "ADD_COUNTRY_RESET";

export const ADD_STATE_REQUEST = "ADD_STATE_REQUEST";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_ERROR = "ADD_STATE_ERROR";
export const ADD_STATE_RESET = "ADD_STATE_RESET";

export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_ERROR = "ADD_CITY_ERROR";
export const ADD_CITY_RESET = "ADD_CITY_RESET";

export const EDIT_COUNTRY_REQUEST = "EDIT_COUNTRY_REQUEST";
export const EDIT_COUNTRY_SUCCESS = "EDIT_COUNTRY_SUCCESS";
export const EDIT_COUNTRY_ERROR = "EDIT_COUNTRY_ERROR";

export const EDIT_STATE_REQUEST = "EDIT_STATE_REQUEST";
export const EDIT_STATE_SUCCESS = "EDIT_STATE_SUCCESS";
export const EDIT_STATE_ERROR = "EDIT_STATE_ERROR";

export const EDIT_CITY_REQUEST = "EDIT_CITY_REQUEST";
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_CITY_ERROR = "EDIT_CITY_ERROR";

export const ACTIVE_COUNTRYLIST_REQUEST = "ACTIVE_COUNTRYLIST_REQUEST";
export const ACTIVE_COUNTRYLIST_SUCCESS = "ACTIVE_COUNTRYLIST_SUCCESS";
export const ACTIVE_COUNTRYLIST_ERROR = "ACTIVE_COUNTRYLIST_ERROR";

export const SPECIALITY_LIST_REQUEST = "SPECIALITY_LIST_REQUEST";
export const SPECIALITY_LIST_SUCCESS = "SPECIALITY_LIST_SUCCESS";
export const SPECIALITY_LIST_ERROR = "SPECIALITY_LIST_ERROR";

export const SPECIALITY_LIST_RESET = "SPECIALITY_LIST_RESET";

export const ADD_SPECIALITY_REQUEST = "ADD_SPECIALITY_REQUEST";
export const ADD_SPECIALITY_SUCCESS = "ADD_SPECIALITY_SUCCESS";
export const ADD_SPECIALITY_ERROR = "ADD_SPECIALITY_ERROR";
export const ADD_SPECIALITY_RESET = "ADD_SPECIALITY_RESET";

export const SPECIALITY_STATUS_REQUEST = "SPECIALITY_STATUS_REQUEST";
export const SPECIALITY_STATUS_SUCCESS = "SPECIALITY_STATUS_SUCCESS";
export const SPECIALITY_STATUS_ERROR = "SPECIALITY_STATUS_ERROR";
export const SPECIALITY_STATUS_RESET = "SPECIALITY_STATUS_RESET";

export const ACTIVE_STATELIST_REQUEST = "ACTIVE_STATELIST_REQUEST";
export const ACTIVE_STATELIST_SUCCESS = "ACTIVE_STATELIST_SUCCESS";
export const ACTIVE_STATELIST_ERROR = "ACTIVE_STATELIST_ERROR";


export const VENDOR_LIST_REQUEST = "VENDOR_LIST_REQUEST";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_ERROR = "VENDOR_LIST_ERROR";
export const VENDOR_LIST_RESET = "VENDOR_LIST_RESET";

export const RESTAURANT_VIEW_REQUEST = "RESTAURANT_VIEW_REQUEST";
export const RESTAURANT_VIEW_SUCCESS = "RESTAURANT_VIEW_SUCCESS";
export const RESTAURANT_VIEW_ERROR = "RESTAURANT_VIEW_ERROR";


export const RESTAURANT_TYPELIST_REQUEST = "RESTAURANT_TYPELIST_REQUEST";
export const RESTAURANT_TYPELIST_SUCCESS = "RESTAURANT_TYPELIST_SUCCESS";
export const RESTAURANT_TYPELIST_ERROR = "RESTAURANT_TYPELIST_ERROR";

export const ACCOUNT_STATUS_REQUEST = "ACCOUNT_STATUS_REQUEST";
export const ACCOUNT_STATUS_SUCCESS = "ACCOUNT_STATUS_SUCCESS";
export const ACCOUNT_STATUS_ERROR = "ACCOUNT_STATUS_ERROR";
export const ACCOUNT_STATUS_RESET = "ACCOUNT_STATUS_RESET";

export const COUNTRY_DETAILS_REQUEST = "COUNTRY_DETAILS_REQUEST";
export const COUNTRY_DETAILS_SUCCESS = "COUNTRY_DETAILS_SUCCESS";
export const COUNTRY_DETAILS_ERROR = "COUNTRY_DETAILS_ERROR";
export const COUNTRY_DETAILS_RESET = "COUNTRY_DETAILS_RESET";

export const STATE_DETAILS_REQUEST = "STATE_DETAILS_REQUEST";
export const STATE_DETAILS_SUCCESS = "STATE_DETAILS_SUCCESS";
export const STATE_DETAILS_ERROR = "STATE_DETAILS_ERROR";
export const STATE_DETAILS_RESET = "STATE_DETAILS_RESET";

export const CITY_DETAILS_REQUEST = "CITY_DETAILS_REQUEST";
export const CITY_DETAILS_SUCCESS = "CITY_DETAILS_SUCCESS";
export const CITY_DETAILS_ERROR = "CITY_DETAILS_ERROR";
export const CITY_DETAILS_RESET = "CITY_DETAILS_RESET";

export const CITY_EDIT_REQUEST = "CITY_EDIT_REQUEST";
export const CITY_EDIT_SUCCESS = "CITY_EDIT_SUCCESS";
export const CITY_EDIT_ERROR = "CITY_EDIT_ERROR";
export const CITY_EDIT_RESET = "CITY_EDIT_RESET";

export const STATE_EDIT_REQUEST = "STATE_EDIT_REQUEST";
export const STATE_EDIT_SUCCESS = "STATE_EDIT_SUCCESS";
export const STATE_EDIT_ERROR = "STATE_EDIT_ERROR";
export const STATE_EDIT_RESET = "STATE_EDIT_RESET";

export const COUNTRY_EDIT_REQUEST = "COUNTRY_EDIT_REQUEST";
export const COUNTRY_EDIT_SUCCESS = "COUNTRY_EDIT_SUCCESS";
export const COUNTRY_EDIT_ERROR = "COUNTRY_EDIT_ERROR";
export const COUNTRY_EDIT_RESET = "COUNTRY_EDIT_RESET";

export const DOCUMENT_LIST_REQUEST = "DOCUMENT_LIST_REQUEST";
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
export const DOCUMENT_LIST_ERROR = "DOCUMENT_LIST_ERROR";
export const DOCUMENT_LIST_RESET = "DOCUMENT_LIST_RESET";

export const DOCUMENT_VIEWLIST_REQUEST = "DOCUMENT_VIEWLIST_REQUEST";
export const DOCUMENT_VIEWLIST_SUCCESS = "DOCUMENT_VIEWLIST_SUCCESS";
export const DOCUMENT_VIEWLIST_ERROR = "DOCUMENT_VIEWLIST_ERROR";
export const DOCUMENT_VIEWLIST_RESET = "DOCUMENT_VIEWLIST_RESET";

export const BAGSIZE_LIST_REQUEST = "BAGSIZE_LIST_REQUEST";
export const BAGSIZE_LIST_SUCCESS = "BAGSIZE_LIST_SUCCESS";
export const BAGSIZE_LIST_ERROR = "BAGSIZE_LIST_ERROR";
export const BAGSIZE_LIST_RESET = "BAGSIZE_LIST_RESET";

export const BAGSIZE_ADD_REQUEST = "BAGSIZE_ADD_REQUEST";
export const BAGSIZE_ADD_SUCCESS = "BAGSIZE_ADD_SUCCESS";
export const BAGSIZE_ADD_ERROR = "BAGSIZE_ADD_ERROR";
export const BAGSIZE_ADD_RESET = "BAGSIZE_ADD_RESET";

export const TYPE_LIST_REQUEST = "TYPE_LIST_REQUEST";
export const TYPE_LIST_SUCCESS = "TYPE_LIST_SUCCESS";
export const TYPE_LIST_ERROR = "TYPE_LIST_ERROR";
export const TYPE_LIST_RESET = "TYPE_LIST_RESET";

export const TYPE_ADD_REQUEST = "TYPE_ADD_REQUEST";
export const TYPE_ADD_SUCCESS = "TYPE_ADD_SUCCESS";
export const TYPE_ADD_ERROR = "TYPE_ADD_ERROR";
export const TYPE_ADD_RESET = "TYPE_ADD_RESET";

export const TYPE_STATUS_REQUEST = "TYPE_STATUS_REQUEST";
export const TYPE_STATUS_SUCCESS = "TYPE_STATUS_SUCCESS";
export const TYPE_STATUS_ERROR = "TYPE_STATUS_ERROR";
export const TYPE_STATUS_RESET = "TYPE_STATUS_RESET";

export const BAGSIZE_STATUS_REQUEST = "BAGSIZE_STATUS_REQUEST";
export const BAGSIZE_STATUS_SUCCESS = "BAGSIZE_STATUS_SUCCESS";
export const BAGSIZE_STATUS_ERROR = "BAGSIZE_STATUS_ERROR";
export const BAGSIZE_STATUS_RESET = "BAGSIZE_STATUS_RESET";

export const BAGSIZE_DETAILS_REQUEST = "BAGSIZE_DETAILS_REQUEST";
export const BAGSIZE_DETAILS_SUCCESS = "BAGSIZE_DETAILS_SUCCESS";
export const BAGSIZE_DETAILS_ERROR = "BAGSIZE_DETAILS_ERROR";
export const BAGSIZE_DETAILS_RESET = "BAGSIZE_DETAILS_RESET";

export const BAGSIZE_UPDATE_REQUEST = "BAGSIZE_UPDATE_REQUEST";
export const BAGSIZE_UPDATE_SUCCESS = "BAGSIZE_UPDATE_SUCCESS";
export const BAGSIZE_UPDATE_ERROR = "BAGSIZE_UPDATE_ERROR";
export const BAGSIZE_UPDATE_RESET = "BAGSIZE_UPDATE_RESET";

export const SPECIALITY_UPDATE_REQUEST = "SPECIALITY_UPDATE_REQUEST";
export const SPECIALITY_UPDATE_SUCCESS = "SPECIALITY_UPDATE_SUCCESS";
export const SPECIALITY_UPDATE_ERROR = "SPECIALITY_UPDATE_ERROR";
export const SPECIALITY_UPDATE_RESET = "SPECIALITY_UPDATE_RESET";

export const SPECIALITY_DETAILS_REQUEST = "SPECIALITY_DETAILS_REQUEST";
export const SPECIALITY_DETAILS_SUCCESS = "SPECIALITY_DETAILS_SUCCESS";
export const SPECIALITY_DETAILS_ERROR = "SPECIALITY_DETAILS_ERROR";
export const SPECIALITY_DETAILS_RESET = "SPECIALITY_DETAILS_RESET";

export const TYPE_UPDATE_REQUEST = "TYPE_UPDATE_REQUEST";
export const TYPE_UPDATE_SUCCESS = "TYPE_UPDATE_SUCCESS";
export const TYPE_UPDATE_ERROR = "TYPE_UPDATE_ERROR";
export const TYPE_UPDATE_RESET = "TYPE_UPDATE_RESET";

export const TYPE_DETAILS_REQUEST = "TYPE_DETAILS_REQUEST";
export const TYPE_DETAILS_SUCCESS = "TYPE_DETAILS_SUCCESS";
export const TYPE_DETAILS_ERROR = "TYPE_DETAILS_ERROR";
export const TYPE_DETAILS_RESET = "TYPE_DETAILS_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_ERROR = "ORDER_LIST_ERROR";
export const ORDER_LIST_RESET = "ORDER_LIST_RESET";

export const ADD_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_ERROR = "ADD_FAQ_ERROR";
export const ADD_FAQ_RESET = "ADD_FAQ_RESET";

export const FAQ_TYPE_REQUEST = "FAQ_TYPE_REQUEST";
export const FAQ_TYPE_SUCCESS = "FAQ_TYPE_SUCCESS";
export const FAQ_TYPE_ERROR = "FAQ_TYPE_ERROR";
export const FAQ_TYPE_RESET = "FAQ_TYPE_RESET";

export const FAQ_LIST_REQUEST = "FAQ_LIST_REQUEST";
export const FAQ_LIST_SUCCESS = "FAQ_LIST_SUCCESS";
export const FAQ_LIST_ERROR = "FAQ_LIST_ERROR";
export const FAQ_LIST_RESET = "FAQ_LIST_RESET";

export const SUPPORTTICKET_LIST_REQUEST = "SUPPORTTICKET_LIST_REQUEST";
export const SUPPORTTICKET_LIST_SUCCESS = "SUPPORTTICKET_LIST_SUCCESS";
export const SUPPORTTICKET_LIST_ERROR = "SUPPORTTICKET_LIST_ERROR";
export const SUPPORTTICKET_LIST_RESET = "SUPPORTTICKET_LIST_RESET";

export const GETSUPPORT_STATUS_REQUEST = "GETSUPPORT_STATUS_REQUEST";
export const GETSUPPORT_STATUS_SUCCESS = "GETSUPPORT_STATUS_SUCCESS";
export const GETSUPPORT_STATUS_ERROR = "GETSUPPORT_STATUS_ERROR";
export const GETSUPPORT_STATUS_RESET = "GETSUPPORT_STATUS_RESET";

export const CREATE_SETTING_REQUEST = "CREATE_SETTING_REQUEST";
export const CREATE_SETTING_SUCCESS = "CREATE_SETTING_SUCCESS";
export const CREATE_SETTING_ERROR = "CREATE_SETTING_ERROR";
export const CREATE_SETTING_RESET = "CREATE_SETTING_RESET";

export const GET_SETTING_REQUEST = "GET_SETTING_REQUEST";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_ERROR = "GET_SETTING_ERROR";
export const GET_SETTING_RESET = "GET_SETTING_RESET";


export const CREATE_ORDER_LIST_REQUEST = "CREATE_ORDER_LIST_REQUEST";
export const CREATE_ORDER_LIST_SUCCESS = "CREATE_ORDER_LIST_SUCCESS";
export const CREATE_ORDER_LIST_ERROR = "CREATE_ORDER_LIST_ERROR";
export const CREATE_ORDER_LIST_RESET = "CREATE_ORDER_LIST_RESET";


export const SUPPORT_STATUSEDIT_REQUEST = "SUPPORT_STATUSEDIT_REQUEST";
export const SUPPORT_STATUSEDIT_SUCCESS = "SUPPORT_STATUSEDIT_SUCCESS";
export const SUPPORT_STATUSEDIT_ERROR = "SUPPORT_STATUSEDIT_ERROR";
export const SUPPORT_STATUSEDIT_RESET = "SUPPORT_STATUSEDIT_RESET";


export const CHAT_SEND_REQUEST = "CHAT_SEND_REQUEST";
export const CHAT_SEND_SUCCESS = "CHAT_SEND_SUCCESS";
export const CHAT_SEND_ERROR = "CHAT_SEND_ERROR";
export const CHAT_SEND_RESET = "CHAT_SEND_RESET";


export const CHAT_GET_REQUEST = "CHAT_GET_REQUEST";
export const CHAT_GET_SUCCESS = "CHAT_GET_SUCCESS";
export const CHAT_GET_ERROR = "CHAT_GET_ERROR";
export const CHAT_GET_RESET = "CHAT_GET_RESET";



export const Excle_Download_REQUEST = 'Excle_Download_REQUEST';
export const Excle_Download_SUCCESS = 'Excle_Download_SUCCESS';
export const Excle_Download_ERROR = 'Excle_Download_ERROR';
export const Excle_Download_RESET = 'Excle_Download_RESET'



export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR';
export const DASHBOARD_DATA_RESET = 'DASHBOARD_DATA_RESET'



export const User_LIST_REQUEST = "VENDOR_LIST_REQUEST";
export const User_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const User_LIST_ERROR = "VENDOR_LIST_ERROR";
export const User_LIST_RESET = 'User_LIST_RESET'
  


export const User_VIEW_REQUEST = "User_VIEW_REQUEST";
export const User_VIEW_SUCCESS = "User_VIEW_SUCCESS";
export const User_VIEW_ERROR = "User_VIEW_ERROR";

export const LOGOUT = "LOGOUT";


export const DASHBOARD_COMMISSION_DATA_REQUEST = 'DASHBOARD_COMMISSION_DATA_REQUEST';
export const DASHBOARD_COMMISSION_DATA_SUCCESS = 'DASHBOARD_COMMISSION_DATA_SUCCESS';
export const DASHBOARD_COMMISSION_DATA_ERROR = 'DASHBOARD_COMMISSION_DATA_ERROR';
export const DASHBOARD_COMMISSION_DATA_RESET = 'DASHBOARD_COMMISSION_DATA_RESET'




export const NOTIFICATION_DATA_REQUEST = 'NOTIFICATION_DATA_REQUEST';
export const NOTIFICATION_DATA_SUCCESS = 'NOTIFICATION_DATA_SUCCESS';
export const NOTIFICATION_DATA_ERROR = 'NOTIFICATION_DATA_ERROR';
export const NOTIFICATION_DATA_RESET = 'NOTIFICATION_DATA_RESET';

 
export const NOTIFICATION_READ_REQUEST = 'NOTIFICATION_READ_REQUEST';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATION_READ_ERROR = 'NOTIFICATION_READ_ERROR';
export const NOTIFICATION_READ_RESET = 'NOTIFICATION_READ_RESET';
 
export const NOTIFICATION_COUNT_REQUEST = 'NOTIFICATION_COUNT_REQUEST';
export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_COUNT_SUCCESS';
export const NOTIFICATION_COUNT_ERROR = 'NOTIFICATION_COUNT_ERROR';
export const NOTIFICATION_COUNT_RESET = 'NOTIFICATION_COUNT_RESET';
 
export const ROLE_DROPDOWN_REQUEST = 'ROLE_DROPDOWN_REQUEST';
export const ROLE_DROPDOWN_SUCCESS = 'ROLE_DROPDOWN_SUCCESS';
export const ROLE_DROPDOWN_ERROR = 'ROLE_DROPDOWN_ERROR';
export const ROLE_DROPDOWN_RESET = 'ROLE_DROPDOWN_RESET';
 
export const PERMISSION_UPDATE_REQUEST = 'PERMISSION_UPDATE_REQUEST';
export const PERMISSION_UPDATE_SUCCESS = 'PERMISSION_UPDATE_SUCCESS';
export const PERMISSION_UPDATE_ERROR = 'PERMISSION_UPDATE_ERROR';
export const PERMISSION_UPDATE_RESET = 'PERMISSION_UPDATE_RESET';
 
export const PERMISSION_GET_REQUEST = 'PERMISSION_GET_REQUEST';
export const PERMISSION_GET_SUCCESS = 'PERMISSION_GET_SUCCESS';
export const PERMISSION_GET_ERROR = 'PERMISSION_GET_ERROR';
export const PERMISSION_GET_RESET = 'PERMISSION_GET_RESET';
 
export const PERMISSION_GETVALUE_REQUEST = 'PERMISSION_GETVALUE_REQUEST';
export const PERMISSION_GETVALUE_SUCCESS = 'PERMISSION_GETVALUE_SUCCESS';
export const PERMISSION_GETVALUE_ERROR = 'PERMISSION_GETVALUE_ERROR';
export const PERMISSION_GETVALUE_RESET = 'PERMISSION_GETVALUE_RESET';
 
export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';
export const UPDATE_ADMIN_RESET = 'UPDATE_ADMIN_RESET';


export const Eamil_Otp_REQUEST = 'Eamil_Otp_REQUEST';
export const Eamil_Otp_SUCCESS = 'Eamil_Otp_SUCCESS';
export const Eamil_Otp_ERROR = 'Eamil_Otp_ERROR';
export const Eamil_Otp_RESET = 'Eamil_Otp_RESET';



export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';
export const UPLOAD_DOCUMENT_RESET = 'UPLOAD_DOCUMENT_RESET';


export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_ERROR = 'BANK_LIST_ERROR';
export const BANK_LIST_RESET = 'BANK_LIST_RESET';


export const DOCUMENTS_DETAILS_REQUEST = 'DOCUMENTS_DETAILS_REQUEST';
export const DOCUMENTS_DETAILS_SUCCESS = 'DOCUMENTS_DETAILS_SUCCESS';
export const DOCUMENTS_DETAILS_ERROR = 'DOCUMENTS_DETAILS_ERROR';
export const DOCUMENTS_DETAILS_RESET = 'DOCUMENTS_DETAILS_RESET';


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';



export const EMPTY_DEVICETOKEN_REQUEST = 'EMPTY_DEVICETOKEN_REQUEST';
export const EMPTY_DEVICETOKEN_SUCCESS = 'EMPTY_DEVICETOKEN_SUCCESS';
export const EMPTY_DEVICETOKEN_ERROR = 'EMPTY_DEVICETOKEN_ERROR';
export const EMPTY_DEVICETOKEN_RESET = 'EMPTY_DEVICETOKEN_RESET';


export const RESTURANT_LIST_REQUEST = 'RESTURANT_LIST_REQUEST';
export const RESTURANT_LIST_SUCCESS = 'RESTURANT_LIST_SUCCESS';
export const RESTURANT_LIST_ERROR = 'RESTURANT_LIST_ERROR';
export const RESTURANT_LIST_RESET = 'RESTURANT_LIST_RESET';