
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyA64RB8gTsjDrxCntRO01ALrklBfFTTSkE",
  authDomain: "pickntreat-b17b3.firebaseapp.com",
  databaseURL: "https://pickntreat-b17b3-default-rtdb.firebaseio.com",
  projectId: "pickntreat-b17b3",
  storageBucket: "pickntreat-b17b3.appspot.com",
  messagingSenderId: "657083280866",
  appId: "1:657083280866:web:3ee00e501743f5259b9dbb",
  measurementId: "G-T73F1K3Q0K"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);

// Get Firebase Cloud Messaging instance
const messaging = getMessaging(firebaseApp);

// Listener for receiving messages
export const onMessageListener = () => 
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
      resolve(payload);
    });
  });



  export const getTokenfun = (setTokenFound) => {
    
    return getToken(messaging, {vapidKey:'BBW6z2rgC7ZJrZOtiUAEundRtZwGQ82Pb5HV7yzegSTZddJw9-elzyxLt6dRPNC3v_F1G5Xmr2M8fiRGJ_wY9eI'}).then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        localStorage.setItem('device_token', currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }