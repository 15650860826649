import React from 'react'
import Country from './views/country'
import Specialty from './views/specialty'
import City from './views/city'
import State from './views/state'
import CountryAdd from './views/country/countryAdd'
import CityAdd from './views/city/cityadd'
import StateAdd from './views/state/stateadd'
import SpecialityAdd from './views/specialty/specialityadd'
import Documentverification from './views/vendor/document'
import Accountverification from './views/vendor/account'
import LiveVendor from './views/vendor/liveVendor'
import ViewRestuarant from './views/vendor/viewrestaurant'
import ViewLiveRestuarant from './views/vendor/livewendorview'
import ViewSupport from './views/support/supportView'
import ViewDocument from './views/vendor/documentViem'
import SurpriceBagSize from './views/surpriceBagSize'
import SurpriseBagAdd from './views/surpriceBagSize/bagSizeadd'
import RestaurantType from './views/restaurantType/restaurantTypelist'
import RestaurantTypeAdd from './views/restaurantType/addtype'
import Orderlist from './views/orderList'
import Billinglist from './views/orderbilling/Billing'
import AddFaq from './views/faq/addFaq'
import FaqList from './views/faq/faqlist'
import TermsConditions from './views/setting/TermConditions'
import Privacy from './views/setting/Privacy'
import SupportList from './views/support'
import SettingList from './views/setting/settingList'
import AddSetting from './views/setting/settingAdd'
import User from './views/User/Index'
import ViewUser from './views/User/ViewUser'
import DocumentUpload from './views/vendor/DocumentUpload'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))



const routes = [
  { path: '/dashboard/app', name: 'Dashboard', element: Dashboard },
  { path: '/dashboard/country', name: 'Country', element: Country },
  { path: '/dashboard/country/add', name: 'Add', element: CountryAdd },
  { path: '/dashboard/country/add/:id', name: 'Edit', element: CountryAdd },
  { path: '/dashboard/state', name: 'State', element: State },
  { path: '/dashboard/state/add', name: 'Add', element: StateAdd },
  { path: '/dashboard/state/add/:id', name: 'Edit', element: StateAdd },
  { path: '/dashboard/city', name: 'City', element: City },
  { path: '/dashboard/city/add', name: 'Add', element: CityAdd },
  { path: '/dashboard/city/add/:id', name: 'Edit', element: CityAdd },
  { path: '/dashboard/speciality', name: 'Cuisine', element: Specialty },
  { path: '/dashboard/speciality/add', name: 'Add', element: SpecialityAdd },
  { path: '/dashboard/speciality/add/:id', name: 'Edit', element: SpecialityAdd },
  { path: '/dashboard/document', name: 'Documents', element: Documentverification },
  { path: '/dashboard/account', name: 'Account', element: Accountverification },
  { path: '/dashboard/liveVendor', name: 'Live Vendors', element: LiveVendor },
  { path: '/dashboard/restaurantView/:id', name: 'Details', element: ViewRestuarant },
  { path: '/dashboard/vendorView/:id', name: 'Details', element: ViewLiveRestuarant },
  { path: '/dashboard/supportView/:id', name: 'Details', element: ViewSupport },
  { path: '/dashboard/documentView/:id', name: 'Details', element: ViewDocument },
  { path: '/dashboard/bagsize', name: 'Bagsize', element: SurpriceBagSize },
  { path: '/dashboard/bagsize/add', name: 'Add', element: SurpriseBagAdd },
  { path: '/dashboard/bagsize/add/:id', name: 'Edit', element: SurpriseBagAdd },
  { path: '/dashboard/type/add', name: 'Add', element: RestaurantTypeAdd },
  { path: '/dashboard/type/add/:id', name: 'Edit', element: RestaurantTypeAdd },
  { path: '/dashboard/type', name: 'type', element: RestaurantType },

  { path: '/dashboard/orderlist', name: 'Orders', element: Orderlist },
  { path: '/dashboard/billinglist', name: 'Billing', element: Billinglist },
  { path: '/dashboard/faq/add', name: 'Add', element: AddFaq },
  { path: '/dashboard/faq/add/:id', name: 'Edit', element: AddFaq },
  { path: '/dashboard/faq', name: 'FAQ', element: FaqList },
 

  { path: '/dashboard/support', name: 'Support', element: SupportList },
  { path: '/dashboard/privacy', name: 'Privacy Policy', element: Privacy },
  { path: '/dashboard/terms', name: 'Terms & Conditions', element: TermsConditions },

  { path: '/dashboard/setting', name: 'Settings', element: SettingList },
  { path: '/dashboard/setting/add', name: 'Add', element:AddSetting },
  { path: '/dashboard/setting/add/:id', name: 'Edit', element: AddSetting },


  { path: '/dashboard/users', name: 'User', element:User },
  { path: '/dashboard/userView/:id', name: 'User View', element: ViewUser },


  { path: '/dashboard/documentUpload/:restaurantIdFK', name: 'uploaddocument', element: DocumentUpload },
]

export default routes
