import React, { useEffect, useState } from "react";
import "./index.css";

import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CProgress,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";

import WidgetsDropdown from "../widgets/WidgetsDropdown";
import MainChart from "./MainChart";
import ToastMessage from "../../utils/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction } from "../../redux/actions";
import { dashboard_api } from "../../API/baseUrl";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import PieChart from "../../components/pie";
import DashboardTable from "./dashboardTable";
import PieChartDropdown from "./piechartwithdropdown";
import { HomeDashboard } from "../Title";
import dayjs from "dayjs";
import DashboardSkeleton from "./dashboardskeleton";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [filter, setFilter] = useState("Today");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [startdateval, setStartDateval] = useState("");
  const [enddateval, setEndDateval] = useState("");
  const dispatch = useDispatch();
  const { dashboardInfo } = useSelector(
    (state) => state?.DashboardReducer || {}
  );

  useEffect(() => {
    const parameter = {
      filter_option: filter,
      url: dashboard_api,
      start_date: filter === "Custom" ? startdate : "",
      end_date: filter === "Custom" ? enddate : "",
    };
    dispatch(DashboardAction(parameter));
  }, [filter, startdate, enddate]);

  useEffect(() => {
    if (dashboardInfo?.status) {
      setData(dashboardInfo);
      setPending(false);
    } else {
      ToastMessage("error", dashboardInfo?.message);
      setPending(false);
    }
  }, [dashboardInfo]);

  const handlefilter = (value) => {
    setFilter(value);
  };

  const handleChangePeriod = (event) => {
    setFilter(event.target.value);
  };
  useEffect(() => {
    document.title = HomeDashboard;
  }, []);
  const handleStartDateChange = (event) => {
    const formattedDate = dayjs(event.target.value).format("DD-MM-YYYY");
    setStartDate(formattedDate);
    setStartDateval(event.target.value);
  };

  const handleEndDateChange = (event) => {
    const formattedDate = dayjs(event.target.value).format("DD-MM-YYYY");
    setEndDate(formattedDate);
    setEndDateval(event.target.value);
  };
  const period = [
    {
      name: "Today",
      value: "Today",
    },
    {
      name: "Week",
      value: "Past 7 Days",
    },
    {
      name: "Month",
      value: "This Month",
    },
    {
      name: "Year",
      value: "This Year",
    },
  ];

  const formatDateToDDMMYYYY = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  

  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);


  return (
    <>
      {pending ? (
        <LoaderAnimation />
      ) : (
    <>
    {
      parsedDatapermission?.dashboardView == 1 ?     <>
      <div className="typeAndPeriodClass">
        <h4 className="dashboard_Heading">Revenue Dashboard</h4>

        <div className="typeAndPeriodClassContainer">
          <div className="periodDivClass">
            <p>Period :</p>
            <div className="periodDivClassDropdown">
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-simple-select-label"
                  value={filter}
                  onChange={handleChangePeriod}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Past 7 Days">This Week</MenuItem>
                  <MenuItem value="This Month">This Month</MenuItem>
                  <MenuItem value="Past Month">Last Month </MenuItem>
                  <MenuItem value="This Year">This Year </MenuItem>
                  <MenuItem value="Previous Year">Last Year </MenuItem>
                  <MenuItem value="Till Date">Till Date </MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {filter == "Custom" ? (
            <div className="dhasboard_date_container_main">
              <div className="periodDivClass">
                <p className="date_picker_label_dashboard">From</p>
                <input
                  className="dashboard_date_container"
                  type="date"
                  value={startdateval}
                  onChange={handleStartDateChange}
                  format="dd-mm-yyyy"
                  max={enddateval || getTodayDate()}
                />
              </div>

              <div className="periodDivClass">
                <p className="date_picker_label_dashboard">To</p>
                <input
                  className="dashboard_date_container"
                  type="date"
                  value={enddateval}
                  onChange={handleEndDateChange}
                  format="dd-mm-yyyy"
                  max={getTodayDate()} // Limit end date to today
                  min={startdateval} // Limit end date to selected start date
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <WidgetsDropdown className="mb-4" data={data} />

      {/* Flex container for graph and pie chart */}

      <div
      // className="chart-pie-container"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div
              style={{ width: "100%" }}
              //  className="bigger_component"
            >
              <CCard className="mb-4 chart-card heightcard">
                <CCardBody>
                  <h2 className="pie_heading">Revenue</h2>
                  <MainChart dashboarddata={data} />
                </CCardBody>
              </CCard>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div
            //  className="smaller_component"
            >
              <CCard className="mb-4 pie-card heightcard">
                <CCardBody>
                  <PieChart data={data?.totalActiveRestaurantTypeCount} />
                </CCardBody>
              </CCard>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={12} md={4} lg={4}>
            <div
            // className="smaller_component"
            >
              <CCard className="mb-4 pie-card heightcard">
                <CCardBody>
                  <PieChartDropdown
                    data={data?.totalActiveRestaurantTypeCount}
                  />
                </CCardBody>
              </CCard>
            </div>
          </Grid> */}
          <Grid item xs={12} sm={12} md={8} lg={12}>
            <div
            // className="bigger_component"
            >
              <CCard className="mb-4 table-card heightcard">
                <CCardBody>
                  <DashboardTable
                    userdata={data?.top5Users}
                    vendordata={data?.top5Restaurant}
                  />
                </CCardBody>
              </CCard>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Flex container for pie chart and table */}
      {/* <div className="table-pie-container">


</div> */}
</> : <div className="dont-permission-text">
  <p>You don't have permission to view this page</p>
</div>
    }

    
    </>



      
      )}
    </>
  );
};

export default Dashboard;
