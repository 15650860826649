import React, { useEffect, useState } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Outlet, useNavigate } from 'react-router-dom';
import "./index.css"
import { useSelector } from 'react-redux';
import ToastMessage from '../utils/ToastMessage';
import axios from 'axios';

const DefaultLayout = () => {
  const navigate = useNavigate();
  const [sidebarShow , setSidebarShow] = useState(true)
  

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      <Outlet/>
     
    } else {
      navigate("/");
    }
  }, []);


const checkstatus = async () =>{
  let intervalId;
  try {
    // Get the user ID from localStorage
    const LoginUserId = localStorage.getItem("userIdFk");

    if (!LoginUserId) {
      clearInterval(intervalId); 
      // return;
    }
    const response = await axios.get(
      "https://pickntreat-b17b3-default-rtdb.firebaseio.com/admin.json"
    );

    const userData = response.data[LoginUserId];
    
    // Verify if the user is inactive
    const isInactive = userData && userData.is_active == 0;

    // Show alert, log out, clear localStorage, and stop script if user is inactive
    if (isInactive) {
      alert("You will be automatically logged out due to some changes in your role.");
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/"; 
      }, 1000);

      // Stop the interval
      clearInterval(intervalId);
    }
  } catch (error) {
    console.error("Error fetching data", error);
  }
}




  // useEffect(() => {
  //   checkstatus();
  // }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      checkstatus();
    }, 5000); 

    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <AppSidebar {...{sidebarShow , setSidebarShow}} />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader {...{sidebarShow , setSidebarShow}} />
        <div className="body flex-grow-1 outletcontainer">
          {/* <AppContent/> */}
          <Outlet/>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default DefaultLayout
