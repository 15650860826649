import { cilPuzzle, cilSpeedometer } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { MdDashboard } from "react-icons/md";
import { CNavGroup, CNavItem } from "@coreui/react";
import { AiOutlineDashboard } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { FaCity } from "react-icons/fa";
import { MdFoodBank } from "react-icons/md";
import { GiMatterStates } from "react-icons/gi";
import { MdOutlineLunchDining } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";
import { MdBorderColor } from "react-icons/md";
import { IoBagAdd } from "react-icons/io5";
import { FaPuzzlePiece } from "react-icons/fa6";
import LogoutIcon from "@mui/icons-material/Logout";
import { MdSupportAgent } from "react-icons/md";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
import { GrRestaurant } from "react-icons/gr";
import { ImUser } from "react-icons/im";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import React from "react";

const data = localStorage.getItem("permissiondata")



const parsedData = JSON.parse(data);

console.log("datadataaayu",parsedData)














const _nav = [
  {
    component: CNavItem,
    access: parsedData?.dashboardView == 1 ? true : false,
    // access:  true ,
    name: "Dashboard",
    to: "/dashboard/app",
    icon: <MdDashboard className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavGroup,
    name: "Vendors",
    to: "",
    icon: <GrRestaurant className="me-3" />,
    items: [
      {
        component: CNavItem,
        access: parsedData?.liveVendorsview == 1 ? true : false,
        // access:  true ,
        name: "Live Vendors",
        to: "/dashboard/liveVendor",
      },
      {
        component: CNavItem,
        access: parsedData?.vendorsAccountsview == 1 ? true : false,
        // access:  true ,
        name: "Vendor Account",
        to: "/dashboard/account",
      },
      {
        component: CNavItem,
        access: parsedData?.vendorsDocumentview == 1 ? true : false,
        // access:  true ,
        name: "Vendor Document",
        to: "/dashboard/document",
      },
    ],
  },

  {
    component: CNavGroup,
    // access: parsedData?.contactList_Permission == 0 ? true : false,
    name: "Admin User Management",
    to: "",
    icon: <ImUser className="me-3" />,
    items: [
      {
        component: CNavItem,
        access: parsedData?.adminUserview == 1 ? true : false,
        // access:  true ,
        name: "Admin Users",
        to: "/dashboard/adminusers",
      },
      {
        component: CNavItem,
        access: parsedData?.adminRoleview == 1 ? true : false,
        // access:  true ,
        name: "Role",
        to: "/dashboard/userRole",
      },
      {
        component: CNavItem,
        access: parsedData?.adminPermissionView == 1 ? true : false,
        // access:  true ,
        name: "Permission",
        to: "/dashboard/permission",
      },
    ],
  },

  {
    component: CNavItem,
    access: parsedData?.usersView == 1 ? true : false,
        // access:  true ,
    name: "Users",
    to: "/dashboard/users",
    icon: <PersonIcon className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },
  
  {
    component: CNavItem,
    access: parsedData?.orderView == 1 ? true : false,
        // access:  true ,
    name: "Orders",
    to: "/dashboard/orderlist",
    icon: <MdBorderColor className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.billingsView == 1 ? true : false,
        // access:  true ,
    name: "Billings",
    to: "/dashboard/billinglist",
    icon: <RiBillLine className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.restaurantView == 1 ? true : false,
        // access:  true ,
    name: "Restaurant Types",
    to: "/dashboard/type",
    icon: <MdOutlineLunchDining className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.magicbagSizeview == 1 ? true : false,
        // access:  true ,
    name: "Magic Bag Size ",
    to: "/dashboard/bagsize",
    icon: <IoBagAdd className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.cuisineView == 1 ? true : false,
        // access:  true ,
    name: "Cuisine",
    to: "/dashboard/speciality",
    icon: <MdFoodBank className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavGroup,
    name: "Location",
    to: "",
    icon: <TbWorld className="me-3" />,
    items: [
      {
        component: CNavItem,
        access: parsedData?.countryView == 1 ? true : false,
        // access:  true ,
        name: "Country",
        to: "/dashboard/country",
        badge: {
          color: "info",
          text: "",
        },
      },
      {
        component: CNavItem,
        access: parsedData?.stateView == 1 ? true : false,
        // access:  true ,
        name: "State",
        to: "/dashboard/state",
        badge: {
          color: "info",
          text: "",
        },
      },
      {
        component: CNavItem,
        access: parsedData?.cityView == 1 ? true : false,
        // access:  true ,
        name: "City",
        to: "/dashboard/city",
        badge: {
          color: "info",
          text: "",
        },
      },
    ],
  },

  {
    component: CNavItem,
    access: parsedData?.bankView == 1 ? true : false,
    // access:  true ,
    name: "Bank",
    to: "/dashboard/bank",
    icon: <AccountBalanceIcon className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.faqView == 1 ? true : false,
        // access:  true ,
    name: "FAQ",
    to: "/dashboard/faq",
    icon: <FaQuestion className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.supportView == 1 ? true : false,
        // access:  true ,
    name: "Support",
    to: "/dashboard/support",
    icon: <MdSupportAgent className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.privacyPolicyView == 1 ? true : false,
        // access:  true ,
    name: "Privacy Policy",
    to: "/dashboard/privacy",
    icon: <SiGnuprivacyguard className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.termsandconditionView == 1 ? true : false,
        // access:  true ,
    name: "Terms and Conditions",
    to: "/dashboard/terms",
    icon: <MdOutlinePrivacyTip className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access: parsedData?.settingsView == 1 ? true : false,
        // access:  true ,
    name: "Settings",
    to: "/dashboard/setting",
    icon: <IoSettingsSharp className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },

  {
    component: CNavItem,
    access:  true ,
    name: "Logout",
    to: "",
    icon: <LogoutIcon className="me-3" />,
    badge: {
      color: "info",
      text: "",
    },
  },
];

export default _nav;
