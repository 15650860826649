import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { getstatelist_api, stateStatus_api } from "../../API/baseUrl";
import CircularProgress from "@mui/material/CircularProgress";
import {
  removeDataFromReducer,
  stateList,
  stateStatusRequest,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
// import { CButton, CFormSwitch } from "@coreui/react";
import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { TableSk } from "../skelaton/tableSk";
import { StateTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { FormControlLabel, styled, Switch } from "@mui/material";

const State = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { stateListInfo } = useSelector((state) => state?.stateList || {});
  const { statestatusdata } = useSelector((state) => state?.statestatus) || {};

  console.log("countryListInfo", statestatusdata);
  useEffect(() => {

    document.title = StateTitle;
  }, []);

  useEffect(() => {
    if (stateListInfo?.status) {
      setData(stateListInfo?.data);
      setTotalRows(stateListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", stateListInfo?.message);
    }
  }, [stateListInfo]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getstatelist_api,
    };
    dispatch(stateList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleAdd = async (e) => {
    dispatch(removeDataFromReducer("STATE_DETAILS_RESET"));
    navigate("/dashboard/state/add");
  };



  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#267887",
      '&:hover': {
        backgroundColor: 'none',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#267887",
    },
  }));

  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.stateId,
      sortable: true,
      column_name: "stateId",
      reorder: true,
    },
    {
      name: "Name",
      selector: (row) => row.stateName,
      sortable: true,
      column_name: "stateName",
      reorder: true,
    },
    {
      name: "Country ",
      selector: (row) => row.countryName,
      // sortable: true,
      column_name: "countryName",
      reorder: true,
    },


    {
      name: " Status",

      selector: (row) => (

        parsedDatapermission?.stateEdit == 1 ?
          <FormControlLabel
            className='form_control_lable'
            control={
              <PinkSwitch
                size="small"
                defaultChecked={row.isActive}
                checked={row.isActive == '1'}
                onChange={() => handleStatus(row.stateId)}
              />
            }
            label={row.isActive === 1 ? "Active" : "Inactive"}
          /> : row.isActive === 1 ? "Active" : "Inactive"


      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },


    // {
    //   name: "Status",
    //   selector: (row) => (
    //     parsedDatapermission?.stateEdit == 1 ? 
    //     <CFormSwitch
    //       onClick={() => handleStatus(row.stateId)}
    //       label={row.isActive === 1 ? "Active" : "Inactive"}
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //         color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }}
    //     /> : row.isActive === 1 ? "Active" : "Inactive"
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },


    ...(parsedDatapermission?.stateEdit == 1
      ? [ 
        {
          name: "Edit",
          selector: (row) => (
            <button className="edit_btn" onClick={() => handleEdit(row.stateId)}
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
              }}
            >
              Edit
            </button>
          ),
          // sortable: true,
          column_name: "",
          reorder: true,
        },
        ]
      : []),
 
  ];

  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("STATE_DETAILS_RESET"));
    navigate(`/dashboard/state/add/${id}`);
  };

  const handleStatus = (id) => {
    const parameter = {
      url: `${stateStatus_api}/${id}`,
    };
    dispatch(stateStatusRequest(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getstatelist_api,
    };
    if (statestatusdata?.status) {
      ToastMessage("success", statestatusdata?.message);
      dispatch(stateList(parameter));
      dispatch(removeDataFromReducer("STATE_STATUS_RESET"));
    } else {
      ToastMessage("error", statestatusdata?.message);
    }
  }, [statestatusdata]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>State List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          {parsedDatapermission?.stateEdit == 1 ?  <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            className="me-md-2 btn_add_country"
            onClick={handleAdd}
          // disabled={isLoading}
          >
            Add State
          </CButton> :""}
        
        </div>
      </div>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default State;
