import React, { useEffect, useState } from "react";
// import InputField from "../../components/input";
import { useFormik } from "formik";

import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import "./index.css";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography, Stack
} from "@mui/material";
// import { TagsInput } from "react-tag-input-component";
import {
  accountStatusRequest,
  documentViewRequest,
  getBanklist,
  removeDataFromReducer,
  uploaddocuments,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  bank_list_api,
  document_edit_api,
  document_statusUpdate_api,
  document_viewlist_api,
  documentUpload_api,
  ImageUrl,
} from "../../API/baseUrl";
import ToastMessage from "../../utils/ToastMessage";
// import { documentUploadSchema } from "../../utils/schema";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Margin } from "@mui/icons-material";
import InputField from "../../components/input";
import { documentUploadSchema } from "../../utils/schema";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const DocumentUpload = ({ setCallApi, callApi }) => {
  const documentStatus = localStorage.getItem("documentStatus");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banklist, setBanklist] = useState([]);
  const [selected, setSelected] = useState([]);
  const [licenceImageURL, setLicenceImageURL] = useState([]);
  const [panCardImageURL, setPanCardImageURL] = useState([]);
  const [FoodImageURL, setFoodImageURL] = useState([]);
  const [RestaurantLogoURL, setRestaurantLogoURL] = useState([]);
  const [gstImageURL, setGstImageURL] = useState([]);
  const [menuImageURL, setMenuImageURL] = useState([]);

  const [imgUrl, setImgUrl] = useState("");
  const [imgUrllogo, setImgUrllogo] = useState("");
  const [image, setImage] = useState("");
  const [logoimage, setLogoimage] = useState("");
  const [logoimagearray, setLogoimagearray] = useState("");
  const [imagearray, setImagearray] = useState("");

  const [licenceImageArray, setLicenceImageArray] = useState([]);
  const [panCardImageArray, setPanCardImageArray] = useState([]);
  const [gstImageArray, setGstImageArray] = useState([]);
  const [showfassaistatus, setShowfassaistatus] = useState(false);

  const [menuImageArray, setMenuImageArray] = useState([]);
  const { uploaddocumentdata } = useSelector(
    (state) => state?.uploadimagereduser || {}
  );
  const { bankListdata } = useSelector((state) => state?.bankListreduser || {});


  const { documentviewListdata } = useSelector((state) => state?.documentviewListReduser || {});


  const { getReduserData } = useSelector(
    (state) => state?.getDocumentReduser || {}
  );




  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { restaurantIdFK } = useParams();
  const { reupload } = useParams();
  const [disablepancard, setDisablepancard] = React.useState(false);
  const [disablefassai, setDisablefassi] = React.useState(false);
  const [disablegst, setDisablegst] = React.useState(false);
  const [disablelogo, setDisablelogo] = React.useState(false);
  const [disablefood, setDisablefood] = React.useState(false);
  const [enddate, setEnddate] = React.useState("");
  const [apienddate, setApiEnddate] = React.useState("");
  const [pendingloader, setPendingloader] = React.useState(false);

  const [isBankRejected, setIsBankRejected] = React.useState(0);

  const handlecheckboxChange = (event) => {
    setChecked(event?.target?.checked);
  };

  useEffect(() => {
    const parts = apienddate?.split("-");
    const date = new Date(parts[2], parts[1] - 1, parts[0]);

    // Add one day to the date to make it 15th Aug
    date?.setDate(date?.getDate());

    // Convert the date to the desired format
    const formattedDateString = date?.toString();

    if (apienddate) {
      setEnddate(formattedDateString);
    }
  }, [apienddate]);


  const today = new Date();

  const initialValues = {
    restaurantIdFk: restaurantIdFK,
    bankIdFk: "",
    bankDetailIdFk: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    fssaiLicence: "",
    panCard: "",
    regularGstIn: "",
    fssaiLicenceExpiryDate: "",
    foodImage: "",
    restaurantLogo: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: documentUploadSchema,
    onSubmit: () => submitForm(),
  });

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
    return;
  };


  useEffect(() => {
    
    if (documentviewListdata?.status) {
      setPendingloader(false)
      setFieldValue("bankIdFk", documentviewListdata?.data?.bankIdFk);
      setFieldValue(
        "accountHolderName",
        documentviewListdata?.data?.account_holder_name
      );
      setFieldValue("accountNumber", documentviewListdata?.data?.account_number);
      setFieldValue("ifscCode", documentviewListdata?.data?.ifsc_code);
      setFieldValue("ifscCode", documentviewListdata?.data?.ifsc_code);
      setIsBankRejected(documentviewListdata?.data?.isBankReject);
      setFieldValue("branchName", documentviewListdata?.data?.branch_name);
      setFieldValue("bankDetailIdFk", documentviewListdata?.data?.bankDetailIdFk);
      values.bankDetailIdFk = documentviewListdata?.data?.bankDetailIdFk;

      if(getReduserData?.data?.fssaiLicenceExpiryDate){
        if (documentviewListdata?.data?.fssaiLicence[0]?.isDocumentReject == 0) {
          setLicenceImageArray(documentviewListdata?.data?.fssaiLicence);
          setFieldValue("fssaiLicence", documentviewListdata?.data?.fssaiLicence);
          setFieldValue(
            "fssaiLicenceExpiryDate",
           documentviewListdata?.data?.fssaiLicenceExpiryDate == "Invalid Date" ? "": documentviewListdata?.data?.fssaiLicenceExpiryDate
          );
          setApiEnddate(documentviewListdata?.data?.fssaiLicenceExpiryDate == "Invalid Date" ? "": documentviewListdata?.data?.fssaiLicenceExpiryDate);
          setDisablefassi(true);
          setShowfassaistatus(true)
        }
      }
    

      if (documentviewListdata?.data?.panCard[0]?.isDocumentReject == 0) {
        setPanCardImageArray(documentviewListdata?.data?.panCard);
        setFieldValue("panCard", documentviewListdata?.data?.panCard);
        setDisablepancard(true);
      }
      if (documentviewListdata?.data?.regularGstIn[0]?.isDocumentReject === 0) {
        setGstImageArray(documentviewListdata?.data?.regularGstIn);
        setFieldValue("regularGstIn", documentviewListdata?.data?.regularGstIn);
        setDisablegst(true);
      }

      if (documentviewListdata?.data?.foodImage?.[0]?.isDocumentReject === 0) {
        setImagearray(documentviewListdata?.data?.foodImage[0]?.documentPath);
        setFieldValue("foodImage", documentviewListdata?.data?.foodImage);
        setDisablefood(true);
      }
      if (documentviewListdata?.data?.restaurantLogo?.[0]?.isDocumentReject === 0) {
        setLogoimagearray(
          documentviewListdata?.data?.restaurantLogo[0]?.documentPath
        );
        setFieldValue("restaurantLogo", documentviewListdata?.data?.restaurantLogo);
        setDisablelogo(true);
      }
    } else {
      setPendingloader(false)
      ToastMessage("error", documentviewListdata?.message);
    }
  }, [documentviewListdata]);


  console.log("getReduserData", documentviewListdata)


  const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];

  const fileTypes1 = ["JPG", "JPEG", "PNG"];




  const submitForm = async (value) => {
    const fd = new FormData();

    for (let index = 0; index < licenceImageArray.length; index++) {
      const element = licenceImageArray[index];
      fd.append(`fssaiLicence`, element);
    }
    for (let index = 0; index < panCardImageArray.length; index++) {
      const element = panCardImageArray[index];
      fd.append(`panCard`, element);
    }
    for (let index = 0; index < gstImageArray.length; index++) {
      const element = gstImageArray[index];
      fd.append(`regularGstIn`, element);
    }
    fd.append("foodImage", values.foodImage);
    fd.append("restaurantLogo", values.restaurantLogo);
    fd.append(`restaurantIdFk`, restaurantIdFK);
    fd.append(`bankIdFk`, values.bankIdFk);
    fd.append(`accountHolderName`, values.accountHolderName);
    fd.append(`accountNumber`, values.accountNumber);
    fd.append(`ifscCode`, values.ifscCode);
    fd.append(`branchName`, values.branchName);
    fd.append(`fssaiLicenceExpiryDate`, values.fssaiLicenceExpiryDate);



    if (reupload == 2) {
      fd.append(`bankDetailIdFk`, values.bankDetailIdFk);
    }


    setLoading(true);
    const parameter = {
      values: fd,
      url: reupload == 2 ? document_edit_api : documentUpload_api,
    };

    dispatch(uploaddocuments(parameter));
  };
  console.log(documentUpload_api,"ddddddddddddddddddddddddd");


  const AutoAprrove = async () => {
    const parameter = {
      restaurantIdFk: restaurantIdFK,
      documentStatus: '1',
      reasonOfRejection: "",
      rejectItems: "",
      url: document_statusUpdate_api,
    };
    dispatch(accountStatusRequest(parameter));
  };



  useEffect(() => {
    if (bankListdata?.status) {
      setBanklist(bankListdata?.data);
    } else {
      ToastMessage("error", bankListdata?.message);
    }
  }, [bankListdata]);

  const prifilleddata = () => {
    setPendingloader(true)
    const parameter = {
      url: `${document_viewlist_api}${restaurantIdFK}`,
    };
    dispatch(documentViewRequest(parameter));
  };




  useEffect(() => {
    if (reupload == 2) {
      prifilleddata();
    }
  }, []);

  useEffect(() => {
    if (uploaddocumentdata?.status) {
      setLoading(false);
      ToastMessage("success", uploaddocumentdata?.message);
      dispatch(removeDataFromReducer("UPLOAD_DOCUMENT_RESET"));
      dispatch(removeDataFromReducer("DOCUMENT_VIEWLIST_RESET"));
      AutoAprrove()
      navigate("/dashboard/document");
      // if (setCallApi) {
      //   setCallApi(!callApi);
      // }
    } else {
      setLoading(false);
      ToastMessage("error", uploaddocumentdata?.message);
    }
  }, [uploaddocumentdata]);



  useEffect(() => {
    const parameter = {
      url: bank_list_api,
    };
    console.log("getBanklist",getBanklist)
    dispatch(getBanklist(parameter));
  }, []);

  const handletagChange = (e) => {
    if (e.length <= 5) {
      setSelected(e);
      values.topFiveDish = e?.join(",");
    }
  };

  const handlefileChange = (file, type) => {
    setFieldValue(type, file);

    const imageeditArr = Object.entries(file).map((img) =>
      URL.createObjectURL(img[1])
    );
    // ****
    if (type === "fssaiLicence") {
      // setLicenceImageURL(URL.createObjectURL(file))
      setLicenceImageURL([...licenceImageURL, ...imageeditArr]);
      const nestedaray = [...file, licenceImageArray].flat();

      setLicenceImageArray(nestedaray);
    } else if (type === "panCard") {
      // setPanCardImageURL(URL.createObjectURL(file))
      setPanCardImageURL([...panCardImageURL, ...imageeditArr]);
      const nestedaray = [...file, panCardImageArray];
      setPanCardImageArray(nestedaray.flat());
    } else {
      // setGstImageURL(URL.createObjectURL(file))
      setGstImageURL([...gstImageURL, ...imageeditArr]);
      const nestedaray = [...file, gstImageArray];
      setGstImageArray(nestedaray.flat());
    }
  };

  const handlesinglefileChange = (file, type) => {
    if (type === "logo") {
      setImgUrllogo(URL.createObjectURL(file));
      setLogoimage(file);
      values.restaurantLogo = file;
      setFieldValue("restaurantLogo", file);
    } else {
      setImgUrl(URL.createObjectURL(file));
      setImage(file);
      values.foodImage = file;
      setFieldValue("foodImage", file);
    }
  };

  const gototerms = () => {
    window.open("/Pick'n'Treate/terms", "_blank");
  };

  const gotopolicy = () => {
    window.open("/Pick'n'Treate/policy", "_blank");
  };

  const handlebankchange = (data, val, type) => {
    setFieldValue("bankIdFk", val.bankIdFk);
  };

  const checkArrvalue = (arr, value) => {
    const data = arr?.filter((item) => item.bankIdFk == value);
    return data;
  };


  const handleDateChange = (date) => {
    setEnddate(date);
    values.fssaiLicenceExpiryDate = dayjs(date).format("DD-MM-YYYY");
    setFieldValue("fssaiLicenceExpiryDate", dayjs(date).format("DD-MM-YYYY"));
  };


  const handleImageDelete = (e, index, type) => {
    e.preventDefault();
    let updatedArray;
    switch (type) {
      case "pancard":
        if (panCardImageURL.length <= 1) {
          ToastMessage("error", "You must have at least one image.");
          return;
        }
        updatedArray = panCardImageURL.filter((_, i) => i !== index);
        setPanCardImageArray([...updatedArray]);
        setPanCardImageURL([...updatedArray]);
        break;

      case "FssaiLicence":
        if (licenceImageURL.length <= 1) {
          ToastMessage("error", "You must have at least one image.");
          return;
        }
        updatedArray = licenceImageURL.filter((_, i) => i !== index);
        setLicenceImageArray([...updatedArray]);
        setLicenceImageURL([...updatedArray]);
        break;

      case "RegularGst":
        if (gstImageURL.length <= 1) {
          ToastMessage("error", "You must have at least one image.");
          return;
        }
        updatedArray = gstImageURL.filter((_, i) => i !== index);
        setGstImageArray([...updatedArray]);
        setGstImageURL([...updatedArray]);
        break;

      default:
        console.warn("Unknown type: ", type);
        break;
    }
  };

  const handleImageDelete1 = (e, type) => {
    e.preventDefault();
    if (type === "logo") {
      setImgUrllogo("");
      setLogoimage("");
      values.restaurantLogo = "";
      setFieldValue("restaurantLogo", "");
    } else {
      setImgUrl("");
      setImage("");
      values.foodImage = "";
      setFieldValue("foodImage", "");
    }
  };

  return (
    <>
    { pendingloader ? <LoaderAnimation/> : 
    
    <Box component="form" onSubmit={handleSubmit2}>
    <Grid container spacing={4} sx={{ display: "flex", p: 3 }}>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ alignItems: "center", display: "flex", fontWeight: "bold" }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{ fontWeight: "500", mx: 1 }}
        >
          Upload Documents
        </Typography>
      </Grid> */}

      <Box maxWidth="false" className="containerdivuser">
        <Stack className='editAndAddHeadClass headandback' >
          <ArrowBackIcon onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }} />
          <h2> Upload Documents</h2>
        </Stack>
      </Box>




      <Grid item xs={12} sm={12} md={12} lg={12} sx={{}}>
        <Autocomplete
          focused
          disablePortal
          disabled={isBankRejected == 0 && reupload == 2}
          disableClearable
          options={banklist}
          getOptionLabel={(option) => option?.bankName}
          defaultValue={checkArrvalue(banklist, values?.bankIdFk)[0]}
          key={checkArrvalue(banklist, values?.bankIdFk)[0]?.bankIdFk}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(banklist, val) =>
            handlebankchange(banklist, val, "bankIdFk")
          }
          renderInput={(params) => (
            <TextField
              onBlur={handleBlur}
              name="bankIdFk"
              label="Bank Name *"
              {...params}
            />
          )}
        />
        {errors.bankIdFk && touched.bankIdFk ? (
          <p className="form-error">{errors.bankIdFk}</p>
        ) : null}
        {reupload == 2 ? (
          <>
            {isBankRejected == 0 && reupload == 2 ? (
              <p className="verifiedText">Verified</p>
            ) : (
              <p className="rejectedText">Rejected</p>
            )}
          </>
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{}}>
        <InputField
          focused
          disabled={isBankRejected == 0 && reupload == 2}
          label="Account Holder Name *"
          placeholder="Account Holder Name "
          name="accountHolderName"
          value={values.accountHolderName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {reupload == 2 ? (
          <>
            {isBankRejected == 0 && reupload == 2 ? (
              <p className="verifiedText">Verified</p>
            ) : (
              <p className="rejectedText">Rejected</p>
            )}
          </>
        ) : (
          ""
        )}
        {errors.accountHolderName && touched.accountHolderName ? (
          <p className="form-error">{errors.accountHolderName}</p>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{}}>
        <InputField
          focused
          disabled={isBankRejected == 0 && reupload == 2}
          label="Account Number *"
          placeholder="Account Number "
          name="accountNumber"
          value={values.accountNumber}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {reupload == 2 ? (
          <>
            {isBankRejected == 0 && reupload == 2 ? (
              <p className="verifiedText">Verified</p>
            ) : (
              <p className="rejectedText">Rejected</p>
            )}
          </>
        ) : (
          ""
        )}
        {errors.accountNumber && touched.accountNumber ? (
          <p className="form-error">{errors.accountNumber}</p>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{}}>
        <InputField
          focused
          disabled={isBankRejected == 0 && reupload == 2}
          label="IFSC Code *"
          placeholder="ifscCode"
          name="ifscCode"
          value={values.ifscCode}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {reupload == 2 ? (
          <>
            {isBankRejected == 0 && reupload == 2 ? (
              <p className="verifiedText">Verified</p>
            ) : (
              <p className="rejectedText">Rejected</p>
            )}
          </>
        ) : (
          ""
        )}
        {errors.ifscCode && touched.ifscCode ? (
          <p className="form-error">{errors.ifscCode}</p>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: "2px" }}>
        <InputField
          focused
          disabled={isBankRejected == 0 && reupload == 2}
          label="Branch Name *"
          placeholder="Branch Name"
          name="branchName"
          value={values.branchName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          jk
        />
        {reupload == 2 ? (
          <>
            {isBankRejected == 0 && reupload == 2 ? (
              <p className="verifiedText">Verified</p>
            ) : (
              <p className="rejectedText">Rejected</p>
            )}
          </>
        ) : (
          ""
        )}{" "}
        {errors.branchName && touched.branchName ? (
          <p className="form-error">{errors.branchName}</p>
        ) : null}
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{ display: "flex", flexDirection: "column" }}
          position="relative"
          className="imageUploadClass"
        >
          <lable className="product_image_lable">Fssai Licence *</lable>
          <div className="dropzone_container_Product">
            <div>
              <FileUploader
                focused
                multiple={true}
                className="main_drop"
                disabled={disablefassai}
                handleChange={(e) => handlefileChange(e, "fssaiLicence")}
                onBlur={handleBlur}
                name="fssaiLicence"
                types={fileTypes}
                fullWidth
              />
              {reupload == 2 && showfassaistatus ? (
                <>
                  {disablefassai ? (
                    <p className="verifiedText">Verified</p>
                  ) : (
                    <p className="rejectedText">Rejected</p>
                  )}
                </>
              ) : (
                ""
              )}
              {errors.fssaiLicence && touched.fssaiLicence ? (
                <p className="form-error">{errors.fssaiLicence}</p>
              ) : null}

              <>
                {licenceImageURL.length ? (
                  <div className="Dropzone_Preview_container">
                    {console.log(licenceImageURL, "licenceImageURL")}
                    {licenceImageURL.map((item, index) => {
                      const isPdf = item.toLowerCase().endsWith(".pdf");
                      return (
                        <div key={index} className="preview_image">
                          {isPdf ? (
                            <iframe
                              src={item}
                              title="PDF Preview"
                              height={100}
                              width={100}
                              className="pdf_frame"
                            />
                          ) : (
                            <img
                              src={item}
                              alt="Food Image"
                              height={100}
                              width={100}
                            />
                          )}
                          <button
                            className="delete_button"
                            onClick={(e) =>
                              handleImageDelete(e, index, "FssaiLicence")
                            }
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {licenceImageArray.length ? (
                      <div className="Dropzone_Preview_container">
                        {licenceImageArray.map((item, index) => {
                          const isPdf = item.documentPath
                            .toLowerCase()
                            .endsWith(".pdf");
                          return (
                            <div key={index} className="preview_image">
                              {isPdf ? (
                                <iframe
                                  src={`${ImageUrl}${item.documentPath}`}
                                  title="PDF Preview"
                                  height={100}
                                  width={100}
                                  className="pdf_frame"
                                />
                              ) : (
                                <img
                                  src={`${ImageUrl}${item.documentPath}`}
                                  alt="banner"
                                  height={100}
                                  width={100}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      " "
                    )}
                  </>
                )}
              </>

            </div>
            <div></div>
          </div>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={4.8}
          sx={{ display: "flex", flexDirection: "column" }}
          position="relative"
          className="imageUploadClass"
        >
          <lable className="product_image_lable">
            Fssai Licence End Date*
          </lable>
          
          <input
            type="date"
            className="fassiEnddate"
            value={enddate}
            disabled={disablefassai}
            onChange={handleDateChange}
            name="fssaiLicenceExpiryDate"
            onBlur={handleBlur}
            min={today}
            // max={maxDate}
          />

          {reupload == 2 ? (
            <>
              {disablefassai ? (
                <p className="verifiedText">Verified</p>
              ) : (
                <p className="rejectedText">Rejected</p>
              )}
            </>
          ) : (
            ""
          )}

          {errors.fssaiLicenceExpiryDate &&
          touched.fssaiLicenceExpiryDate ? (
            <p className="form-error">{errors.fssaiLicenceExpiryDate}</p>
          ) : null}
        </Grid> */}


        <Grid
          item
          xs={12}
          sm={12}
          md={4.8}
          sx={{ display: "flex", flexDirection: "column" }}
          position="relative"
          className="imageUploadClass"
        >
          <lable className="product_image_lable">
            Fssai Licence End Date*
          </lable>

          <ReactDatePicker
            className="fassiEnddate"
            selected={enddate}
            disabled={disablefassai}
            onChange={handleDateChange}
            name="fssaiLicenceExpiryDate"
            onBlur={handleBlur}
            dateFormat="dd-MM-yyyy"
            minDate={today}
          // maxDate={maxDate}
          />

          {reupload == 2 && showfassaistatus ? (
            <>
              {disablefassai ? (
                <p className="verifiedText">Verified</p>
              ) : (
                <p className="rejectedText">Rejected</p>
              )}
            </>
          ) : (
            ""
          )}

          {errors.fssaiLicenceExpiryDate &&
            touched.fssaiLicenceExpiryDate ? (
            <p className="form-error">{errors.fssaiLicenceExpiryDate}</p>
          ) : null}
        </Grid>











      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", flexDirection: "column" }}
        position="relative"
        className="imageUploadClass"
      >
        <lable className="product_image_lable">PanCard *</lable>
        <div className="dropzone_container_Product">
          <div>
            <FileUploader
              multiple={true}
              className="main_drop"
              disabled={disablepancard}
              handleChange={(e) => handlefileChange(e, "panCard")}
              onBlur={handleBlur}
              name="panCard"
              types={fileTypes}
              fullWidth
            />
            {reupload == 2 ? (
              <>
                {disablepancard ? (
                  <p className="verifiedText">Verified</p>
                ) : (
                  <p className="rejectedText">Rejected</p>
                )}
              </>
            ) : (
              ""
            )}
            {errors.panCard && touched.panCard ? (
              <p className="form-error">{errors.panCard}</p>
            ) : null}

            <>
              {panCardImageURL.length ? (
                <div className="Dropzone_Preview_container">
                  {panCardImageURL.map((item, index) => {
                    return (
                      <>
                        <div className="preview_image">
                          <img
                            src={item}
                            alt="banner"
                            height={100}
                            width={100}
                          />
                          <button
                            className="delete_button"
                            onClick={(e) =>
                              handleImageDelete(e, index, "pancard")
                            }
                          >
                            X
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <>
                  {panCardImageArray.length ? (
                    <div className="Dropzone_Preview_container">
                      {panCardImageArray.map((item, index) => {
                        return (
                          <>
                            <div className="preview_image">
                              <img
                                src={`${ImageUrl}${item.documentPath}`}
                                alt="banner"
                                height={100}
                                width={100}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    " "
                  )}
                </>
              )}
            </>

            {/* } */}
          </div>
          <div></div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", flexDirection: "column" }}
        position="relative"
        className="imageUploadClass"
      >
        <lable className="product_image_lable">Regular Gst *</lable>
        <div className="dropzone_container_Product">
          <div>
            <FileUploader
              multiple={true}
              disabled={disablegst}
              className="main_drop"
              handleChange={(e) => handlefileChange(e, "regularGstIn")}
              onBlur={handleBlur}
              name="regularGstIn"
              types={fileTypes}
              fullWidth
            />
            {reupload == 2 ? (
              <>
                {disablegst ? (
                  <p className="verifiedText">Verified</p>
                ) : (
                  <p className="rejectedText">Rejected</p>
                )}
              </>
            ) : (
              ""
            )}
            {errors.regularGstIn && touched.regularGstIn ? (
              <p className="form-error">{errors.regularGstIn}</p>
            ) : null}

            <>
              {gstImageURL.length ? (
                <div className="Dropzone_Preview_container">
                  {gstImageURL.map((item, index) => {
                    const isPdf = item.toLowerCase().endsWith(".pdf");
                    return (
                      <div className="preview_image" key={index}>
                        {isPdf ? (
                          <embed
                            src={item}
                            type="application/pdf"
                            height="100"
                            width="100"
                            className="pdf_embed"
                          />
                        ) : (
                          <img
                            src={item}
                            alt="banner"
                            height={100}
                            width={100}
                          />
                        )}
                        <button
                          className="delete_button"
                          onClick={(e) =>
                            handleImageDelete(e, index, "RegularGst")
                          }
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  {gstImageArray.length ? (
                    <div className="Dropzone_Preview_container">
                      {gstImageArray.map((item, index) => {
                        const fullPath = `${ImageUrl}${item.documentPath}`;
                        const isPdf = fullPath
                          .toLowerCase()
                          .endsWith(".pdf");
                        return (
                          <div className="preview_image" key={index}>
                            {isPdf ? (
                              <embed
                                src={fullPath}
                                type="application/pdf"
                                height="100"
                                width="100"
                                className="pdf_embed"
                              />
                            ) : (
                              <img
                                src={fullPath}
                                alt="banner"
                                height={100}
                                width={100}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    " "
                  )}
                </>
              )}
            </>
          </div>
          <div></div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", flexDirection: "column" }}
        position="relative"
        className="imageUploadClass"
      >
        <div className="dropzone_container_category">
          <div>
            <p className="image_lable">Food Image *</p>

            <div>
              <FileUploader
                multiple={false}
                disabled={disablefood}
                className="main_drop"
                handleChange={(e) => handlesinglefileChange(e, "image")}
                onBlur={handleBlur}
                name="foodImage"
                types={fileTypes1}
                fullWidth
                placeholder="Upload an image"
              />
              {values.restaurantLogo && <p>File uploaded</p>}
              {/* You can render a message or other content if a file is uploaded */}
            </div>
            {reupload == 2 ? (
              <>
                {disablefood ? (
                  <p className="verifiedText">Verified</p>
                ) : (
                  <p className="rejectedText">Rejected</p>
                )}
              </>
            ) : (
              ""
            )}

            {errors.foodImage && touched.foodImage ? (
              <p className="form-error">{errors.foodImage}</p>
            ) : null}

            {imagearray ? (
              <div className="preview_image">
                <img
                  src={`${ImageUrl}${imagearray}`}
                  alt="banner"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <>
                {imgUrl ? (
                  <div className="preview_image single_image_contaoner">
                    <img
                      src={imgUrl}
                      alt="banner"
                      height={100}
                      width={100}
                    />
                    <button
                      className="delete_button"
                      onClick={(e) => handleImageDelete1(e, "image")}
                    >
                      X
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", flexDirection: "column" }}
        position="relative"
        className="imageUploadClass"
      >
        <div className="dropzone_container_category">
          <div>
            <p className="image_lable">Restaurant Logo *</p>
            <FileUploader
              // multiple={true}
              disabled={disablelogo}
              className=""
              handleChange={(e) => handlesinglefileChange(e, "logo")}
              onBlur={handleBlur}
              name="restaurantLogo"
              types={fileTypes1}
            />

            {reupload == 2 ? (
              <>
                {disablelogo ? (
                  <p className="verifiedText">Verified</p>
                ) : (
                  <p className="rejectedText">Rejected</p>
                )}
              </>
            ) : (
              ""
            )}

            {errors.restaurantLogo && touched.restaurantLogo ? (
              <p className="form-error">{errors.restaurantLogo}</p>
            ) : null}

            {logoimagearray ? (
              <div className="preview_image single_image_contaoner">
                <img
                  src={`${ImageUrl}${logoimagearray}`}
                  alt="banner"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <>
                {imgUrllogo ? (
                  <div className="preview_image single_image_contaoner">
                    <img
                      src={imgUrllogo}
                      alt="banner"
                      height={100}
                      width={100}
                    />
                    <button
                      className="delete_button"
                      onClick={(e) => handleImageDelete1(e, "logo")}
                    >
                      X
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </Grid>



      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <button
          className="imageupload_btn"
          type="submit"
        >
          {loading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "Submit"
          )}
        </button>
      </Grid>
    </Grid>
  </Box>}

    </>
  );
};

export default DocumentUpload;
